/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/coppa-deny-view.sass */
#coppa-deny-view .parent-email-blurb {
  width: 500px; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/coppa-deny-view.sass */
#coppa-deny-view .parent-email-input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center; }
  /* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/coppa-deny-view.sass */
  #coppa-deny-view .parent-email-input-group .glyphicon {
    width: 0;
    line-height: 40px;
    font-size: 30px; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/coppa-deny-view.sass */
#coppa-deny-view .error {
  color: red; }
