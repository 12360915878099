/* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-popup {
  padding: 14px 0px;
  position: relative; }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
  .achievement-popup .achievement-body {
    position: relative; }
    /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
    .achievement-popup .achievement-body .achievement-icon {
      position: absolute;
      z-index: 1000;
      width: 140px;
      height: 140px;
      left: -98px;
      top: -14px;
      background-size: 100% 100% !important; }
      /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
      .achievement-popup .achievement-body .achievement-icon .achievement-image {
        width: 100%;
        height: 100%; }
        /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
        .achievement-popup .achievement-body .achievement-icon .achievement-image img {
          border-radius: 25%;
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 91px; }
    /* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
    .achievement-popup .achievement-body .achievement-content {
      background-image: url("/images/achievements/achievement_background.png");
      position: relative;
      width: 315px;
      height: 112px;
      padding: 16.8px 21px 14px 42px;
      background-size: 100% 100%;
      text-align: center;
      overflow: hidden; }
      /* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
      .achievement-popup .achievement-body .achievement-content .achievement-title {
        font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-variant: small-caps;
        font-size: 19.6px;
        font-weight: bold;
        padding-left: -35px;
        white-space: nowrap;
        max-height: 2em;
        overflow: hidden;
        text-overflow: ellipsis; }
      /* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
      .achievement-popup .achievement-body .achievement-content .achievement-description {
        font-size: 11.2px;
        line-height: 1.3em;
        max-height: 2.6em;
        margin-top: auto;
        margin-bottom: 0px !important;
        white-space: normal;
        padding-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis; }
      /* line 79, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
      .achievement-popup .achievement-body .achievement-content .progress-wrapper {
        margin-left: 14px;
        position: absolute;
        bottom: 33.6px; }
        /* line 84, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
        .achievement-popup .achievement-body .achievement-content .progress-wrapper .user-level {
          font-size: 14px;
          color: white;
          position: absolute;
          left: -10.5px;
          margin-top: -5.6px;
          vertical-align: middle;
          z-index: 1000; }
        /* line 93, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
        .achievement-popup .achievement-body .achievement-content .progress-wrapper > .progress-bar-wrapper {
          position: absolute;
          margin-left: 11.9px;
          width: 219.1px;
          height: 14px;
          z-index: 2; }
          /* line 100, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
          .achievement-popup .achievement-body .achievement-content .progress-wrapper > .progress-bar-wrapper > .progress {
            margin-top: 3.5px;
            border-radius: 35px;
            height: 9.8px; }
        /* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
        .achievement-popup .achievement-body .achievement-content .progress-wrapper > .progress-bar-border {
          position: absolute;
          width: 238px;
          height: 21px;
          margin-top: -1.4px;
          background: url("/images/achievements/bar_border.png") no-repeat;
          background-size: 100% 100%;
          z-index: 1; }
    /* line 115, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
    .achievement-popup .achievement-body.locked .achievement-content {
      background-image: url("/images/achievements/achievement_background_locked.png"); }
    /* line 118, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
    .achievement-popup .achievement-body:not(.locked) .achievement-content {
      background-image: url("/images/achievements/achievement_background.png"); }

/* line 123, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-wood.locked .achievement-icon {
  background: url("/images/achievements/border_wood_locked.png") no-repeat; }

/* line 126, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-wood:not(.locked) .achievement-icon {
  background: url("/images/achievements/border_wood.png") no-repeat; }

/* line 131, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-stone.locked .achievement-icon {
  background: url("/images/achievements/border_stone_locked.png") no-repeat; }

/* line 134, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-stone:not(.locked) .achievement-icon {
  background: url("/images/achievements/border_stone.png") no-repeat; }

/* line 139, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-silver.locked .achievement-icon {
  background: url("/images/achievements/border_silver_locked.png") no-repeat; }

/* line 142, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-silver:not(.locked) .achievement-icon {
  background: url("/images/achievements/border_silver.png") no-repeat; }

/* line 147, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-gold.locked .achievement-icon {
  background: url("/images/achievements/border_gold_locked.png") no-repeat; }

/* line 150, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-gold:not(.locked) .achievement-icon {
  background: url("/images/achievements/border_gold.png") no-repeat; }

/* line 155, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-diamond.locked .achievement-icon {
  background: url("/images/achievements/border_diamond_locked.png") no-repeat; }

/* line 158, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-diamond:not(.locked) .achievement-icon {
  background: url("/images/achievements/border_diamond.png") no-repeat; }

/* line 161, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.xp-bar-old {
  background-color: #680080; }

/* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.xp-bar-new {
  background-color: #0096ff; }

/* line 167, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.xp-bar-left {
  background-color: #fffbfd; }

/* line 171, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-category-title {
  margin-left: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #5a5a5a;
  text-transform: uppercase; }

/* line 179, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.table-layout #no-achievements {
  margin-top: 40px; }

/* line 182, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-icon-small {
  height: 18px; }

/* line 186, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.achievement-popup-container {
  position: fixed;
  left: 100px;
  bottom: 0px;
  z-index: 9001;
  cursor: pointer; }

/* line 193, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.popup {
  left: -600px; }

/* line 196, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/achievements.sass */
.user-level {
  background-image: url("/images/achievements/level-bg.png");
  background-size: 100% 100%;
  width: 26.6px;
  height: 26.6px;
  line-height: 26.6px;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }
