/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
#level-victory-modal .victory-banner {
  float: right;
  width: 150px;
  position: relative; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
#level-victory-modal .modal-footer {
  clear: both;
  padding-top: 15px; }

/* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
#level-victory-modal p.sign-up-poke {
  text-align: left;
  margin-bottom: 10px; }

/* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
#level-victory-modal .sign-up-button {
  float: right;
  margin-left: 10px; }

/* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
#level-victory-modal .rating {
  float: left;
  position: relative;
  top: 5px; }
  /* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
  #level-victory-modal .rating span {
    margin-right: 5px; }
  /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
  #level-victory-modal .rating i {
    cursor: pointer;
    padding: 2px; }

/* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
#level-victory-modal .review {
  margin-top: 5px;
  width: 100%;
  box-sizing: border-box;
  float: left;
  clear: both;
  text-align: left; }
  /* line 39, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
  #level-victory-modal .review textarea {
    width: 100%;
    height: 80px;
    box-sizing: border-box; }

/* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
#level-victory-modal .share-buttons {
  padding-top: 15px;
  clear: both; }

/* line 48, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
#level-victory-modal .modal-header {
  text-align: center; }

/* line 52, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
body.ipad #level-victory-modal .share-buttons {
  display: none; }

/* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/victory.sass */
body.ipad #level-victory-modal .modal-body {
  font-size: 30px;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif; }
