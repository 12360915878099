@charset "UTF-8";
/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.secret {
  display: none; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
h1, h2, h3, h4, h5, h6 {
  font-variant: small-caps; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
a {
  cursor: pointer; }

/* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
iframe {
  border: none; }

/* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.error {
  left: 8px; }

/* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.selectable {
  cursor: pointer; }

/* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.modal-dialog.game {
  padding: 5px;
  margin-top: 30px;
  margin-bottom: 0px;
  padding-top: 30px; }
  /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal-dialog.game .background-wrapper {
    background: url("/images/pages/base/modal_background.png");
    background-size: 100% 100%;
    border: 0;
    -webkit-box-shadow: 0 0 0 #000;
    box-shadow: 0 0 0 #000;
    width: 99%; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal-dialog.game .background-wrapper.plain {
    background: white; }
  /* line 40, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal-dialog.game .plain h1, .modal-dialog.game .plain h2, .modal-dialog.game .plain h3, .modal-dialog.game .plain h4, .modal-dialog.game .plain h5, .modal-dialog.game .plain h6 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important; }
  /* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal-dialog.game .modal-content {
    -webkit-box-shadow: none;
    box-shadow: none; }
  /* line 46, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal-dialog.game .modal-header {
    margin: 0 14px;
    padding: 8px 0;
    border-bottom-color: #ccc; }
    /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    .modal-dialog.game .modal-header .close {
      font-size: 28px;
      padding: 15px;
      margin-right: -15px;
      margin-top: -15px;
      opacity: 0.6;
      filter: alpha(opacity=60); }
      /* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
      .modal-dialog.game .modal-header .close:hover {
        opacity: 1;
        filter: alpha(opacity=100); }
  /* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal-dialog.game .modal-footer {
    background-color: transparent;
    margin: 0 14px;
    border-bottom-color: #ccc; }
  /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal-dialog.game .modal-footer.linkedin {
    text-align: center; }
    /* line 66, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    .modal-dialog.game .modal-footer.linkedin .signin-text {
      font-size: 15px;
      padding-bottom: 10px; }
  /* line 69, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal-dialog.game .login-link {
    cursor: pointer; }

/* line 74, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.loading-screen {
  margin-bottom: 20px; }
  /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .loading-screen .progress {
    width: 50%;
    margin: 0 25%; }

/* line 81, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.loading-container {
  text-align: center; }
  /* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .loading-container .progress-bar {
    width: 0%;
    transition: width 0.1s ease; }
  /* line 86, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .loading-container .errors .alert {
    padding: 5px;
    display: block;
    margin: 10px auto; }
    /* line 90, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    .loading-container .errors .alert .btn {
      margin-left: 10px; }

/* line 94, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.modal {
  overflow-y: auto !important; }
  /* line 97, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal .wait h3 {
    text-align: center; }
  /* line 99, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal .wait .progress {
    width: 50%;
    margin: 10px auto; }
  /* line 102, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .modal .wait .progress-bar {
    width: 100%; }

/* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
table.table {
  background-color: white; }

/* line 111, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.ui-slider {
  border: 1px solid black; }

/* line 113, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.ui-slider-handle {
  border: 1px solid black !important; }

/* line 117, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.ui-widget-content, .ui-widget-header, .ui-widget-overlay, .ui-widget-shadow {
  background-image: none; }

/* line 121, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.ui-widget-content .ui-state-default, .ui-widget-content .ui-state-focus, .ui-widget-content .ui-state-active, .ui-widget-content .ui-state-highlight, .ui-widget-content .ui-state-error, .ui-state-default .ui-state-default, .ui-state-default .ui-state-focus, .ui-state-default .ui-state-active, .ui-state-default .ui-state-highlight, .ui-state-default .ui-state-error, .ui-widget-header .ui-state-default, .ui-widget-header .ui-state-focus, .ui-widget-header .ui-state-active, .ui-widget-header .ui-state-highlight, .ui-widget-header .ui-state-error {
  background-image: none; }

/* line 126, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.highlight-pointer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100px;
  opacity: 0.0;
  pointer-events: none;
  z-index: 10; }

/* line 137, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.header-font {
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-variant: small-caps; }

/* line 142, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
body[lang='ja'] h1, body[lang='ja'] h2, body[lang='ja'] h3, body[lang='ja'] h4, body[lang='ja'] h5, body[lang='ja'] h6 {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", 'Open Sans Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-variant: small-caps;
  letter-spacing: -1px !important; }

/* line 147, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
body[lang='ja'] .header-font {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", 'Open Sans Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-variant: small-caps;
  letter-spacing: -1px !important; }

/* line 155, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
body[lang='ja'] #top-nav .navbar-nav li a.header-font {
  font-size: 16px; }

@media only screen and (max-width: 800px) {
  /* line 159, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .main-content-area {
    width: 100%; }
  /* line 161, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .content {
    width: 100%; }
  /* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .footer-link-text a {
    font-size: 17px;
    margin-left: 3px;
    margin-right: 3px; }
  /* line 169, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .share-buttons {
    margin-bottom: 20px; }
  /* line 171, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .partner-badges {
    display: none; } }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("/fonts/glyphicons-halflings-regular.eot");
  src: url("/fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("/fonts/glyphicons-halflings-regular.woff") format("woff"), url("/fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("/fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular") format("svg"); }

/* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.spr:after {
  content: " "; }

/* line 183, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.spl:before {
  content: " "; }

/* line 186, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
a[data-toggle="coco-modal"] {
  cursor: pointer; }

/* line 189, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.achievement-corner {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 1001; }

/* line 195, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }

/* line 203, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.gem {
  display: inline-block;
  background: transparent url(/images/common/gem.png) no-repeat center;
  background-size: contain;
  width: 80px;
  height: 80px;
  margin: 0px 2px; }
  /* line 211, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .gem.gem-20 {
    width: 20px;
    height: 20px; }
  /* line 215, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .gem.gem-25 {
    width: 25px;
    height: 25px; }
  /* line 219, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .gem.gem-30 {
    width: 30px;
    height: 30px; }
  /* line 223, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .gem.gem-40 {
    width: 40px;
    height: 40px; }
  /* line 227, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .gem.gem-60 {
    width: 60px;
    height: 60px; }

/* line 231, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.popover {
  border-style: solid;
  border-image: url(/images/level/popover_border_background.png) 16 12 fill stretch;
  border-width: 16px 12px; }
  /* line 235, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .popover .arrow {
    display: none; }
  /* line 237, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .popover .btn {
    font-size: 20px;
    width: 100%; }

/* line 241, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.btn.btn-illustrated {
  background: 0;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-image: url(/images/common/button-background-active-border.png) 14 20 20 20 fill round;
  border-width: 7px 10px 10px 10px;
  padding: 0;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #f8c592; }
  /* line 255, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated.btn-lg {
    border-width: 14px 20px 20px 20px;
    height: 60px;
    line-height: 34px; }
  /* line 260, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated.btn-primary {
    border-image-source: url(/images/common/button-background-primary-active-border.png); }
  /* line 262, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated.btn-success {
    border-image-source: url(/images/common/button-background-success-active-border.png);
    color: #f2f2f2; }
  /* line 265, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated.btn-warning {
    border-image-source: url(/images/common/button-background-warning-active-border.png);
    color: #f2f2f2; }
  /* line 268, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated.btn-danger {
    border-image-source: url(/images/common/button-background-danger-active-border.png); }
  /* line 271, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated:hover {
    color: #fad2aa; }
    /* line 273, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    .btn.btn-illustrated:hover.btn-success {
      color: white; }
    /* line 275, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    .btn.btn-illustrated:hover.btn-warning {
      color: white; }
  /* line 279, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated:active.btn-lg {
    border-image-source: url(/images/common/button-background-pressed-border.png); }
  /* line 281, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated:active:not(.btn-lg) {
    border-image: url(/images/common/button-background-pressed-border.png) 14 16 16 20 fill round;
    padding: 2px 0 0 2px;
    border-width: 7px 8px 8px 10px; }
  /* line 286, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated:active.btn-primary {
    border-image-source: url(/images/common/button-background-primary-pressed-border.png); }
  /* line 288, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated:active.btn-success {
    border-image-source: url(/images/common/button-background-success-pressed-border.png); }
  /* line 290, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated:active.btn-warning {
    border-image-source: url(/images/common/button-background-warning-pressed-border.png); }
  /* line 292, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated:active.btn-danger {
    border-image-source: url(/images/common/button-background-danger-pressed-border.png); }
  /* line 295, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated.disabled, .btn.btn-illustrated:disabled {
    border-image: url(/images/common/button-background-disabled-border.png) 14 20 20 20 fill round;
    opacity: 1;
    filter: alpha(opacity=100); }
    /* line 299, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    .btn.btn-illustrated.disabled.btn-primary, .btn.btn-illustrated:disabled.btn-primary {
      border-image-source: url(/images/common/button-background-primary-disabled-border.png); }
    /* line 301, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    .btn.btn-illustrated.disabled.btn-success, .btn.btn-illustrated:disabled.btn-success {
      border-image-source: url(/images/common/button-background-success-inactive-border.png); }
    /* line 303, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    .btn.btn-illustrated.disabled.btn-warning, .btn.btn-illustrated:disabled.btn-warning {
      border-image-source: url(/images/common/button-background-warning-disabled-border.png); }
    /* line 305, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    .btn.btn-illustrated.disabled.btn-danger, .btn.btn-illustrated:disabled.btn-danger {
      border-image-source: url(/images/common/button-background-danger-disabled-border.png); }
    /* line 308, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    .btn.btn-illustrated.disabled > *, .btn.btn-illustrated:disabled > * {
      opacity: 0.5;
      filter: alpha(opacity=50); }
  /* line 311, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated > * {
    opacity: 0.75;
    filter: alpha(opacity=75); }
  /* line 314, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  .btn.btn-illustrated:hover > * {
    opacity: 1;
    filter: alpha(opacity=100); }

/* line 319, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
html.no-borderimage .popover {
  border: 0;
  background: transparent url(/images/level/popover_background.png);
  background-size: 100% 100%;
  padding: 10px 20px; }

/* line 325, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
html.no-borderimage .btn.btn-illustrated {
  border: 0;
  background-image: url(/images/common/button-background-active.png);
  background-size: 100% 100%;
  padding: 7px 10px 10px 10px; }
  /* line 331, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  html.no-borderimage .btn.btn-illustrated.btn-primary {
    background-image: url(/images/common/button-background-primary-active.png); }
  /* line 333, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  html.no-borderimage .btn.btn-illustrated.btn-success {
    background-image: url(/images/common/button-background-success-active.png); }
  /* line 335, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  html.no-borderimage .btn.btn-illustrated.btn-warning {
    background-image: url(/images/common/button-background-warning-active.png); }
  /* line 337, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  html.no-borderimage .btn.btn-illustrated.btn-danger {
    background-image: url(/images/common/button-background-danger-active.png); }
  /* line 340, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  html.no-borderimage .btn.btn-illustrated:active {
    background-image: url(/images/common/button-background-pressed.png);
    padding: 9px 8px 8px 12px;
    border: 0; }
    /* line 345, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    html.no-borderimage .btn.btn-illustrated:active.btn-primary {
      background-image: url(/images/common/button-background-primary-pressed.png); }
    /* line 347, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    html.no-borderimage .btn.btn-illustrated:active.btn-success {
      background-image: url(/images/common/button-background-success-pressed.png); }
    /* line 349, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    html.no-borderimage .btn.btn-illustrated:active.btn-warning {
      background-image: url(/images/common/button-background-warning-pressed.png); }
    /* line 351, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    html.no-borderimage .btn.btn-illustrated:active.btn-danger {
      background-image: url(/images/common/button-background-danger-pressed.png); }
  /* line 354, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  html.no-borderimage .btn.btn-illustrated.disabled, html.no-borderimage .btn.btn-illustrated:disabled {
    background-image: url(/images/common/button-background-disabled.png); }
    /* line 357, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    html.no-borderimage .btn.btn-illustrated.disabled.btn-primary, html.no-borderimage .btn.btn-illustrated:disabled.btn-primary {
      background-image: url(/images/common/button-background-primary-disabled.png); }
    /* line 359, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    html.no-borderimage .btn.btn-illustrated.disabled.btn-success, html.no-borderimage .btn.btn-illustrated:disabled.btn-success {
      background-image: url(/images/common/button-background-success-inactive.png); }
    /* line 361, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    html.no-borderimage .btn.btn-illustrated.disabled.btn-warning, html.no-borderimage .btn.btn-illustrated:disabled.btn-warning {
      background-image: url(/images/common/button-background-warning-disabled.png); }
    /* line 363, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
    html.no-borderimage .btn.btn-illustrated.disabled.btn-danger, html.no-borderimage .btn.btn-illustrated:disabled.btn-danger {
      background-image: url(/images/common/button-background-danger-disabled.png); }

/* line 367, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
body > iframe[src^="https://apis.google.com"] {
  display: none; }

/* line 370, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
#module-load-progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  z-index: 1;
  transition: 1s; }
  /* line 379, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
  #module-load-progress .progress-bar {
    background-color: lightblue; }

/* line 382, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.treema-node input[type='checkbox'] {
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
  width: auto;
  margin: 8px 15px 8px 15px; }

/* line 387, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.particle-man {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  pointer-events: none; }

/* line 395, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.text-uppercase {
  text-transform: uppercase; }

/* line 398, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.text-capitalize {
  text-transform: capitalize; }

/* line 403, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.well-parchment {
  border: 2px solid #14110e;
  background: #c2b695;
  color: #282116; }

/* line 408, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.img-prize {
  border: 4px solid #fef9a3;
  padding: 2px;
  background: #693415; }

/* line 413, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
.label-banner {
  border: 2px solid #350f0d;
  background: #e6251c;
  color: white;
  padding: 0.0em 0.6em 0.1em; }

/* line 419, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
body.picoctf .picoctf-hide {
  display: none; }

/* line 422, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/common.sass */
body:not(.picoctf) .picoctf-show {
  display: none; }
