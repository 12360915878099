/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
#goals-view {
  position: absolute;
  left: -15px;
  top: -100px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  border-style: solid;
  border-image: url(/images/level/goals_background.png) 15 20 51 15 fill stretch;
  border-width: 8px 10px 25px 8px;
  margin: -8px -10px -25px -8px;
  padding: 19px 0px 2px 25px;
  z-index: 3;
  font-size: 14px;
  min-width: 230px; }
  /* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
  #goals-view.brighter {
    font-size: 18px;
    font-size: 1.4vw;
    border-width: 0.91vw 1.22vw 3.10vw 0.91vw;
    min-width: 23vw; }
  /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
  #goals-view .goals-status {
    margin: 5px 0 0 0;
    position: absolute;
    color: white;
    text-transform: uppercase; }
    /* line 30, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
    #goals-view .goals-status .success {
      color: lightgreen;
      text-shadow: 1px 1px 0px black; }
    /* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
    #goals-view .goals-status .timed-out {
      color: #e6e6e6; }
    /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
    #goals-view .goals-status .failure {
      color: #ef3d47;
      text-shadow: 1px 1px 0px black; }
    /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
    #goals-view .goals-status .incomplete {
      color: #f5aa31; }
    /* line 40, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
    #goals-view .goals-status .running {
      color: #c8c8c8; }
  /* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
  #goals-view ul {
    padding-left: 0;
    margin-bottom: 0;
    color: black; }
  /* line 48, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
  #goals-view li {
    list-style: none;
    margin-right: 5px; }
    /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
    #goals-view li i {
      margin-right: 5px; }
  /* line 54, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
  #goals-view li.status-incomplete {
    color: #333; }
  /* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
  #goals-view li.status-failure {
    color: darkred; }
  /* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
  #goals-view li.status-success {
    color: darkgreen; }

/* line 63, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
#goals-view.collapsed i.collapsed, #goals-view.expanded i.expanded {
  display: none; }

/* line 66, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
#goals-view.collapsed ul {
  display: none; }

/* line 70, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
html.no-borderimage #goals-view {
  background: transparent url(/images/level/goals_background.png);
  background-size: 100% 100%;
  border: 0; }
  /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
  html.no-borderimage #goals-view.brighter {
    border: 0; }
  /* line 78, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
  html.no-borderimage #goals-view .goals-status {
    position: static;
    margin-top: 10px; }

/* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
body[lang^='pt'] #goals-view, body[lang^='es'] #goals-view, body[lang='fr'] #goals-view, body[lang='hu'] #goals-view, body[lang='it'] #goals-view, body[lang='sr'] #goals-view {
  font-size: 12px; }
  /* line 86, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/goals.sass */
  body[lang^='pt'] #goals-view.brighter, body[lang^='es'] #goals-view.brighter, body[lang='fr'] #goals-view.brighter, body[lang='hu'] #goals-view.brighter, body[lang='it'] #goals-view.brighter, body[lang='sr'] #goals-view.brighter {
    font-size: 16px;
    font-size: 1.2vw; }
