/* line 1, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/cla.sass */
#cla-view {
  background-color: white;
  padding: 50px 0 200px; }
  /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/cla.sass */
  #cla-view #cla {
    width: 800px;
    margin: 0 auto;
    box-sizing: border-box; }
  /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/cla.sass */
  #cla-view hr {
    border: 1px solid black; }
