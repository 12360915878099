/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal .modal-dialog {
  margin-top: 0;
  padding-top: 0;
  width: 750px; }
  /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal .modal-dialog .modal-content {
    position: relative;
    margin-top: -251px; }

@media only screen and (max-height: 720px) {
  /* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal .modal-dialog {
    margin-top: -76px; } }

/* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal .background-wrapper {
  width: 750px;
  background: transparent;
  border: 0px solid transparent;
  border-style: solid;
  border-image: url("/images/pages/play/level/modal/victory_modal_border_background.png") 250 0 100 0 fill round;
  border-width: 250px 0 100px 0;
  border-radius: 12px; }

/* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal .modal-header {
  border: none;
  position: absolute;
  left: 188px;
  width: 378px;
  height: 134px;
  margin: 0;
  padding: 0; }
  /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal .modal-header .close {
    display: none; }
  /* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal .modal-header h1 {
    position: absolute;
    left: 0;
    top: 90px;
    margin: 0;
    width: 380px;
    text-align: center;
    color: #febc44;
    font-size: 28px;
    text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0; }

/* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal .modal-body {
  padding: 0 20px;
  min-height: 30px;
  margin-top: 160px; }

/* line 63, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal .description {
  margin: 20px 5px 0 5px;
  color: white;
  text-align: center; }

/* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal .answers-container-wrapper {
  margin-top: 5px;
  border: 4px solid #1a1511; }
  /* line 72, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal .answers-container-wrapper .answers-container {
    background-color: #2d241d;
    border: 4px solid #4a3d30;
    padding: 15px; }

/* line 77, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal table.table {
  margin-bottom: 0; }

/* line 81, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal tr.answer:not(.selected) td {
  background-color: #4a3d30;
  border-color: #695744; }

/* line 85, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal tr.answer:hover:not(.selected) td {
  background-color: #695744; }

/* line 88, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal tr.answer.selected td {
  background-color: #211c15;
  border-color: #403629; }

/* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal tr.answer:not(.selected) {
  cursor: pointer; }

/* line 95, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal tr.answer td {
  vertical-align: middle; }
  /* line 98, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal tr.answer td code {
    padding: 2px 4px;
    font-size: 90%;
    color: white;
    background-color: #333;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }
  /* line 106, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal tr.answer td.graph-cell {
    min-width: 200px; }
  /* line 109, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal tr.answer td p {
    margin: 0;
    color: white; }
  /* line 113, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal tr.answer td .progress {
    width: 100%;
    margin-bottom: 0;
    background-color: #2d241d;
    border-radius: 10px; }
    /* line 119, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
    #poll-modal tr.answer td .progress .progress-bar {
      background-color: #f5aa31;
      border-radius: 10px;
      border: 3px solid #2d241d;
      -webkit-transition: none;
      transition: none; }
  /* line 125, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal tr.answer td.votes-cell {
    max-width: 34px; }
    /* line 128, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
    #poll-modal tr.answer td.votes-cell .badge {
      background-color: #f5aa31;
      color: #2d241d; }

/* line 133, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal table:not(.answered) tr {
  text-align: center; }

/* line 136, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal table:not(.answered) .graph-cell, #poll-modal table:not(.answered) .votes-cell {
  display: none; }

/* line 139, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal .random-gems-container-wrapper {
  width: 558px;
  height: 115px;
  background: transparent url(/images/pages/play/modal/random-gems-background.png) no-repeat 100% 100%;
  padding: 25px;
  margin: 10px auto; }
  /* line 146, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal .random-gems-container-wrapper:not(.answered) {
    display: none; }
  /* line 151, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  #poll-modal .random-gems-container-wrapper .random-gems-container .random-gems-code {
    font-size: 14px;
    display: block;
    white-space: pre;
    padding: 2px 4px;
    font-size: 90%;
    color: black;
    background-color: transparent;
    border-radius: 0;
    margin-bottom: 5px; }
    /* line 162, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
    #poll-modal .random-gems-container-wrapper .random-gems-container .random-gems-code .comment {
      font-weight: bold;
      color: #865607; }

/* line 168, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal .modal-footer {
  margin: 0 0 -80px 0;
  padding: 0 20px;
  text-align: center; }

/* line 174, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
#poll-modal .done-button {
  float: right;
  height: 60px;
  min-width: 100px;
  line-height: 30px;
  margin: 0 10px; }

/* line 183, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
html.no-borderimage #poll-modal .modal-dialog {
  margin-top: 251px; }

/* line 185, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
html.no-borderimage #poll-modal .background-wrapper {
  border: 0;
  background: url("/images/pages/play/level/modal/victory_modal_background.png");
  height: 713px; }

@media only screen and (max-height: 720px) {
  /* line 191, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/poll-modal.sass */
  html.no-borderimage #poll-modal .modal-dialog {
    margin-top: 175px; } }
