/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/invite-to-classroom-modal.sass */
#invite-to-classroom-modal .modal-dialog {
  width: 700px; }

/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/invite-to-classroom-modal.sass */
#invite-to-classroom-modal #class-code-well {
  margin: 0 auto;
  text-align: center;
  font-weight: bold;
  display: inline-block; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/invite-to-classroom-modal.sass */
#invite-to-classroom-modal #copied-alert, #invite-to-classroom-modal #copy-failed-alert {
  margin-top: 10px;
  padding: 5px;
  display: inline-block; }
