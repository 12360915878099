/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/game_dev_track_view.sass */
#game-dev-track-view {
  position: absolute;
  display: none;
  transition: right 0.25s, opacity 0.125s, z-index 1s;
  top: 60px;
  border: 16px solid black;
  border-image: url(/images/pages/play/level-info-background.png) fill 20 stretch; }
  /* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/game_dev_track_view.sass */
  #game-dev-track-view:not(playback-float-right) {
    right: calc(43% + 10px + 200px);
    z-index: 6; }
  /* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/game_dev_track_view.sass */
  #game-dev-track-view.playback-float-right {
    right: calc(20% + 10px);
    z-index: 10; }
  /* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/game_dev_track_view.sass */
  #game-dev-track-view #listings .track-elem {
    display: flex;
    cursor: default; }
    /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/game_dev_track_view.sass */
    #game-dev-track-view #listings .track-elem .track-icon, #game-dev-track-view #listings .track-elem .track-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 32px;
      overflow: hidden;
      text-align: center; }
    /* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/game_dev_track_view.sass */
    #game-dev-track-view #listings .track-elem .track-icon {
      width: 16px; }
    /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/game_dev_track_view.sass */
    #game-dev-track-view #listings .track-elem .track-text {
      width: 48px; }
