/* line 1, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
#thang-components-edit-view {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
  #thang-components-edit-view .column {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 5px;
    box-sizing: border-box; }
    /* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
    #thang-components-edit-view .column h3 {
      margin: 5px 0; }
  /* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
  #thang-components-edit-view .treema-dependent > .treema-row {
    background-color: #FFC671; }
  /* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
  #thang-components-edit-view #thang-components-column {
    left: 0;
    width: 300px; }
    /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
    #thang-components-edit-view #thang-components-column .treema {
      position: absolute;
      top: 80px;
      left: 0;
      right: 10px;
      bottom: 0;
      overflow: scroll; }
      /* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
      #thang-components-edit-view #thang-components-column .treema .treema-key, #thang-components-edit-view #thang-components-column .treema .treema-description {
        display: none; }
      /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
      #thang-components-edit-view #thang-components-column .treema .dependent {
        background-color: rgba(128, 64, 255, 0.1); }
  /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
  #thang-components-edit-view #thang-components-config-column {
    border-left: 1px solid black;
    right: 0;
    left: 300px;
    min-width: 600px; }
    /* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
    #thang-components-edit-view #thang-components-config-column h3 {
      margin-left: 20px; }
    /* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
    #thang-components-edit-view #thang-components-config-column #thang-component-configs {
      position: absolute;
      top: 80px;
      bottom: 0;
      right: 0;
      left: 20px;
      overflow: scroll;
      margin-bottom: 150px; }
      /* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-components-edit-view.sass */
      #thang-components-edit-view #thang-components-config-column #thang-component-configs .selected-component .panel-heading {
        background-color: lightblue; }
