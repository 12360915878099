/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/artisans/thang-tasks-view.sass */
#thang-tasks-view #thangTable {
  width: 100%; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/artisans/thang-tasks-view.sass */
#thang-tasks-view .tasksTable {
  width: 100%; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/artisans/thang-tasks-view.sass */
#thang-tasks-view .tasks {
  width: 87.5%; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/artisans/thang-tasks-view.sass */
#thang-tasks-view .taskOwner {
  width: 12.5%; }
