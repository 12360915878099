/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
body[lang='ru'] h1, body[lang='ru'] .text-h1, body[lang='ru'] h3, body[lang='ru'] .text-h3, body[lang='ru'] h5, body[lang='ru'] .text-h5, body[lang='uk'] h1, body[lang='uk'] .text-h1, body[lang='uk'] h3, body[lang='uk'] .text-h3, body[lang='uk'] h5, body[lang='uk'] .text-h5, body[lang='bg'] h1, body[lang='bg'] .text-h1, body[lang='bg'] h3, body[lang='bg'] .text-h3, body[lang='bg'] h5, body[lang='bg'] .text-h5, body[lang^='mk'] h1, body[lang^='mk'] .text-h1, body[lang^='mk'] h3, body[lang^='mk'] .text-h3, body[lang^='mk'] h5, body[lang^='mk'] .text-h5, body[lang='sr'] h1, body[lang='sr'] .text-h1, body[lang='sr'] h3, body[lang='sr'] .text-h3, body[lang='sr'] h5, body[lang='sr'] .text-h5 {
  font-family: 'Open Sans', serif; }

/* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
.style-flat {
  background: white;
  color: black;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 29px; }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat h1, .style-flat h2, .style-flat h3, .style-flat h4, .style-flat h5, .style-flat h6, .style-flat .text-h1, .style-flat .text-h2, .style-flat .text-h3, .style-flat .text-h4, .style-flat .text-h5, .style-flat .text-h6 {
    font-variant: normal;
    color: black;
    margin: 0; }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat h1, .style-flat .text-h1 {
    font-family: "Arvo", serif;
    font-weight: normal;
    font-size: 46px;
    line-height: 62px; }
  /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat h2, .style-flat .text-h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: lighter;
    font-size: 30px;
    line-height: 42px; }
  /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat h3, .style-flat .text-h3 {
    font-family: "Arvo", serif;
    font-weight: normal;
    font-size: 33px;
    line-height: 45px; }
  /* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat h4, .style-flat .text-h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: lighter;
    font-size: 22px;
    line-height: 32px; }
  /* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat h5, .style-flat .text-h5 {
    font-family: "Arvo", serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 31px; }
  /* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat h6, .style-flat .text-h6 {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px; }
  /* line 61, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat p {
    color: black;
    margin: 0 0 14px; }
  /* line 65, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .small {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px; }
  /* line 70, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .semibold, .style-flat .student-name {
    font-weight: 600; }
  /* line 73, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .small-details {
    font: "Arvo", serif;
    font-size: 15px;
    line-height: 26px; }
  /* line 82, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat blockquote {
    border: none; }
    /* line 85, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat blockquote:before {
      font-family: "Monaco";
      content: "\201C";
      position: absolute;
      left: 0px;
      top: 20px;
      font-size: 40px;
      opacity: 0.5; }
  /* line 94, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat hr {
    border-top: 1px solid gray; }
  /* line 99, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat #main-nav.navbar {
    background: white;
    margin-bottom: 0;
    white-space: nowrap; }
    /* line 104, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar a.navbar-brand {
      color: #7D0101; }
      /* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #main-nav.navbar a.navbar-brand #logo-img {
        width: 230px;
        height: 65px;
        margin-right: 10px; }
        /* line 110, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
        .style-flat #main-nav.navbar a.navbar-brand #logo-img.code-ninjas-powered-by {
          width: 150px;
          height: auto;
          margin-top: -10px; }
      /* line 115, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #main-nav.navbar a.navbar-brand .code-ninjas-logo {
        width: 150px;
        margin-right: 10px;
        margin-top: 6px;
        margin-bottom: 10px; }
      /* line 122, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #main-nav.navbar a.navbar-brand:hover {
        color: white;
        background: #7D0101; }
      /* line 126, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #main-nav.navbar a.navbar-brand .glyphicon-home {
        position: relative;
        top: 3px; }
    /* line 130, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar .navbar-toggle {
      color: black;
      margin: 30px 25px 0;
      border-color: #0E4C60; }
      /* line 135, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #main-nav.navbar .navbar-toggle .icon-bar {
        background-color: #0E4C60; }
    @media (min-width: 992px) {
      /* line 139, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #main-nav.navbar #navbar-collapse {
        float: right; } }
    /* line 142, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar #navbar-collapse {
      max-height: none; }
    /* line 145, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar .nav > li > a {
      font-weight: bold;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 31px;
      padding: 32px 15px 0;
      height: 95px;
      color: #7D0101;
      text-shadow: 0 0 0; }
      /* line 156, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #main-nav.navbar .nav > li > a:hover {
        background: #7D0101;
        color: white; }
    /* line 160, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar .nav > li.disabled > a {
      color: black; }
      /* line 162, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #main-nav.navbar .nav > li.disabled > a:hover {
        background: white;
        color: black;
        cursor: default; }
    /* line 167, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar #language-dropdown-wrapper {
      display: inline-block;
      padding: 30px 10px;
      width: 100%; }
    @media (max-width: 992px) {
      /* line 173, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #main-nav.navbar .nav > li > a {
        padding: 10px 20px;
        height: 45px; }
      /* line 176, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #main-nav.navbar #language-dropdown-wrapper {
        display: inline-block;
        padding: 10px 10px; }
        /* line 179, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
        .style-flat #main-nav.navbar #language-dropdown-wrapper .language-dropdown {
          width: 150px; } }
    /* line 182, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar .img-circle {
      border: #F2BE19 8px solid;
      width: 98px;
      height: 98px; }
    /* line 187, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar .img-circle-small {
      border: #F2BE19 3px solid;
      width: 33px;
      height: 33px; }
    /* line 193, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar .border-burgundy {
      border-color: #7D0101; }
    /* line 196, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar .border-navy {
      border-color: #0E4C60; }
    /* line 199, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #main-nav.navbar .user-level {
      position: absolute;
      top: 76px;
      right: 42px;
      color: #F2BE19;
      text-shadow: 1px 1px black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black; }
  /* line 208, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .well {
    padding: 8px;
    background-color: transparent;
    border: thin solid lightgray;
    border-radius: 0; }
  /* line 217, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn {
    border: none;
    border-radius: 5px;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    background-image: none; }
    /* line 223, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .btn .disabled {
      opacity: 50%; }
  /* line 226, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn + .btn {
    margin-left: 12px; }
  /* line 229, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-primary, .style-flat .btn-navy {
    background-color: #0E4C60;
    color: white; }
  /* line 233, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-primary-alt, .style-flat .btn-navy-alt {
    background-color: white;
    border: 1px solid #0E4C60;
    color: #0E4C60; }
  /* line 238, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-forest {
    background-color: #20572B;
    color: white; }
  /* line 242, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-forest-alt {
    background-color: white;
    border: 1px solid #20572B;
    color: #20572B; }
  /* line 247, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-gold {
    background-color: #F2BE19;
    color: white; }
  /* line 251, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-gold-alt {
    background-color: white;
    border: 1px solid #F2BE19;
    color: #F2BE19; }
  /* line 256, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-purple {
    background-color: #9760B3;
    color: white; }
  /* line 260, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-gold-alt {
    background-color: white;
    border: 1px solid #9760B3;
    color: #9760B3; }
  /* line 265, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-burgundy {
    background-color: #7D0101;
    color: white; }
  /* line 269, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-burgundy-alt {
    background-color: white;
    border: 1px solid #7D0101;
    color: #7D0101; }
  /* line 274, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-lg {
    font-size: 18px; }
  /* line 277, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-gplus {
    color: white;
    background-color: #DD4B39; }
    /* line 280, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .btn-gplus img {
      height: 22px; }
  /* line 283, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .btn-facebook {
    color: white;
    background-color: #3B5998; }
    /* line 286, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .btn-facebook img {
      height: 22px; }
  /* line 290, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat select {
    height: 33px;
    background-color: white;
    border: 1px solid #0E4C60;
    color: #0E4C60; }
  /* line 298, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .tooltip.in {
    opacity: 1; }
  /* line 301, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .tooltip .tooltip-arrow::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }
  /* line 311, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .tooltip.top .tooltip-arrow {
    margin-left: -10px;
    border-width: 5px 10px 0;
    border-top-color: #333; }
    /* line 315, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .tooltip.top .tooltip-arrow::after {
      top: -6px;
      left: 50%;
      margin-left: -10px;
      border-width: 5px 10px 0;
      border-top-color: white; }
  /* line 322, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .tooltip.right .tooltip-arrow {
    border-right-color: #333;
    border-width: 5px 6px 5px 0; }
    /* line 326, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .tooltip.right .tooltip-arrow::after {
      top: 50%;
      left: 1px;
      margin-top: -5px;
      border-width: 5px 6px 5px 0;
      border-right-color: white; }
  /* line 333, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .tooltip.left .tooltip-arrow {
    border-right-color: #333;
    border-width: 5px 0 5px 6px; }
    /* line 337, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .tooltip.left .tooltip-arrow::after {
      top: 50%;
      right: 1px;
      margin-top: -5px;
      border-width: 5px 0 5px 6px;
      border-left-color: white; }
  /* line 344, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #333;
    margin-left: -10px;
    border-width: 0 10px 5px; }
    /* line 348, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .tooltip.bottom .tooltip-arrow::after {
      top: 1px;
      left: 50%;
      margin-left: -10px;
      border-width: 0 10px 5px;
      border-bottom-color: white; }
  /* line 355, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .tooltip-inner {
    padding: 10px 20px;
    border: 1px solid #333;
    color: black;
    background: white;
    border-radius: 20px;
    min-width: 150px;
    max-width: 600px; }
  /* line 371, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .checkbox-flat {
    position: relative;
    background: white;
    border: thin solid #979797;
    width: 20px;
    height: 20px; }
    /* line 378, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .checkbox-flat input {
      visibility: hidden; }
    /* line 381, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .checkbox-flat label {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 1px;
      top: 1px; }
    /* line 388, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .checkbox-flat label:after {
      opacity: 0;
      content: '';
      position: absolute;
      width: 14px;
      height: 7px;
      background: transparent;
      top: 3px;
      left: 1px;
      border: 2px solid black;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg); }
    /* line 402, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .checkbox-flat label:hover::after {
      opacity: 0.3; }
    /* line 405, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .checkbox-flat input:checked + label:after {
      opacity: 1; }
  /* line 411, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .text-navy {
    color: #0E4C60; }
  /* line 414, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .text-burgundy {
    color: #7D0101; }
  /* line 417, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .text-forest {
    color: #20572B; }
  /* line 420, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .bg-navy {
    background-color: #0E4C60;
    color: white; }
    /* line 423, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .bg-navy h1, .style-flat .bg-navy h2, .style-flat .bg-navy h3, .style-flat .bg-navy h4, .style-flat .bg-navy h5, .style-flat .bg-navy h6, .style-flat .bg-navy a {
      color: white; }
    /* line 425, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .bg-navy a.btn-primary-alt, .style-flat .bg-navy a.btn-navy-alt {
      color: #0E4C60; }
  /* line 428, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .bg-forest {
    background-color: #20572B;
    color: white; }
    /* line 431, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .bg-forest h1, .style-flat .bg-forest h2, .style-flat .bg-forest h3, .style-flat .bg-forest h4, .style-flat .bg-forest h5, .style-flat .bg-forest h6, .style-flat .bg-forest a {
      color: white; }
    /* line 433, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat .bg-forest a.btn-primary-alt, .style-flat .bg-forest a.btn-forest-alt {
      color: #20572B; }
  /* line 445, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-x-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-a-0 {
    margin: 0 0 !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-t-0 {
    margin-top: 0 !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-r-0 {
    margin-right: 0 !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-b-0 {
    margin-bottom: 0 !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-l-0 {
    margin-left: 0 !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-x-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-a-1 {
    margin: 1rem 1rem !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-t-1 {
    margin-top: 1rem !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-r-1 {
    margin-right: 1rem !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-b-1 {
    margin-bottom: 1rem !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-l-1 {
    margin-left: 1rem !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-x-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-y-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-a-2 {
    margin: 1.5rem 1.5rem !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-t-2 {
    margin-top: 1.5rem !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-r-2 {
    margin-right: 1.5rem !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-b-2 {
    margin-bottom: 1.5rem !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-l-2 {
    margin-left: 1.5rem !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-x-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-y-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-a-3 {
    margin: 3rem 3rem !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-t-3 {
    margin-top: 3rem !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-r-3 {
    margin-right: 3rem !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-b-3 {
    margin-bottom: 3rem !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-l-3 {
    margin-left: 3rem !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-x-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-y-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-a-4 {
    margin: 4rem 4rem !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-t-4 {
    margin-top: 4rem !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-r-4 {
    margin-right: 4rem !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-b-4 {
    margin-bottom: 4rem !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-l-4 {
    margin-left: 4rem !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-x-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-y-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-a-5 {
    margin: 5rem 5rem !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-t-5 {
    margin-top: 5rem !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-r-5 {
    margin-right: 5rem !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-b-5 {
    margin-bottom: 5rem !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-l-5 {
    margin-left: 5rem !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-x-5 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .m-y-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-a-0 {
    padding: 0 0 !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-t-0 {
    padding-top: 0 !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-r-0 {
    padding-right: 0 !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-b-0 {
    padding-bottom: 0 !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-l-0 {
    padding-left: 0 !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-x-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-a-1 {
    padding: 1rem 1rem !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-t-1 {
    padding-top: 1rem !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-r-1 {
    padding-right: 1rem !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-b-1 {
    padding-bottom: 1rem !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-l-1 {
    padding-left: 1rem !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-x-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-y-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-a-2 {
    padding: 1.5rem 1.5rem !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-t-2 {
    padding-top: 1.5rem !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-r-2 {
    padding-right: 1.5rem !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-b-2 {
    padding-bottom: 1.5rem !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-l-2 {
    padding-left: 1.5rem !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-x-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-y-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-a-3 {
    padding: 3rem 3rem !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-t-3 {
    padding-top: 3rem !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-r-3 {
    padding-right: 3rem !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-b-3 {
    padding-bottom: 3rem !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-l-3 {
    padding-left: 3rem !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-x-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-y-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-a-4 {
    padding: 4rem 4rem !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-t-4 {
    padding-top: 4rem !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-r-4 {
    padding-right: 4rem !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-b-4 {
    padding-bottom: 4rem !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-l-4 {
    padding-left: 4rem !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-x-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-y-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  /* line 454, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-a-5 {
    padding: 5rem 5rem !important; }
  /* line 456, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-t-5 {
    padding-top: 5rem !important; }
  /* line 458, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-r-5 {
    padding-right: 5rem !important; }
  /* line 460, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-b-5 {
    padding-bottom: 5rem !important; }
  /* line 462, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-l-5 {
    padding-left: 5rem !important; }
  /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-x-5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  /* line 470, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .p-y-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  /* line 476, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat #footer {
    background-image: url("/images/pages/home/footer_background.png");
    height: 229px;
    margin: 65px auto 0;
    color: white; }
    @media (max-width: 768px) {
      /* line 476, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #footer {
        background-color: #201a15;
        background-image: none;
        height: auto; } }
    /* line 487, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #footer ul {
      margin: 30px; }
      /* line 489, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #footer ul li:first-child {
        border-bottom: 1px solid white;
        margin-bottom: 10px; }
    /* line 492, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #footer a {
      color: white; }
  /* line 495, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat #final-footer {
    position: absolute;
    left: 0;
    right: 0;
    height: 60px;
    color: white;
    background-color: #463a2c; }
    @media (max-width: 768px) {
      /* line 495, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
      .style-flat #final-footer {
        position: inherit;
        padding: 20px;
        height: auto; } }
    /* line 507, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #final-footer a {
      color: white; }
    /* line 510, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
    .style-flat #final-footer img {
      width: 150px;
      margin: 0 10px; }
  /* line 517, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat.modal-content {
    padding: 10px;
    border-radius: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); }
  /* line 522, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/style-flat.sass */
  .style-flat .button.close {
    position: absolute;
    top: 10px;
    right: 10px; }
