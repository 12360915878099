/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view h1, #progress-view h2, #progress-view h3 {
  margin-top: 0;
  color: black;
  margin-bottom: 5px; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view .modal-header h1 {
  padding-top: 10px;
  color: #F2BE19; }

/* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view .next-level-description p {
  margin-top: 30px; }

/* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view .course-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

/* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view .publish-text {
  font-size: 18px; }
  /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
  #progress-view .publish-text p {
    margin-bottom: 0; }

/* line 30, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view #share-level-input {
  font-size: 12px;
  margin-top: 5px; }

/* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/progress-view.sass */
#progress-view #share-level-btn {
  width: 100%;
  margin-top: 5px; }
