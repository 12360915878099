/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
#share-licenses-modal .modal-header {
  text-transform: capitalize; }

/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
#share-licenses-component {
  padding-top: 5px; }
  /* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
  #share-licenses-component .list-item {
    transition: background-color 0.5s ease-out; }
  /* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
  #share-licenses-component .list-enter {
    background-color: #0E4C60; }
  /* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
  #share-licenses-component .error-message {
    color: red;
    text-align: right; }
    /* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
    #share-licenses-component .error-message a {
      color: inherit;
      text-decoration: underline; }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
  #share-licenses-component h4 {
    font-size: 16px;
    font-weight: 600; }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
  #share-licenses-component input {
    width: 100%;
    border: 1px solid black; }
  /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
  #share-licenses-component .add-teacher-btn {
    width: 100%; }
  /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
  #share-licenses-component .subheader-text {
    margin-left: 8%;
    margin-right: 8%; }
  /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/share-licenses-modal.sass */
  #share-licenses-component .footer-text {
    margin-left: 9%;
    margin-right: 9%; }
