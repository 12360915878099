/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
#auth-modal .modal-dialog {
  padding: 0;
  width: 666px;
  height: 582px; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
#auth-modal .auth-modal-background {
  position: absolute;
  top: -90px;
  left: -40px; }

/* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
#auth-modal h1 {
  position: absolute;
  left: 183px;
  top: 0px;
  margin: 0;
  width: 255px;
  text-align: center;
  color: #febc44;
  font-size: 32px;
  text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0; }
  /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal h1.long-title {
    top: -14px; }

/* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
#auth-modal #close-modal {
  position: absolute;
  left: 442px;
  top: -15px;
  width: 60px;
  height: 60px;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 15px;
  cursor: pointer;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg); }
  /* line 54, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal #close-modal:hover {
    color: yellow; }

/* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
#auth-modal .auth-form-content {
  position: absolute;
  top: 100px;
  left: 40px;
  width: 588px; }
  /* line 66, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .auth-form-content .help-block {
    margin: 0; }
  /* line 69, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .auth-form-content .alert {
    margin-top: -25px;
    margin-bottom: 0;
    padding: 10px 15px; }
  /* line 74, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .auth-form-content #recover-account-wrapper {
    float: right;
    margin-top: 7px; }
  /* line 78, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .auth-form-content .form-group {
    color: #333333;
    padding: 0;
    margin: 0; }
    /* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-form-content .form-group .input-border {
      border: 2px solid #e9ddc2;
      border-radius: 4px; }
      /* line 87, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
      #auth-modal .auth-form-content .form-group .input-border input {
        background-color: #efe8d8;
        border: 2px solid #1a1512;
        border-radius: 4px; }
  /* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .auth-form-content label {
    font-size: 20px;
    text-transform: uppercase;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 0; }
    /* line 98, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-form-content label .optional-note {
      font-size: 14px; }
  /* line 103, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .auth-form-content .form-group.checkbox {
    margin: 10px 0; }
    /* line 106, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-form-content .form-group.checkbox label {
      position: relative;
      line-height: 34px; }
      /* line 110, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
      #auth-modal .auth-form-content .form-group.checkbox label span:not(.custom-checkbox) {
        margin-left: 40px; }
    /* line 113, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-form-content .form-group.checkbox input {
      display: none; }
      /* line 117, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
      #auth-modal .auth-form-content .form-group.checkbox input + .custom-checkbox .glyphicon {
        display: none; }
      /* line 120, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
      #auth-modal .auth-form-content .form-group.checkbox input:checked + .custom-checkbox .glyphicon {
        display: inline;
        color: #f8a943;
        text-align: center;
        text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black;
        font-size: 20px;
        position: relative;
        top: -2px; }
    /* line 129, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-form-content .form-group.checkbox .input-border {
      border-radius: 4px;
      height: 34px;
      width: 34px;
      position: absolute; }
    /* line 135, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-form-content .form-group.checkbox .custom-checkbox {
      border-radius: 4px;
      position: absolute;
      height: 30px;
      width: 30px;
      border: 2px solid #1a1512;
      background: #e4d9c4;
      text-align: center; }
  /* line 146, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .auth-form-content #login-btn {
    position: absolute;
    top: 186px;
    height: 70px;
    font-size: 32px;
    line-height: 42px; }

/* line 156, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
#auth-modal .auth-network-logins {
  position: absolute;
  top: 358px;
  width: 580px;
  left: 48px;
  padding-left: 29px; }
  /* line 163, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .auth-network-logins .btn.btn-lg.network-login {
    width: 251px;
    height: 60px;
    float: left;
    text-align: center;
    position: relative;
    margin-right: 15px; }
    /* line 171, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-network-logins .btn.btn-lg.network-login .network-logo {
      height: 30px;
      position: absolute;
      left: -10px;
      top: 2px; }
    /* line 177, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-network-logins .btn.btn-lg.network-login .sign-in-blurb {
      line-height: 34px;
      margin-left: 12px; }
    /* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-network-logins .btn.btn-lg.network-login .fb-login-button {
      transform: scale(3.92188, 2.6087);
      position: absolute;
      top: 4px;
      left: 74px;
      opacity: 0.01;
      filter: alpha(opacity=1); }
    /* line 190, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-network-logins .btn.btn-lg.network-login .gplus-login-wrapper {
      position: absolute;
      left: 65px;
      top: -6px;
      transform: scale(2.9881, 2.5);
      opacity: 0.01;
      filter: alpha(opacity=1); }
  /* line 199, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .auth-network-logins #github-login-button {
    position: relative;
    top: -1px;
    border-radius: 5px; }
    /* line 203, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
    #auth-modal .auth-network-logins #github-login-button img {
      width: 16px;
      margin: 0 5px 0 -5px; }
  /* line 207, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .auth-network-logins #gplus-login-button {
    position: relative;
    top: 8px; }

/* line 213, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
#auth-modal .extra-pane {
  background-image: url(/images/pages/modal/auth/extra-pane.png);
  width: 633px;
  height: 139px;
  padding: 23px 23px 23px 168px;
  position: absolute;
  top: 408px; }
  /* line 221, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .extra-pane .switch-explanation {
    margin: 25px 10px 0 0;
    width: 200px;
    color: #febc44;
    font-size: 20px;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: black 1px 1px 0, black -1px -1px 0, black 1px -1px 0, black -1px 1px 0, black 1px 0px 0, black 0px -1px 0, black -1px 0px 0, black 0px 1px 0;
    float: left; }
  /* line 232, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
  #auth-modal .extra-pane .btn {
    float: right;
    margin-top: 20px;
    width: 230px;
    height: 70px;
    line-height: 40px; }

/* line 240, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
.ie10 #auth-modal .auth-network-logins .btn.btn-lg.network-login .network-logo, .lt-ie10 #auth-modal .auth-network-logins .btn.btn-lg.network-login .network-logo {
  left: 15px;
  top: 15px; }

/* line 245, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/auth-modal.sass */
body[lang^='pt'] #auth-modal .auth-network-logins .btn.btn-lg.network-login, body[lang^='es'] #auth-modal .auth-network-logins .btn.btn-lg.network-login, body[lang='fr'] #auth-modal .auth-network-logins .btn.btn-lg.network-login, body[lang^='de'] #auth-modal .auth-network-logins .btn.btn-lg.network-login, body[lang='hu'] #auth-modal .auth-network-logins .btn.btn-lg.network-login, body[lang='pl'] #auth-modal .auth-network-logins .btn.btn-lg.network-login, body[lang='sr'] #auth-modal .auth-network-logins .btn.btn-lg.network-login, body[lang='sk'] #auth-modal .auth-network-logins .btn.btn-lg.network-login {
  font-size: 16px; }
