/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .ladder-table .name-col-cell {
  max-width: 170px; }
  /* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
  #ladder-tab-view .ladder-table .name-col-cell.ai {
    color: #3f44bf; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .histogram-display {
  height: 130px; }
  /* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
  #ladder-tab-view .histogram-display svg {
    overflow: visible; }

/* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .bar rect {
  fill: steelblue;
  shape-rendering: crispEdges; }

/* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .bar text {
  fill: #fff; }

/* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .specialbar rect {
  fill: #555555; }

/* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .axis path, #ladder-tab-view .axis line {
  fill: none;
  stroke: #555555;
  shape-rendering: crispEdges; }

/* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .humans-bar {
  fill: #bf3f3f;
  shape-rendering: crispEdges; }

/* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .ogres-bar {
  fill: #3f44bf;
  shape-rendering: crispEdges; }

/* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view text {
  fill: #555555; }

/* line 40, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .rank-text {
  font-size: 15px;
  fill: #555555; }

/* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .humans-rank-text {
  fill: #bf3f3f; }

/* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .ogres-rank-text {
  fill: #3f44bf; }

/* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .load-more-ladder-entries {
  position: absolute;
  right: 15px;
  bottom: -5px; }

/* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view td {
  padding: 1px 2px; }

/* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .code-language-cell, #ladder-tab-view .hero-portrait-cell {
  padding: 0 10px;
  background: transparent url(/images/common/code_languages/javascript_icon.png) no-repeat center center;
  height: 16px;
  background-size: 16px 16px; }

/* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .spectate-cell {
  cursor: pointer; }
  /* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
  #ladder-tab-view .spectate-cell:not(.selected) .glyphicon {
    display: none;
    opacity: 0.75; }
  /* line 71, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
  #ladder-tab-view .spectate-cell:hover .glyphicon {
    display: inline-block; }

/* line 74, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder-tab-view.sass */
#ladder-tab-view .iconic-cell {
  text-align: center; }
