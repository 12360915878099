/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/scripts_tab.sass */
#editor-level-scripts-tab-view .toggle {
  z-index: 11;
  margin-top: -10px;
  margin-left: -10px;
  float: left; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/scripts_tab.sass */
#editor-level-scripts-tab-view .treema-script {
  cursor: pointer; }

/* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/scripts_tab.sass */
#editor-level-scripts-tab-view .editor-nano-container #scripts-treema {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 250px;
  overflow: scroll; }
  @media screen and (max-width: 800px) {
    /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/scripts_tab.sass */
    #editor-level-scripts-tab-view .editor-nano-container #scripts-treema {
      top: 40px;
      z-index: 11; } }

/* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/scripts_tab.sass */
#editor-level-scripts-tab-view .editor-nano-container #script-treema {
  margin-left: 290px;
  max-height: 100%;
  overflow: scroll;
  box-sizing: border-box; }
  @media screen and (max-width: 800px) {
    /* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/scripts_tab.sass */
    #editor-level-scripts-tab-view .editor-nano-container #script-treema {
      margin-left: 30px;
      top: -50px; } }
