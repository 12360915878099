/* line 1, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/treema-ext.sass */
.treema-root {
  border: 1px solid black;
  background-color: #f5f5f5;
  padding: 10px 20px 10px 0; }

/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/treema-ext.sass */
.treema-markdown {
  border: 1px solid gray;
  padding: 5px; }
  /* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/treema-ext.sass */
  .treema-markdown, .treema-markdown > div.ace_editor {
    width: 100%;
    min-height: 48px; }
  /* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/treema-ext.sass */
  .treema-markdown .buttons button {
    float: left;
    margin-bottom: 5px;
    margin-right: 5px; }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/treema-ext.sass */
  .treema-markdown .preview {
    clear: both;
    width: 100%;
    border: 3px inset rgba(0, 100, 100, 0.2);
    box-sizing: border-box;
    padding: 5px; }

/* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/treema-ext.sass */
.treema-selection-map {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9001;
  background: #ffffff;
  width: 80%;
  height: 80%;
  margin: 10%;
  box-sizing: border-box;
  border: 2px solid black; }
  /* line 40, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/treema-ext.sass */
  .treema-selection-map img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    cursor: pointer;
    max-width: 95%;
    max-height: 95%; }

/* line 52, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/treema-ext.sass */
.treema-sound-file .dropdown li {
  margin: 0 10px;
  cursor: pointer; }
  /* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/treema-ext.sass */
  .treema-sound-file .dropdown li:hover {
    background-color: #dddddd; }
