/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
#course-victory-modal .modal-dialog, #picoctf-victory-modal .modal-dialog {
  margin-top: 0;
  padding-top: 0;
  width: 750px; }
  @media screen and (max-height: 625px) {
    /* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
    #course-victory-modal .modal-dialog, #picoctf-victory-modal .modal-dialog {
      margin-top: -50px; } }
  /* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
  #course-victory-modal .modal-dialog .modal-content, #picoctf-victory-modal .modal-dialog .modal-content {
    position: relative;
    margin-top: -251px; }

/* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
#course-victory-modal .background-wrapper, #picoctf-victory-modal .background-wrapper {
  width: 750px;
  background: transparent;
  border: 0px solid transparent;
  border-style: solid;
  border-image: url("/images/pages/play/level/modal/victory_modal_border_background.png") 250 0 100 0 fill round;
  border-width: 250px 0 100px 0;
  border-radius: 12px; }

/* line 30, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
#course-victory-modal .modal-header, #picoctf-victory-modal .modal-header {
  border: none;
  position: absolute;
  left: 0px;
  width: 100%;
  height: 134px;
  margin: 0;
  padding: 0;
  top: 74px;
  text-align: center; }
  /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
  #course-victory-modal .modal-header .well-parchment, #picoctf-victory-modal .modal-header .well-parchment {
    display: inline-block; }
    /* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
    #course-victory-modal .modal-header .well-parchment h1, #picoctf-victory-modal .modal-header .well-parchment h1 {
      color: inherit;
      margin: 0; }
  /* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
  #course-victory-modal .modal-header #close-modal, #picoctf-victory-modal .modal-header #close-modal {
    position: absolute;
    right: 174px;
    color: white;
    top: -15px;
    background: #ba1d00;
    padding: 6px 6px 3px; }

/* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
#course-victory-modal .modal-body, #picoctf-victory-modal .modal-body {
  padding: 30px 20px 0;
  position: relative;
  top: 80px;
  margin-top: 80px; }
  @media screen and (max-height: 650px) {
    /* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
    #course-victory-modal .modal-body, #picoctf-victory-modal .modal-body {
      padding-top: 10px; } }
  /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
  #course-victory-modal .modal-body .well-parchment, #picoctf-victory-modal .modal-body .well-parchment {
    margin-top: 20px; }
    @media screen and (max-height: 675px) {
      /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
      #course-victory-modal .modal-body .well-parchment, #picoctf-victory-modal .modal-body .well-parchment {
        margin-top: 0; } }

/* line 73, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
html.no-borderimage #course-victory-modal .modal-dialog, html.no-borderimage #picoctf-victory-modal .modal-dialog {
  margin-top: 251px; }

/* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/course-victory-modal.sass */
html.no-borderimage #course-victory-modal .background-wrapper, html.no-borderimage #picoctf-victory-modal .background-wrapper {
  border: 0;
  background: url("/images/pages/play/level/modal/victory_modal_background.png");
  height: 713px; }
