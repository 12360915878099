/* line 1, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
.editor {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px; }
  /* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor h1, .editor h2, .editor h3, .editor h4, .editor h5, .editor h6, .editor a {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important; }
  /* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .dropdown-menu {
    position: absolute;
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.176);
    left: 0px;
    right: auto; }
    /* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
    .editor .dropdown-menu li a {
      color: #555;
      padding: 3px 20px; }
  /* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .navbar-nav {
    float: left;
    margin: 0; }
    /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
    .editor .navbar-nav > li {
      float: left; }
  /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor li.navbar-btn {
    margin-right: 5px; }
  /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .navbar-nav > li > a {
    padding: 7px 8px 8px;
    cursor: pointer; }
    /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
    .editor .navbar-nav > li > a:hover {
      background-color: #4f4030; }
  /* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .navbar {
    min-height: 0px;
    border-radius: 0; }
  /* line 46, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .navbar-right {
    margin-right: 10px !important;
    float: right; }
    /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
    .editor .navbar-right .dropdown-menu {
      right: 0px;
      left: auto; }
  /* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .navbar-brand {
    padding-top: 7px;
    padding-bottom: 7px;
    color: #ffef99; }
  /* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .navbar-header {
    border-left: 2px solid #6e5944;
    border-right: 2px solid #6e5944;
    background: #4f4030;
    margin-left: 20px;
    float: left; }
  /* line 66, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .nav-tabs {
    margin-left: 5px;
    border-bottom: 0 !important; }
    /* line 70, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
    .editor .nav-tabs li {
      float: left;
      display: block; }
    @media only screen and (max-width: 800px) {
      /* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
      .editor .nav-tabs li {
        float: none;
        display: none;
        z-index: 12; }
        /* line 81, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
        .editor .nav-tabs li a {
          background-color: #e4cf8c;
          border-color: #5c4b15;
          border-width: 0px 1px;
          border-radius: 0px; }
      /* line 87, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
      .editor .nav-tabs li:first-child > a {
        border-radius: 5px 5px 0px 0px;
        border-top-width: 1px; }
      /* line 91, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
      .editor .nav-tabs li:last-child > a {
        border-radius: 0px 0px 5px 5px;
        border-bottom-width: 1px; }
      /* line 95, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
      .editor .nav-tabs li.active {
        display: block; } }
    /* line 98, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
    .editor .nav-tabs .active > a, .editor .nav-tabs .active > a:hover, .editor .nav-tabs .active > a:focus {
      background-color: #e4cf8c !important;
      border-color: #5c4b15;
      border-bottom: 0; }
    /* line 102, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
    .editor .nav-tabs a {
      padding: 7px 5px !important; }
  /* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .dropdown-menu a {
    cursor: pointer; }
    /* line 107, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
    .editor .dropdown-menu a:hover {
      background-color: #d3d3d3; }
  /* line 110, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .badge {
    background-color: green; }
  /* line 113, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .outer-content {
    background-color: #e4cf8c;
    position: absolute;
    top: 35px;
    bottom: 0;
    left: 0;
    right: 0; }
  /* line 121, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .top-controls-area {
    position: fixed;
    background-color: #e4cf8c;
    z-index: 100;
    left: 20px;
    right: 20px;
    margin-top: -10px;
    padding-top: 10px; }
  /* line 130, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor #level-editor-tabs, .editor#thang-type-edit-view .tab-content {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px; }
  /* line 137, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .treema-root {
    background-color: white;
    border-radius: 4px; }
    /* line 140, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
    .editor .treema-root:focus {
      box-shadow: 0 0 10px blue; }
  /* line 143, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .editor-nano-container {
    position: static; }
  /* line 146, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/editor.sass */
  .editor .nano-content {
    outline: thin; }
