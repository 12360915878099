@media print {
  /* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .backlink {
    display: none; } }

/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
#markdown-resource-view .print, #markdown-resource-view .print p {
  text-align: center;
  font-size: .75em; }
  @media print {
    /* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
    #markdown-resource-view .print, #markdown-resource-view .print p {
      display: none; } }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
#markdown-resource-view .print a {
  color: #fff;
  text-decoration: none; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
#markdown-resource-view .back-to-top {
  text-transform: none; }
  @media print {
    /* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
    #markdown-resource-view .back-to-top {
      display: none; } }

@media print {
  /* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view #footer, #markdown-resource-view #final-footer {
    display: none; } }

/* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
#markdown-resource-view #hour-of-code {
  background-color: #cff2fc;
  border-radius: 10px;
  padding: 20px 60px;
  margin: 0 0 30px 0;
  text-align: center;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view #hour-of-code p.small {
    font-style: italic; }
  /* line 39, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view #hour-of-code ol {
    text-align: left;
    margin: 0 15px; }
    /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
    #markdown-resource-view #hour-of-code ol li {
      padding: 0 0 10px 0; }
  /* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view #hour-of-code h3 {
    text-align: center;
    font-size: 1.5em; }

/* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
#markdown-resource-view .lesson-plans {
  max-width: 900px;
  background-color: #f8f8f8;
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 40px 100px;
  font-size: 0.85em;
  margin: 20px auto; }
  @media print {
    /* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
    #markdown-resource-view .lesson-plans {
      max-width: none;
      border: none;
      margin: none;
      padding: none;
      font-size: 80%; } }
  /* line 65, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans ul {
    margin: 0 0 30px 0; }
  /* line 69, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans h1 {
    font-size: 2.5em;
    margin: 0;
    padding: 0;
    font-family: Open Sans;
    font-weight: 200; }
    @media print {
      /* line 69, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
      #markdown-resource-view .lesson-plans h1 {
        font-size: 2em; } }
  /* line 79, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans h2 {
    margin: 10px 0 0px 0; }
  /* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans h3 {
    font-size: 1.4em;
    margin: 30px 0 0 0; }
  /* line 88, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans h4 {
    font-size: 1.2em;
    font-weight: 400;
    color: #444;
    margin: 25px 0 5px 0; }
  /* line 95, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans h5 {
    color: #999;
    text-transform: uppercase;
    font-family: Open Sans;
    font-size: 1em;
    padding: 0;
    margin: 0; }
  /* line 104, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans h5[id^="module"], #markdown-resource-view .lesson-plans h5[id^="step"] {
    border-top: 1px solid #666;
    margin: 30px 0 0 0;
    padding: 20px 0 0 0; }
    @media print {
      /* line 104, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
      #markdown-resource-view .lesson-plans h5[id^="module"], #markdown-resource-view .lesson-plans h5[id^="step"] {
        page-break-before: always; } }
  /* line 111, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans h5[id="step-1"] {
    border-top: none !important; }
    @media print {
      /* line 111, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
      #markdown-resource-view .lesson-plans h5[id="step-1"] {
        page-break-before: avoid; } }
    /* line 115, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
    #markdown-resource-view .lesson-plans h5[id="step-1"] .back-to-top {
      display: none; }
  /* line 118, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans h6 {
    font-style: italic;
    font-weight: 200;
    text-align: right; }
  /* line 123, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans table, #markdown-resource-view .lesson-plans tr, #markdown-resource-view .lesson-plans td, #markdown-resource-view .lesson-plans th {
    border: 2px solid #f8f8f8;
    border-radius: 5px;
    background-color: #fff; }
  /* line 128, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans td, #markdown-resource-view .lesson-plans th {
    padding: 0 10px; }
  /* line 131, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans blockquote {
    padding: 5px;
    margin: 15px 0 30px 0; }
  /* line 135, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans blockquote::before {
    content: none; }
  /* line 138, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .lesson-plans blockquote p {
    font-size: 1.0em;
    line-height: 1.7em;
    margin: 0 0 0 0px;
    padding: 0 60px 0 50px;
    border-left: 5px solid #eee; }

/* line 150, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
#markdown-resource-view #getting-started img, #markdown-resource-view #clever-faq img {
  max-width: 400px;
  border: 1px solid #666;
  box-shadow: 2px 2px #ddd;
  margin: 15px auto 15px auto;
  display: block; }
  @media print {
    /* line 150, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
    #markdown-resource-view #getting-started img, #markdown-resource-view #clever-faq img {
      max-width: 400px !important; } }

/* line 158, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
#markdown-resource-view .ace_content {
  background-color: #f5f5f5; }
  /* line 161, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .ace_content .ace_marker-layer .ace_bracket {
    display: none; }
  /* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/markdown-resource-view.sass */
  #markdown-resource-view .ace_content .ace_cursor-layer .ace_cursor {
    display: none; }
