/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/choose-language-modal.sass */
#choose-language-modal button {
  margin: 20px 0 10px; }
  /* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/choose-language-modal.sass */
  #choose-language-modal button img {
    width: 30px; }

/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/choose-language-modal.sass */
#choose-language-modal .progress {
  width: 50%;
  margin: 50px 25%; }
