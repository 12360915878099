/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/project-gallery.sass */
#project-gallery-view .project-list {
  list-style: none;
  -webkit-padding-start: 0; }
  /* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/project-gallery.sass */
  #project-gallery-view .project-list li {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    /* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/project-gallery.sass */
    #project-gallery-view .project-list li .creator-name {
      text-align: left; }
    /* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/project-gallery.sass */
    #project-gallery-view .project-list li .view-edit-buttons {
      text-align: right; }
    /* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/project-gallery.sass */
    #project-gallery-view .project-list li .btn ~ .btn {
      margin-left: 10px; }
