/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/activate-licenses-modal.sass */
#activate-licenses-modal select {
  min-width: 80%; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/activate-licenses-modal.sass */
#activate-licenses-modal .checkbox {
  margin: 0; }
  /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/activate-licenses-modal.sass */
  #activate-licenses-modal .checkbox input[type='checkbox'] {
    margin-top: 8px; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/activate-licenses-modal.sass */
#activate-licenses-modal .modal-content {
  padding: 60px;
  width: 690px; }

/* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/activate-licenses-modal.sass */
#activate-licenses-modal .well {
  max-height: 284px;
  overflow: scroll; }

/* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/activate-licenses-modal.sass */
#activate-licenses-modal .not-enough-enrollments {
  color: red;
  visibility: hidden; }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/activate-licenses-modal.sass */
  #activate-licenses-modal .not-enough-enrollments.visible {
    visibility: visible; }
