/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-component-config-view.sass */
.thang-component-config-view .panel-body {
  padding: 0; }
  /* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-component-config-view.sass */
  .thang-component-config-view .panel-body .treema-root {
    border: 0;
    padding: 0 5px; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/thang-component-config-view.sass */
.thang-component-config-view .is-default-component {
  background-color: lightgray; }
