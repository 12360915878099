/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-update-account-view.sass */
#courses-update-account-view #main-content {
  background-color: #f2dede;
  padding: 20px 80px 20px 80px; }

/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-update-account-view.sass */
#courses-update-account-view .warning {
  color: red;
  font-size: 14px;
  line-height: 15px; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-update-account-view.sass */
#courses-update-account-view .well {
  font-size: 15px;
  line-height: 16px;
  margin: 20px; }

/* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-update-account-view.sass */
#courses-update-account-view .not_you {
  font-size: 14px;
  margin-left: 20px; }
