/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
.contribute_class #homepage_screenshot {
  margin: 20px 0px; }

/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
.contribute_class .class_detail {
  float: left; }
  /* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
  .contribute_class .class_detail img {
    width: 360px; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
.contribute_class .signature {
  text-align: right; }

/* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
.contribute_class a {
  cursor: pointer; }

/* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
.contribute_class .class_image {
  float: left;
  width: 150px;
  margin: 10px 10px 20px 20px; }

/* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
.contribute_class .class-main {
  margin-left: 33%;
  padding: 0px 40px 40px 40px;
  box-sizing: border-box; }
  /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
  .contribute_class .class-main .header-scrolling-fix {
    padding-top: 65px;
    margin-top: -50px; }
  /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
  .contribute_class .class-main .well {
    padding: 5px 5px 5px 30px; }
  /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
  .contribute_class .class-main .saved-notification {
    display: none;
    float: right; }

/* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
.contribute_class .thumbnail {
  background-color: transparent;
  height: 176px; }
  /* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
  .contribute_class .thumbnail:hover {
    background-color: rgba(200, 244, 255, 0.2); }
  /* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
  .contribute_class .thumbnail a:not(.has-github) {
    cursor: default;
    text-decoration: none; }
  /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
  .contribute_class .thumbnail img {
    max-width: 100px;
    max-height: 100px; }
  /* line 54, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
  .contribute_class .thumbnail .caption {
    background-color: transparent; }
    /* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute_classes.sass */
    .contribute_class .thumbnail .caption h4 {
      text-align: center; }
