/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-school-licenses.sass */
#admin-school-licenses-view table td, #admin-school-licenses-view table th {
  padding: 0px; }

/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-school-licenses.sass */
#admin-school-licenses-view .range-container {
  position: relative;
  width: 100%; }
  /* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-school-licenses.sass */
  #admin-school-licenses-view .range-container .range-background {
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: green;
    opacity: 0.25; }
  /* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-school-licenses.sass */
  #admin-school-licenses-view .range-container .range-dates {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px; }
