/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/email-verified-view.sass */
#email-verified-view .alert {
  display: flex;
  align-items: center;
  justify-content: center; }

/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/email-verified-view.sass */
#email-verified-view .glyphicon {
  font-size: 20pt; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/email-verified-view.sass */
#email-verified-view .btn-lg {
  min-width: 246px; }
