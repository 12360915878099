/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/course/edit.sass */
#editor-course-edit-view .treema-root {
  margin-bottom: 20px; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/course/edit.sass */
#editor-course-edit-view .course-tool-button {
  float: right;
  margin-bottom: 15px;
  margin-left: 10px; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/course/edit.sass */
#editor-course-edit-view textarea {
  width: 92%;
  height: 300px; }
