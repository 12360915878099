/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/artisans/student-solutions-view.sass */
#student-solutions-view .is-breakable {
  word-break: break-word; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/artisans/student-solutions-view.sass */
#student-solutions-view .solution {
  max-height: 200px;
  overflow: auto; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/artisans/student-solutions-view.sass */
#student-solutions-view .solution-info {
  font-size: 16px;
  line-height: 20px; }

/* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/artisans/student-solutions-view.sass */
#student-solutions-view .go-button {
  margin-top: 33px; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/artisans/student-solutions-view.sass */
#student-solutions-view .ace_editor {
  height: 200px;
  width: 90%;
  font-family: Monaco, Menlo, Ubuntu Mono, Consolas, "source-code-pro", monospace !important;
  margin-bottom: 10px; }
