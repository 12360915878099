/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
#basic-info-view .network-login {
  width: 175px;
  height: 40px;
  border: solid 0.5px darkgray; }
  /* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
  #basic-info-view .network-login span {
    visibility: hidden; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
#basic-info-view .basic-info {
  display: flex;
  flex-direction: column; }
  /* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
  #basic-info-view .basic-info .form-group {
    text-align: left;
    margin: 0; }
    /* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
    #basic-info-view .basic-info .form-group input {
      max-height: 5vh; }
    /* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
    #basic-info-view .basic-info .form-group .help-block {
      float: right; }
    /* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
    #basic-info-view .basic-info .form-group .fancy-error {
      padding-top: 29px;
      line-height: 14px; }
  /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
  #basic-info-view .basic-info .form-container > .form-group, #basic-info-view .basic-info .form-container > .row {
    max-height: 84px;
    max-height: calc(29px + 29px + 5vh);
    flex-grow: 1;
    align-self: flex-start; }

/* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
#basic-info-view .btn-illustrated img {
  opacity: 1; }

/* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
#basic-info-view label {
  margin-bottom: 0; }

/* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
#basic-info-view .help-block {
  margin: 0; }

/* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
#basic-info-view .optional-help-block {
  font-style: italic; }

/* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
#basic-info-view .form-container {
  width: 800px; }

/* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/basic-info-view.sass */
#basic-info-view .input-lg {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 12px;
  padding-right: 12px; }
