/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .breadcrumbs {
  color: #065e73; }
  /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .breadcrumbs a {
    color: #065e73; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .student-details {
  margin-top: 20px; }

/* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .performance .recommendation {
  padding: 5px 0 0 0; }
  /* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .recommendation .small-details {
    line-height: 20px;
    font-weight: 600;
    padding: 0 15px 0 50px;
    width: 94%; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .recommendation .glyphicon {
    padding: 5px 0 0 0;
    width: 5%;
    float: left; }
  /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .recommendation .glyphicon-exclamation-sign {
    color: #808080;
    font-size: 2em; }
  /* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .recommendation .glyphicon-star {
    color: #F2BE19;
    font-size: 2em; }
  /* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .recommendation .glyphicon-thumbs-up {
    color: #0E4C60;
    font-size: 2em; }
  /* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .recommendation .status {
    padding: 2px 0 0 0;
    margin-bottom: 20px;
    font-weight: normal; }

/* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .performance .additional-progress {
  padding: 0 0 0 12px; }
  /* line 61, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .additional-progress .glyphicon {
    margin-top: 4px;
    vertical-align: top; }
  /* line 65, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .additional-progress .glyphicon-exclamation-sign {
    color: #808080; }
  /* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .additional-progress .glyphicon-star {
    color: #F2BE19; }
  /* line 71, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .additional-progress .glyphicon-thumbs-up {
    color: #0E4C60; }
  /* line 74, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .performance .additional-progress .small-details {
    vertical-align: top;
    margin-left: 15px; }

/* line 78, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .playtime-charts {
  margin-top: 30px; }
  /* line 81, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .playtime-charts h4 {
    margin-bottom: 15px; }

/* line 85, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view svg.visualisation {
  display: none; }
  /* line 89, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view svg.visualisation text.label {
    font-size: 11px;
    fill: #000000;
    font-weight: bold; }
  /* line 96, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view svg.visualisation g.tick {
    font-size: 13px; }
  /* line 99, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view svg.visualisation g.labels {
    font-size: 15px;
    font-weight: 600; }

/* line 103, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .graphs :first-child {
  display: block; }

/* line 106, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .graphsSelector {
  text-align: center;
  margin-bottom: 10px; }

/* line 110, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view div.legend {
  text-align: center;
  padding: 10px 0 0 0;
  font-size: 15px;
  font-style: italic; }

/* line 116, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .student-levels {
  margin-top: 30px;
  margin-bottom: 30px; }
  /* line 120, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .student-levels h4 {
    margin-bottom: 10px; }

/* line 123, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .course-row {
  padding-left: 75px;
  padding-top: 15px;
  padding-bottom: 30px; }
  /* line 128, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .course-row .course-info {
    font-weight: 600; }

/* line 131, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .progress-dot {
  margin: 5px; }

/* line 134, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .progress-dot {
  display: inline-block;
  margin-right: 6px;
  min-width: 34px;
  height: 34px;
  border-radius: 16px;
  padding: 0 5px;
  background: #999;
  position: relative; }
  /* line 143, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .progress-dot a {
    text-decoration: none; }
  /* line 146, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .progress-dot .dot-label {
    padding-top: 2px; }
    /* line 148, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
    #teacher-student-view .progress-dot .dot-label .dot-label-inner {
      font-size: 11px;
      font-weight: bold;
      color: white; }

/* line 153, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .progress-dot-lg .dot-label .dot-label-inner {
  font-size: 13px; }

/* line 156, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .progress-dot.forest {
  background: #20572B; }
  /* line 158, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .progress-dot.forest .tooltip-inner {
    color: #20572B;
    border-color: #20572B; }
  /* line 161, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .progress-dot.forest .tooltip-arrow {
    border-top-color: #20572B; }

/* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .progress-dot.gold {
  background: #F2BE19; }
  /* line 166, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .progress-dot.gold .tooltip-inner {
    color: #0E4C60;
    border-color: #0E4C60; }
  /* line 169, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .progress-dot.gold .tooltip-arrow {
    border-top-color: #0E4C60; }

/* line 172, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
#teacher-student-view .progress-dot.navy {
  background: #0E4C60; }
  /* line 174, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .progress-dot.navy .tooltip-inner {
    color: #0E4C60;
    border-color: #0E4C60; }
  /* line 177, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-student-view.sass */
  #teacher-student-view .progress-dot.navy .tooltip-arrow {
    border-top-color: #0E4C60; }
