/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
#main-ladder-view .level {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px black; }
  /* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
  #main-ladder-view .level:hover div, #main-ladder-view .level:hover .dynamic-level-name {
    color: #fbd78e; }
  /* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
  #main-ladder-view .level:hover img {
    filter: brightness(1.2);
    -webkit-filter: brightness(1.2);
    box-shadow: 0 0 5px black; }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
  #main-ladder-view .level .level-image {
    width: 100%; }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
  #main-ladder-view .level .dynamic-level-name {
    position: absolute;
    z-index: 1;
    top: 40px;
    width: 100%;
    text-align: center;
    text-shadow: 0px 5px 5px black, -2px 0px 2px black, 2px 0px 2px black, 0px -2px 2px black;
    font-size: 72px;
    color: #F7B42C;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-variant: small-caps;
    -webkit-transition: color 0.1s linear;
    transition: color 0.1s linear; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
  #main-ladder-view .level .overlay-text {
    color: #F7B42C;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-variant: small-caps;
    -webkit-transition: color 0.1s linear;
    transition: color 0.1s linear; }
  /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
  #main-ladder-view .level .level-difficulty {
    position: absolute;
    left: 0px;
    bottom: 0px;
    font-size: 25px;
    padding-right: 10px;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 6px; }
  /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
  #main-ladder-view .level .play-text-container {
    position: absolute;
    left: 50%;
    bottom: -10px; }
    /* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
    #main-ladder-view .level .play-text-container .play-text {
      margin-left: -50%;
      font-size: 50px; }

/* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
#main-ladder-view a[disabled] .level {
  opacity: 0.7; }

/* line 63, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
#main-ladder-view a .level-difficulty .level-status-complete {
  color: #F7B42C; }

/* line 66, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder_home.sass */
#main-ladder-view a .level-difficulty .level-status-started {
  color: #c0a163; }
