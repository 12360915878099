/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/skipped-contacts-view.sass */
#skipped-contacts-view ol {
  padding: 0; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/skipped-contacts-view.sass */
#skipped-contacts-view li.skipped-contact-info {
  list-style: none;
  border: thin solid grey;
  padding: 20px; }
  /* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/skipped-contacts-view.sass */
  #skipped-contacts-view li.skipped-contact-info:nth-child(2n) {
    background-color: #EEEEEE; }
