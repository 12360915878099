/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view #volume-group {
  position: relative;
  width: 100%;
  height: 25px;
  margin: 15px 0; }
  /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #volume-group > * {
    position: absolute; }
  /* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #volume-group .ui-slider {
    left: 40px;
    right: 40px;
    top: 4px;
    background-color: #f8a943;
    border: 4px solid #1a1512;
    height: 18px;
    border-radius: 18px; }
    /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
    #options-view #volume-group .ui-slider .ui-slider-handle {
      background-color: #1a1512;
      width: 28px;
      height: 28px;
      border-radius: 28px;
      top: -9px;
      outline: 0; }
  /* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #volume-group .glyphicon {
    font-size: 30px;
    top: -2px;
    color: #1a1512; }
  /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #volume-group .glyphicon-volume-down {
    position: absolute;
    left: 0; }
  /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view #volume-group .glyphicon-volume-up {
    position: absolute;
    right: -10px; }

/* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view .hr {
  width: 100%;
  height: 10px; }

/* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view h3 {
  margin-top: 5px;
  color: #1a1512; }

/* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view .form-group {
  font-size: 16px;
  color: #333333;
  padding-top: 4px;
  padding-left: 40px; }

/* line 66, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view label {
  font-weight: normal; }

/* line 73, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view .form-group.checkbox label {
  position: relative; }

/* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view .form-group.checkbox input {
  display: none; }
  /* line 80, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view .form-group.checkbox input + .custom-checkbox .glyphicon {
    display: none; }
  /* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
  #options-view .form-group.checkbox input:checked + .custom-checkbox .glyphicon {
    display: inline;
    color: #f8a943;
    text-align: center;
    text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black;
    font-size: 14px;
    position: relative;
    top: -1px; }

/* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view .form-group.checkbox .custom-checkbox {
  border-radius: 2px;
  height: 27px;
  width: 27px;
  border: 4px solid #1a1512;
  position: absolute;
  background: #e4d9c4;
  text-align: center;
  left: -40px;
  top: -4px; }

/* line 108, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/options-view.sass */
#options-view select {
  border: 3px solid black;
  text-transform: uppercase; }
