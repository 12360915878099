/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view #ladder-top {
  background-color: whitesmoke;
  margin: -14px -12px 20px -12px;
  padding-bottom: 30px;
  border-bottom: 1px solid #888; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view #course-header {
  background-color: black;
  font-size: 24px;
  padding: 6px 4px 8px;
  font-weight: bold; }
  /* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view #course-header #course-details-link {
    position: absolute;
    background-color: white;
    padding: 2px 5px; }
    /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
    #ladder-view #course-header #course-details-link a {
      color: black; }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view #course-header #course-name {
    color: white; }

/* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view #level-column {
  padding-top: 14px;
  text-align: center; }
  /* line 29, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view #level-column img {
    margin-top: -14px;
    width: 100%; }

/* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view h1 {
  text-align: center; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view h1.league-header {
    margin: 15px 0 20px 0; }

/* line 39, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .tournament-blurb {
  margin-top: -10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  background-color: whitesmoke; }
  /* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view .tournament-blurb h2 {
    text-align: center; }
  /* line 48, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view .tournament-blurb a {
    font-weight: bold; }
  /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view .tournament-blurb .sponsor-logos {
    padding: 10px 15px 10px 15px;
    -webkit-filter: grayscale(100%);
    -webkit-transition: .5s ease-in-out;
    -moz-filter: grayscale(100%);
    -moz-transition: .5s ease-in-out;
    -o-filter: grayscale(100%);
    -o-transition: .5s ease-in-out;
    filter: grayscale(100%);
    transition: .5s ease-in-out; }
    /* line 63, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
    #ladder-view .tournament-blurb .sponsor-logos:hover {
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      -o-filter: grayscale(0%);
      filter: grayscale(0%); }
    /* line 69, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
    #ladder-view .tournament-blurb .sponsor-logos img {
      margin: 0px 15px; }

/* line 72, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .tab-pane {
  margin-top: 10px; }

/* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .rank-cell, #ladder-view .fight-cell {
  text-align: center; }

/* line 78, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .score-cell {
  width: 50px;
  text-align: center; }

/* line 82, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .play-button {
  margin-bottom: 10px;
  background-image: none;
  color: white;
  font-size: 24px; }

/* line 88, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .spectate-button-container {
  margin-top: 10px;
  text-align: center; }
  /* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view .spectate-button-container .btn.spectate-button {
    font-size: 18px;
    color: white; }

/* line 96, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .name-col-cell {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/* line 102, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .ellipsis-row {
  text-align: center; }

/* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .simulator-leaderboard-cell {
  text-align: center; }

/* line 110, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .friends-header {
  margin-top: 0;
  margin-bottom: 5px; }

/* line 114, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .connect-buttons {
  margin-bottom: 15px; }
  /* line 116, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view .connect-buttons .btn {
    margin-right: 5px; }

/* line 119, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .friend-entry img {
  float: left;
  margin-right: 10px; }

/* line 123, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .friend-entry {
  margin-bottom: 15px; }

/* line 126, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .connect-facebook {
  background-color: #4c66a4 !important;
  background-image: none;
  color: white; }

/* line 131, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view .connect-google-plus {
  background-color: #CC3234 !important;
  background-image: none;
  color: white; }

/* line 136, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view #must-log-in button {
  margin-right: 10px; }

/* line 139, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view #prize_table {
  width: 960px;
  font-weight: bold; }
  /* line 143, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view #prize_table thead {
    font-size: 24px; }
  /* line 147, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view #prize_table tbody tr:not(:first-child) {
    border-top: 10px solid #ddd; }
  /* line 150, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view #prize_table tbody td {
    vertical-align: middle; }
    /* line 153, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
    #ladder-view #prize_table tbody td:nth-child(1), #ladder-view #prize_table tbody td:nth-child(3) {
      text-align: center;
      font-size: 24px; }
    /* line 157, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
    #ladder-view #prize_table tbody td li {
      list-style: none; }
      /* line 160, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
      #ladder-view #prize_table tbody td li:not(:last-child) {
        margin-bottom: 10px;
        border-bottom: 1px solid #ddd; }
      /* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
      #ladder-view #prize_table tbody td li img {
        margin-right: 10px; }

/* line 168, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view #winners .win {
  color: #172; }

/* line 170, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view #winners .loss {
  color: #712; }

/* line 172, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
#ladder-view #winners .code-language-cell {
  padding: 0 10px;
  background: transparent url(/images/common/code_languages/javascript_icon.png) no-repeat center center;
  height: 16px; }

@media only screen and (max-width: 800px) {
  /* line 179, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/ladder.sass */
  #ladder-view #level-column img {
    width: 100%; } }
