/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/home.sass */
#thang-type-home-view .portrait {
  width: 30px; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/home.sass */
#thang-type-home-view #portrait-col {
  width: 30px; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/home.sass */
#thang-type-home-view td {
  vertical-align: middle; }
