/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .big-font {
  text-transform: uppercase;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .modal-dialog {
  padding: 0;
  width: 1230px;
  height: 660px;
  background: none; }

/* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal #play-items-modal-bg, #play-items-modal #play-items-modal-narrow-bg {
  position: absolute;
  top: -69px;
  left: -8px; }

/* line 29, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal #play-items-modal-narrow-bg {
  display: none; }

/* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal h1 {
  position: absolute;
  left: 200px;
  top: 25px;
  color: #fec346;
  font-size: 38px;
  text-shadow: black 4px 4px 0, black -4px -4px 0, black 4px -4px 0, black -4px 4px 0, black 4px 0px 0, black 0px -4px 0, black -4px 0px 0, black 0px 4px 0;
  margin: 0; }

/* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal #gems-count-container {
  position: absolute;
  left: 425px;
  top: 10px;
  width: 160px;
  height: 66px;
  -webkit-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg); }
  /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal #gems-count-container #gems-count {
    position: absolute;
    left: 75px;
    top: 17px;
    font-size: 25px;
    color: #01405b; }

/* line 59, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal #close-modal {
  position: absolute;
  left: 602px;
  top: 23px;
  width: 60px;
  height: 60px;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 7px;
  cursor: pointer;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg); }
  /* line 72, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal #close-modal:hover {
    color: yellow; }

/* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .nav {
  position: absolute;
  top: 125px;
  left: -31px;
  width: 178px; }
  /* line 82, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .nav li {
    background: url(/images/pages/play/modal/menu-tab.png);
    padding: 5px;
    margin: -5px 0;
    height: 80px;
    padding: 0; }
    /* line 89, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal .nav li a {
      font-size: 18px;
      line-height: 50px;
      background: none;
      color: #c3997c;
      font-weight: bold;
      padding: 10px 7px; }
  /* line 97, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .nav li.active {
    background: url(/images/pages/play/modal/menu-tab-selected.png);
    width: 197px; }
    /* line 101, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal .nav li.active a {
      color: white; }

/* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal #hero-type-select {
  position: absolute;
  top: 102px;
  left: 177px;
  background: #1a1511;
  padding: 2px 0;
  z-index: 3;
  border-radius: 2px; }
  /* line 114, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal #hero-type-select label {
    background: #3a2f26;
    color: #c3997c;
    border: 2px solid #554639;
    margin: 0 2px; }
    /* line 120, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal #hero-type-select label.active {
      background: #211c15;
      border: 2px solid #403529;
      color: white; }

/* line 126, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .tab-content {
  position: absolute;
  top: 116px;
  left: 148px;
  width: 669px;
  height: 507px;
  overflow: hidden; }
  /* line 135, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .tab-content.filter-warrior .item.Ranger, #play-items-modal .tab-content.filter-warrior .item.Wizard {
    display: none; }
  /* line 139, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .tab-content.filter-ranger .item.Warrior, #play-items-modal .tab-content.filter-ranger .item.Wizard {
    display: none; }
  /* line 143, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .tab-content.filter-wizard .item.Ranger, #play-items-modal .tab-content.filter-wizard .item.Warrior {
    display: none; }

/* line 146, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .tab-pane {
  height: 100%; }
  /* line 149, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .tab-pane .nano-content {
    padding: 26px 51px 26px 26px; }

/* line 153, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .item {
  cursor: pointer;
  width: 187px;
  padding: 10px;
  height: 195px;
  float: left;
  background: url(/images/pages/play/modal/item-box-background.png);
  margin: 4px;
  text-align: center;
  position: relative; }
  /* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .item.silhouetted {
    cursor: default; }
  /* line 167, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .item strong {
    position: absolute;
    top: 7px;
    padding: 2px;
    left: 0;
    right: 0;
    font-size: 18px;
    z-index: 2;
    line-height: 18px;
    color: #161005; }
  /* line 178, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .item img {
    width: 90px;
    height: 90px; }
    /* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal .item img.item-img {
      top: 45px; }
    /* line 183, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal .item img.item-shadow {
      top: 55px; }
    /* line 185, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal .item img.item-silhouette {
      top: 25px;
      width: 110px;
      height: 110px; }
  /* line 190, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .item .glyphicon-lock {
    font-size: 60px;
    position: absolute;
    top: 50px;
    color: #958d7b;
    z-index: 1;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto; }
    /* line 201, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal .item .glyphicon-lock.bolder {
      font-weight: bolder;
      color: #d3c8af; }
  /* line 205, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .item .unlock-button, #play-items-modal .item .unequippable {
    right: 1px;
    bottom: 0;
    width: 93px;
    height: 41px;
    font-size: 16px; }
  /* line 212, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .item .unequippable {
    position: absolute;
    line-height: 41px;
    display: inline-block; }
  /* line 217, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .item .cost {
    position: absolute;
    height: 41px;
    left: 0;
    bottom: 0;
    width: 95px;
    line-height: 38px;
    font-size: 16px;
    color: #163d49;
    font-weight: bold; }
    /* line 228, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal .item .cost img {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      position: relative;
      top: -2px; }
  /* line 235, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .item .owned, #play-items-modal .item .locked {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 41px;
    color: #163d49;
    line-height: 38px;
    font-size: 16px; }
  /* line 245, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .item.selected {
    background: url(/images/pages/play/modal/item-box-background-selected.png); }

/* line 251, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal #item-details-view #item-title {
  left: 910px;
  top: 60px; }

/* line 255, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal #item-details-view #item-details-body {
  left: 860px; }

/* line 258, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal #item-details-view #selected-item-unlock-button {
  left: 856px; }

/* line 264, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .nano-pane, #inventory-modal .nano-pane {
  width: 16px;
  background: black;
  border: 3px solid #614c3a; }

/* line 269, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .nano-slider, #inventory-modal .nano-slider {
  background: #f4aa42;
  border: 3px solid black;
  border-radius: 10px;
  margin-left: -3px;
  margin-right: -3px; }

/* line 277, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .item-img, #play-items-modal .item-shadow, #play-items-modal .item-silhouette, #inventory-modal .item-img, #inventory-modal .item-shadow, #inventory-modal .item-silhouette {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0; }

/* line 285, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .item-img, #inventory-modal .item-img {
  z-index: 1; }

/* line 294, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .item-silhouette, #inventory-modal .item-silhouette {
  opacity: 0.2; }

/* line 298, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .required-level, #inventory-modal .required-level {
  position: absolute;
  left: 0;
  right: 5px;
  top: 70px;
  font-size: 20px;
  line-height: 20px;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 2; }

/* line 311, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
#play-items-modal .unlock-button, #inventory-modal .unlock-button {
  position: absolute;
  border: 3px solid #074153;
  background: #0077a8;
  color: white;
  font-size: 16px;
  border-radius: 0; }
  /* line 319, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal .unlock-button:disabled, #inventory-modal .unlock-button:disabled {
    background: #486a71;
    opacity: 1;
    color: rgba(255, 255, 255, 0.4); }

/* line 325, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
.popover.buy-gems-prompt {
  z-index: 1050;
  text-align: center; }
  /* line 329, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  .popover.buy-gems-prompt button {
    margin-top: 20px; }

@media only screen and (max-width: 1300px) {
  /* line 334, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
  #play-items-modal {
    overflow-x: hidden; }
    /* line 337, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal .tab-content {
      width: 469px; }
    /* line 340, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal #play-items-modal-bg {
      display: none; }
    /* line 343, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal #play-items-modal-narrow-bg {
      display: block; }
    /* line 346, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal .modal-dialog {
      width: 1024px;
      left: 25px; }
    /* line 350, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal h1 {
      display: none; }
    /* line 353, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal #gems-count-container {
      left: 213px; }
    /* line 356, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal #close-modal {
      left: 390px; }
    /* line 359, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal .nano-content {
      padding-left: 20px; }
    /* line 364, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal #item-details-view #item-title {
      left: 698px; }
    /* line 367, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal #item-details-view #item-details-body {
      left: 648px; }
    /* line 370, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
    #play-items-modal #item-details-view #selected-item-unlock-button, #play-items-modal #item-details-view .unequippable {
      left: 645px; } }

/* line 374, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
body[lang='pt-PT'] #play-items-modal .unlock-button, body[lang^='pt-BR'] #play-items-modal .unlock-button {
  font-size: 13px; }

/* line 378, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-items-modal.sass */
body[lang='ru'] #play-items-modal .unlock-button {
  font-size: 10px; }
