/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/world-select-modal.sass */
#world-select-modal {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }
  /* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/world-select-modal.sass */
  #world-select-modal .modal-body {
    max-height: inherit; }
  /* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/world-select-modal.sass */
  #world-select-modal .canvas-wrapper {
    position: relative; }
  /* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/world-select-modal.sass */
  #world-select-modal .normal-canvas {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none; }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/world-select-modal.sass */
  #world-select-modal .webgl-canvas {
    border: 1px solid black;
    background-color: #ddd; }
  /* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/world-select-modal.sass */
  #world-select-modal .instructions .alert {
    float: left;
    width: 23%;
    box-sizing: border-box;
    text-align: center;
    margin: 0 1% 10px; }
