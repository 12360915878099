/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
#choose-account-type-view .choose-type-title {
  display: flex;
  flex-direction: column;
  flex-grow: 0.5;
  justify-content: flex-end; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
#choose-account-type-view h4 {
  padding-bottom: 10px; }

/* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
#choose-account-type-view .path-cards {
  display: flex; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
#choose-account-type-view .path-card ~ .path-card {
  margin-left: 6.5px; }

/* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
#choose-account-type-view .path-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 235px;
  height: 340px;
  max-height: 42vh;
  border-style: solid;
  border-width: thin;
  border-radius: 5px; }
  /* line 30, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
  #choose-account-type-view .path-card.navy {
    border-color: #0E4C60; }
    /* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
    #choose-account-type-view .path-card.navy .card-title {
      background-color: #0E4C60; }
  /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
  #choose-account-type-view .path-card.forest {
    border-color: #20572B; }
    /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
    #choose-account-type-view .path-card.forest .card-title {
      background-color: #20572B; }
  /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
  #choose-account-type-view .path-card .card-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    max-height: 5vh;
    color: white;
    font-weight: bold;
    text-align: center; }
  /* line 52, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
  #choose-account-type-view .path-card .card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 20px; }
    /* line 59, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
    #choose-account-type-view .path-card .card-content ul {
      align-self: center;
      text-align: left;
      padding-left: 20px; }
      /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
      #choose-account-type-view .path-card .card-content ul li span {
        position: relative;
        left: -5px; }
  /* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
  #choose-account-type-view .path-card .card-footer {
    margin: 0 20px 20px;
    min-height: 62px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }

/* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
#choose-account-type-view .individual-section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 425px; }

/* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
#choose-account-type-view .individual-title {
  font-weight: bold; }

/* line 86, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
#choose-account-type-view .individual-description {
  margin: 0px 0px 20px 0px;
  width: 100%; }

/* line 90, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/create-account-modal/choose-account-type-view.sass */
#choose-account-type-view .text-h6 {
  color: white; }
