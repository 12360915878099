/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view #site-content-area {
  width: 100%; }

/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .big-stat {
  width: auto; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .total-count {
  color: green; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .remaining-count {
  color: blue; }

/* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .cancelled-count {
  color: red; }

/* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .churn-count {
  color: orange; }

/* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .growth-rate {
  color: green; }

/* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .count {
  font-size: 50pt; }

/* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .description {
  font-size: 8pt; }

/* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .line-graph-label {
  font-size: 10pt;
  font-weight: normal; }

/* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .line-graph-container {
  height: 500px;
  width: 100%; }
  /* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
  #admin-analytics-subscriptions-view .line-graph-container .x.axis {
    font-size: 9pt; }
    /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
    #admin-analytics-subscriptions-view .line-graph-container .x.axis path {
      display: none; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
  #admin-analytics-subscriptions-view .line-graph-container .y.axis {
    font-size: 9pt; }
    /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
    #admin-analytics-subscriptions-view .line-graph-container .y.axis path {
      display: none; }
  /* line 40, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
  #admin-analytics-subscriptions-view .line-graph-container .key-line {
    font-size: 9pt; }
  /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
  #admin-analytics-subscriptions-view .line-graph-container .key-text {
    font-size: 9pt; }
  /* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
  #admin-analytics-subscriptions-view .line-graph-container .graph-point-info-container {
    display: none;
    position: absolute;
    padding: 10px;
    border: 1px solid black;
    z-index: 3;
    background-color: blanchedalmond;
    font-size: 10pt; }

/* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .subscribers-thead {
  font-size: 10pt; }
  /* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
  #admin-analytics-subscriptions-view .subscribers-thead th {
    padding: 2px; }

/* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
#admin-analytics-subscriptions-view .subscribers-tbody {
  font-size: 9pt; }
  /* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics-subscriptions.sass */
  #admin-analytics-subscriptions-view .subscribers-tbody td {
    padding: 2px;
    max-width: 160px;
    overflow: hidden; }
