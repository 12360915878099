/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/add-thang-components-modal.sass */
#add-thang-components-modal .list-group {
  max-height: 500px;
  overflow: scroll; }

/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/add-thang-components-modal.sass */
#add-thang-components-modal .item-title {
  cursor: pointer; }
  /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/add-thang-components-modal.sass */
  #add-thang-components-modal .item-title .glyphicon-chevron-down {
    display: none; }
  /* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/add-thang-components-modal.sass */
  #add-thang-components-modal .item-title.collapsed .glyphicon-chevron-down {
    display: inline; }
  /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/add-thang-components-modal.sass */
  #add-thang-components-modal .item-title.collapsed .glyphicon-chevron-up {
    display: none; }

/* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/add-thang-components-modal.sass */
#add-thang-components-modal .list-group-item {
  padding: 5px; }

/* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/component/add-thang-components-modal.sass */
#add-thang-components-modal .checkbox, #add-thang-components-modal .item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
