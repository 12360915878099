@-webkit-keyframes castablePulse {
  from {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; }
  50% {
    -webkit-box-shadow: 0px 0px 35px skyblue;
    box-shadow: 0px 0px 35px skyblue;
    color: skyblue; }
  to {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; } }

@-moz-keyframes castablePulse {
  from {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; }
  50% {
    -webkit-box-shadow: 0px 0px 35px skyblue;
    box-shadow: 0px 0px 35px skyblue;
    color: skyblue; }
  to {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; } }

@-ms-keyframes castablePulse {
  /* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  from {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; }
  /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  50% {
    -webkit-box-shadow: 0px 0px 35px skyblue;
    box-shadow: 0px 0px 35px skyblue;
    color: skyblue; }
  /* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  to {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; } }

@-o-keyframes castablePulse {
  from {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; }
  50% {
    -webkit-box-shadow: 0px 0px 35px skyblue;
    box-shadow: 0px 0px 35px skyblue;
    color: skyblue; }
  to {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; } }

@keyframes castablePulse {
  from {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; }
  50% {
    -webkit-box-shadow: 0px 0px 35px skyblue;
    box-shadow: 0px 0px 35px skyblue;
    color: skyblue; }
  to {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; } }

@-webkit-keyframes winnablePulse {
  from {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; }
  50% {
    -webkit-box-shadow: 0px 0px 35px #87CEFF;
    box-shadow: 0px 0px 35px #87CEFF;
    color: #87CEFF; }
  to {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; } }

@-moz-keyframes winnablePulse {
  from {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; }
  50% {
    -webkit-box-shadow: 0px 0px 35px #87CEFF;
    box-shadow: 0px 0px 35px #87CEFF;
    color: #87CEFF; }
  to {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; } }

@-ms-keyframes winnablePulse {
  /* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  from {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  50% {
    -webkit-box-shadow: 0px 0px 35px #87CEFF;
    box-shadow: 0px 0px 35px #87CEFF;
    color: #87CEFF; }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  to {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; } }

@-o-keyframes winnablePulse {
  from {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; }
  50% {
    -webkit-box-shadow: 0px 0px 35px #87CEFF;
    box-shadow: 0px 0px 35px #87CEFF;
    color: #87CEFF; }
  to {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; } }

@keyframes winnablePulse {
  from {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; }
  50% {
    -webkit-box-shadow: 0px 0px 35px #87CEFF;
    box-shadow: 0px 0px 35px #87CEFF;
    color: #87CEFF; }
  to {
    -webkit-box-shadow: 0px 0px 8px #333;
    box-shadow: 0px 0px 8px #333;
    color: white; } }

/* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
#cast-button-view {
  display: none;
  position: absolute;
  z-index: 2;
  width: 100%;
  border-radius: 6px;
  background: transparent url(/images/level/code_toolbar_background.png);
  background-size: 100% 100%;
  left: -15px;
  right: 15px;
  height: 94px;
  margin-top: -8px;
  padding: 20px 2.8%; }
  /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  #cast-button-view .btn.btn-illustrated {
    height: 46px;
    font-size: 24px;
    line-height: 24px;
    width: 45%;
    width: -webkit-calc(50% - 10px);
    width: calc(50% - 10px);
    border-style: solid;
    border-image: url(/images/level/code_toolbar_run_button_active.png) 14 20 20 20 fill round;
    border-width: 7px 10px 10px 10px; }
    /* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
    #cast-button-view .btn.btn-illustrated:active {
      border-image: url(/images/level/code_toolbar_run_button_active_pressed.png) 14 20 20 20 fill round;
      padding: 2px 0 0 2px; }
    /* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
    #cast-button-view .btn.btn-illustrated.submit-button, #cast-button-view .btn.btn-illustrated.done-button, #cast-button-view .btn.btn-illustrated.rank-button {
      margin-left: 10px;
      border-image: url(/images/level/code_toolbar_submit_button_active.png) 14 20 20 20 fill round; }
      /* line 61, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
      #cast-button-view .btn.btn-illustrated.submit-button:active, #cast-button-view .btn.btn-illustrated.done-button:active, #cast-button-view .btn.btn-illustrated.rank-button:active {
        border-image: url(/images/level/code_toolbar_submit_button_active_pressed.png) 14 20 20 20 fill round; }
  /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  #cast-button-view .ladder-submission-view {
    width: 45%;
    width: -webkit-calc(50% - 10px);
    width: calc(50% - 10px);
    display: inline-block; }
    /* line 70, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
    #cast-button-view .ladder-submission-view .btn.btn-illustrated {
      width: 100%;
      font-size: 18px; }
  /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  #cast-button-view .cast-button {
    opacity: 0.77;
    filter: alpha(opacity=77); }
    /* line 78, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
    #cast-button-view .cast-button:hover, #cast-button-view .cast-button.castable {
      opacity: 1;
      filter: alpha(opacity=100); }
  /* line 81, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  #cast-button-view .submit-button > *, #cast-button-view .done-button > * {
    opacity: 0.9;
    filter: alpha(opacity=90); }
    /* line 84, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
    #cast-button-view .submit-button > *:hover, #cast-button-view .done-button > *:hover {
      opacity: 1;
      filter: alpha(opacity=100); }
  /* line 91, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  #cast-button-view:not(.winnable) .btn.btn-illustrated.cast-button.castable {
    font-weight: bold;
    -webkit-animation: castablePulse 3s infinite;
    animation: castablePulse 3s infinite;
    border-image: url(/images/level/code_toolbar_run_button_zazz.png) 14 20 20 20 fill round; }
    /* line 96, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
    #cast-button-view:not(.winnable) .btn.btn-illustrated.cast-button.castable:active {
      border-image: url(/images/level/code_toolbar_run_button_zazz_pressed.png) 14 20 20 20 fill round; }
  /* line 99, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  #cast-button-view:not(.winnable) .btn.btn-illustrated.submit-button {
    font-size: 16px; }
  /* line 102, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  #cast-button-view.winnable .btn.btn-illustrated.cast-button {
    font-size: 16px; }
  /* line 108, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  #cast-button-view.winnable.has-seen-winning-replay .btn.btn-illustrated.submit-button, #cast-button-view.winnable.has-seen-winning-replay .btn.btn-illustrated.done-button {
    font-weight: bold;
    -webkit-animation: winnablePulse 3s infinite;
    animation: winnablePulse 3s infinite;
    border-image: url(/images/level/code_toolbar_submit_button_zazz.png) 14 20 20 20 fill round; }
    /* line 114, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
    #cast-button-view.winnable.has-seen-winning-replay .btn.btn-illustrated.submit-button:active, #cast-button-view.winnable.has-seen-winning-replay .btn.btn-illustrated.done-button:active {
      border-image: url(/images/level/code_toolbar_submit_button_zazz_pressed.png) 14 20 20 20 fill round; }

/* line 119, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
html.no-borderimage #cast-button-view .btn.btn-illustrated {
  border: 0;
  background-image: url(/images/level/code_toolbar_run_button_active.png);
  background-size: 100% 100%;
  padding: 7px 10px 10px 10px; }
  /* line 125, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  html.no-borderimage #cast-button-view .btn.btn-illustrated:active {
    background-image: url(/images/level/code_toolbar_run_button_active_pressed.png);
    padding: 9px 8px 8px 12px;
    border: 0; }
  /* line 130, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  html.no-borderimage #cast-button-view .btn.btn-illustrated.submit-button, html.no-borderimage #cast-button-view .btn.btn-illustrated.done-button {
    background-image: url(/images/level/code_toolbar_submit_button_active.png);
    border: 0; }
    /* line 134, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
    html.no-borderimage #cast-button-view .btn.btn-illustrated.submit-button:active, html.no-borderimage #cast-button-view .btn.btn-illustrated.done-button:active {
      background-image: url(/images/level/code_toolbar_submit_button_active_pressed.png);
      border: 0; }

/* line 140, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
html.no-borderimage #cast-button-view:not(.winnable) .btn.btn-illustrated.cast-button.castable {
  border: 0;
  background-image: url(/images/level/code_toolbar_run_button_zazz.png); }
  /* line 144, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  html.no-borderimage #cast-button-view:not(.winnable) .btn.btn-illustrated.cast-button.castable:active {
    background-image: url(/images/level/code_toolbar_run_button_zazz_pressed.png); }

/* line 149, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
html.no-borderimage #cast-button-view.winnable .btn.btn-illustrated.submit-button, html.no-borderimage #cast-button-view.winnable .btn.btn-illustrated.done-button {
  border: 0;
  background-image: url(/images/level/code_toolbar_submit_button_zazz.png); }
  /* line 153, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/cast_button.sass */
  html.no-borderimage #cast-button-view.winnable .btn.btn-illustrated.submit-button:active, html.no-borderimage #cast-button-view.winnable .btn.btn-illustrated.done-button:active {
    border: 0;
    background-image: url(/images/level/code_toolbar_submit_button_zazz_pressed.png); }
