/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/course-details.sass */
#course-details-view .available-courses-title {
  font-size: 20px;
  font-weight: bold;
  margin: 4px; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/course-details.sass */
#course-details-view .concept {
  display: inline-block;
  white-space: nowrap;
  font-size: 12px;
  line-height: 12px;
  border: 1px solid gray;
  margin: 0px;
  padding: 2px; }

/* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/course-details.sass */
#course-details-view .jumbotron form {
  margin-top: -40px;
  margin-bottom: 20px; }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/course-details.sass */
  #course-details-view .jumbotron form .btn {
    margin-top: -15px; }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/course-details.sass */
  #course-details-view .jumbotron form .no-school {
    width: 30%; }
  /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/course-details.sass */
  #course-details-view .jumbotron form .save-school {
    margin-left: 10%;
    width: 60%; }

/* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/course-details.sass */
#course-details-view .jumbotron .btn:not(.btn-submit) {
  white-space: normal;
  min-height: 200px; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/course-details.sass */
  #course-details-view .jumbotron .btn:not(.btn-submit) h1 {
    font-size: 48px; }

/* line 39, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/course-details.sass */
#course-details-view .btn-view-project-level {
  margin-left: 10px; }
