/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
.search-view a.open-modal-button {
  float: right; }

/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
.search-view input#search {
  width: 190px;
  height: 30px;
  padding-left: 5px; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
.search-view table.table {
  background-color: white; }
  /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
  .search-view table.table .body-row, .search-view table.table .name-row, .search-view table.table .description-row, .search-view table.table .small-name-row, .search-view table.table .watch-row {
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  /* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
  .search-view table.table .name-row {
    max-width: 300px; }
  /* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
  .search-view table.table .description-row {
    max-width: 520px; }
  /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
  .search-view table.table .small-name-row {
    max-width: 200px; }
  /* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
  .search-view table.table .watch-row {
    max-width: 80px;
    text-align: center; }
    /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
    .search-view table.table .watch-row.watching {
      opacity: 1.0; }
    /* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
    .search-view table.table .watch-row.not-watching {
      opacity: 0.5; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
  .search-view table.table tr.mine {
    background-color: #f8ecaa; }

/* line 39, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
.search-view .loading {
  text-align: center; }

/* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
.search-view .progress {
  margin: 0 20%;
  width: 60%; }

/* line 46, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/kinds/search.sass */
.search-view .progress-bar {
  width: 100%; }
