/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
#about-view {
  overflow: hidden; }
  /* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #nav-container {
    min-height: 55px; }
    /* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #nav-container nav {
      background: #F2BE19; }
      /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #nav-container nav.affix {
        z-index: 1;
        position: fixed;
        top: 0;
        width: 100%; }
      /* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #nav-container nav ul {
        margin-top: 5px;
        padding-left: 0;
        list-style: none; }
        /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #nav-container nav ul li {
          height: 22pt;
          display: inline-block;
          margin: 10px 0 0; }
          @media (min-width: 480px) {
            /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
            #about-view #nav-container nav ul li {
              margin: 10px 5px 0; } }
          @media (min-width: 768px) {
            /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
            #about-view #nav-container nav ul li {
              margin: 10px 18px 0; } }
          /* line 30, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
          #about-view #nav-container nav ul li a {
            color: white;
            text-transform: uppercase;
            text-decoration: none; }
        /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #nav-container nav ul li.active .label {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 0;
          margin-left: 0.6em;
          margin-right: 0.6em;
          border-bottom: 4px solid white;
          border-radius: 0; }
  /* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #jumbotron {
    background-color: #0E4C60;
    background-image: url("/images/pages/about/codebackground_zoom_compressed.png");
    background-size: cover;
    background-repeat: no-repeat; }
    /* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #jumbotron h1 {
      color: white;
      margin-top: 100px; }
    /* line 54, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #jumbotron h2 {
      color: white;
      margin-bottom: 100px; }
  /* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view .responsive-side-margins, #about-view h3, #about-view h4, #about-view p {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 768px) {
      /* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view .responsive-side-margins, #about-view h3, #about-view h4, #about-view p {
        max-width: inherit; } }
  /* line 65, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view h3 {
    margin-top: 60px; }
    @media (min-width: 768px) {
      /* line 65, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view h3 {
        margin-top: 150px; } }
  /* line 70, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #about-container {
    background-color: white; }
    @media (min-width: 768px) {
      /* line 70, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #about-container {
        margin-top: 85px; } }
  /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #mission-text {
    margin-top: 30px; }
    @media (min-width: 768px) {
      /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #mission-text {
        margin-top: 45px; } }
  /* line 80, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #mission-graphic, #about-view #community-graphic {
    padding: 30px 40px;
    position: relative;
    min-height: 320px; }
    @media (min-width: 768px) {
      /* line 80, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #mission-graphic, #about-view #community-graphic {
        min-height: 250px; } }
    /* line 87, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #mission-graphic h2, #about-view #community-graphic h2 {
      color: white;
      width: 50%; }
  /* line 91, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #mission-graphic {
    margin-top: 30px;
    background: #0E4C60; }
    @media (min-width: 768px) {
      /* line 91, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #mission-graphic {
        margin-top: 45px; } }
    /* line 97, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #mission-graphic h2 {
      float: right; }
    /* line 100, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #mission-graphic img {
      position: absolute;
      bottom: 0;
      left: 0; }
    /* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #mission-graphic #mission-graphic-filler {
      background: #0E4C60;
      height: 100%;
      width: 2000px;
      position: absolute;
      right: 100%;
      top: 0; }
  /* line 114, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #team ul {
    text-align: center;
    margin-top: 40px;
    padding: 0; }
    /* line 118, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #team ul li {
      width: 200px;
      height: 230px;
      list-style: none;
      display: inline-block;
      text-align: center;
      color: black; }
      /* line 125, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #team ul li small {
        display: block;
        white-space: nowrap; }
      /* line 128, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #team ul li .img-thumbnail {
        border-radius: 50%;
        padding: 0;
        background-color: #7D0101; }
      /* line 133, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #team ul li .avatar {
        display: none; }
      /* line 135, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #team ul li .headshot {
        border: 5px solid #fff; }
    /* line 137, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #team ul .profile-pic:hover .headshot {
      display: none; }
    /* line 139, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #team ul .profile-pic:hover .avatar {
      display: inline;
      border: 5px solid #F2BE19; }
  /* line 147, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #community-row-1 #community-avatars {
    width: 90%;
    margin: 20px 5%; }
  /* line 151, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #community-row-2 {
    margin-top: 35px; }
    @media (min-width: 768px) {
      /* line 151, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #community-row-2 {
        margin-top: 70px; } }
    /* line 156, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #community-row-2 #community-graphic {
      background: #7D0101; }
      /* line 159, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #community-row-2 #community-graphic img {
        position: absolute;
        right: 0;
        bottom: 0; }
      /* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #community-row-2 #community-graphic #community-graphic-filler {
        background: #7D0101;
        height: 100%;
        width: 2000px;
        position: absolute;
        left: 100%;
        top: 0; }
  /* line 172, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #story {
    font-family: "Arvo", serif;
    font-variant: normal; }
    /* line 176, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #story #story-graphic-1 {
      max-width: 580px;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto; }
      @media (min-width: 768px) {
        /* line 176, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #story #story-graphic-1 {
          margin-top: 80px; } }
      /* line 183, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #story #story-graphic-1 .media-heading {
        color: #7D0101; }
    /* line 185, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #story #story-graphic-2 {
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      max-width: 390px; }
      @media (min-width: 768px) {
        /* line 185, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #story #story-graphic-2 {
          margin-top: 80px; } }
      /* line 193, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #story #story-graphic-2 .media-heading {
        color: #20572B; }
    /* line 195, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #story #story-graphic-3 {
      margin-top: 50px; }
      @media (min-width: 768px) {
        /* line 195, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #story #story-graphic-3 {
          margin-top: 80px; } }
      /* line 199, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #story #story-graphic-3 p {
        margin-top: 30px; }
      /* line 201, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #story #story-graphic-3 img {
        margin-top: 20px; }
      /* line 203, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #story #story-graphic-3 #story-bracketed-text {
        width: 100%;
        max-width: 640px;
        margin: 0 auto; }
        /* line 207, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #story #story-graphic-3 #story-bracketed-text .text-h1 {
          display: inline-block;
          margin: auto 0;
          color: #0E4C60;
          width: 80%;
          vertical-align: bottom; }
        /* line 213, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #story #story-graphic-3 #story-bracketed-text img {
          margin: auto 0; }
        /* line 215, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #story #story-graphic-3 #story-bracketed-text #left-bracket {
          width: 10%;
          display: inline-block; }
        /* line 218, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #story #story-graphic-3 #story-bracketed-text #right-bracket {
          width: 10%;
          display: inline-block; }
      /* line 221, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #story #story-graphic-3 #story-languages {
        margin-top: 50px; }
        /* line 223, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #story #story-graphic-3 #story-languages #language-icons {
          display: inline-block;
          padding-left: auto;
          padding-right: auto; }
    /* line 228, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #story #story-graphic-4 {
      margin-left: auto;
      margin-right: auto;
      margin-top: 50px; }
      @media (min-width: 768px) {
        /* line 228, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #story #story-graphic-4 {
          margin-top: 150px; } }
      /* line 234, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #story #story-graphic-4 p {
        margin-top: 20px; }
      /* line 237, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #story #story-graphic-4 figure img {
        display: block;
        margin: 0 auto; }
  /* line 245, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #jobs #jobs-row #benefits, #about-view #jobs #jobs-row .job-listing {
    margin-top: 65px;
    border: thin solid #0E4C60;
    border-radius: 8px;
    padding: 40px;
    width: 370px;
    height: 420px;
    margin-left: auto;
    margin-right: auto; }
    /* line 254, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #jobs #jobs-row #benefits h5, #about-view #jobs #jobs-row .job-listing h5 {
      text-align: center;
      color: inherit; }
    /* line 257, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #jobs #jobs-row #benefits li, #about-view #jobs #jobs-row .job-listing li {
      padding-bottom: 7px; }
  /* line 259, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #jobs #jobs-row #benefits {
    color: white;
    background: #0E4C60; }
    /* line 262, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #jobs #jobs-row #benefits ul {
      margin-top: 20px; }
  /* line 264, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #jobs #jobs-row a.benefits-link {
    color: white;
    text-decoration: underline; }
  /* line 267, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #jobs #jobs-row .job-listing {
    color: #0E4C60;
    background: white;
    position: relative; }
    /* line 272, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #jobs #jobs-row .job-listing .label {
      text-transform: uppercase;
      color: grey; }
    /* line 275, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #jobs #jobs-row .job-listing a.job-link {
      width: 170px;
      bottom: 45px;
      position: absolute;
      left: 50%;
      margin-left: -85px; }
    /* line 282, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #jobs #jobs-row .job-listing p {
      margin-top: 15px; }
  /* line 285, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #files {
    padding: 30px 0;
    margin-top: 50px;
    border: thin solid gray;
    border-radius: 8px; }
    /* line 290, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #files .label {
      color: black;
      display: block; }
    /* line 293, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #files #screenshots {
      text-align: center; }
      /* line 296, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #files #screenshots #screenshot-grid img {
        display: inline-block;
        margin: 6.5px; }
    /* line 300, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #files #downloads-container {
      position: relative;
      height: 250px; }
      /* line 303, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
      #about-view #files #downloads-container #downloads {
        margin: 20px auto 0;
        width: 260px; }
        /* line 307, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #files #downloads-container #downloads ul {
          width: 260px;
          margin-top: 10px;
          margin-bottom: 20px;
          margin-left: -25px; }
        /* line 312, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #files #downloads-container #downloads a {
          color: black; }
        /* line 314, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
        #about-view #files #downloads-container #downloads #download-button {
          margin: 0 auto;
          color: #0E4C60; }
          /* line 317, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
          #about-view #files #downloads-container #downloads #download-button .glyphicon-download-alt {
            margin-right: 15px;
            vertical-align: middle;
            font-size: 1.5em; }
  /* line 323, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #screenshot-lightbox .modal-dialog {
    width: auto;
    max-width: 1024px; }
  /* line 327, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
  #about-view #location {
    margin-top: 75px;
    margin-bottom: 100px;
    text-align: center; }
    /* line 331, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #location p b {
      margin-top: 40px; }
    /* line 333, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #location a {
      color: inherit;
      text-decoration: underline; }
    /* line 336, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
    #about-view #location iframe {
      border: 2px solid lightgray; }

/* line 339, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/about.sass */
.anchor::before {
  content: "";
  display: block;
  height: 55px;
  margin: -55px 0 0 0; }
