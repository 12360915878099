/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/ap-cs-principles.sass */
#ap-cs-principles-view .content {
  max-width: 900px;
  margin: auto; }
  /* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/ap-cs-principles.sass */
  #ap-cs-principles-view .content h4 {
    padding: 0 0 10px 0; }
  /* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/ap-cs-principles.sass */
  #ap-cs-principles-view .content ul {
    margin: 0 0 40px 0; }
    /* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/ap-cs-principles.sass */
    #ap-cs-principles-view .content ul p {
      font-size: .8em; }
