/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
#leaderboard-modal .modal-dialog {
  width: 820px;
  height: 570px;
  padding: 0;
  background: none;
  position: relative;
  top: 40px; }

/* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
#leaderboard-modal #leaderboard-background {
  position: absolute;
  top: -126px;
  left: -3px; }

/* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
#leaderboard-modal .level-title {
  position: absolute;
  left: 172px;
  top: -46px;
  margin: 0;
  width: 457px;
  text-align: center;
  color: #febc44;
  font-size: 38px;
  text-shadow: black 4px 4px 0, black -4px -4px 0, black 4px -4px 0, black -4px 4px 0, black 4px 0px 0, black 0px -4px 0, black -4px 0px 0, black 0px 4px 0; }

/* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
#leaderboard-modal #close-modal {
  position: absolute;
  left: 616px;
  top: -51px;
  width: 60px;
  height: 60px;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 17px;
  cursor: pointer;
  z-index: 2;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg); }
  /* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
  #leaderboard-modal #close-modal:hover {
    color: yellow; }

/* line 61, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
#leaderboard-modal #leaderboard-nav {
  position: absolute;
  top: 53px;
  left: 42px;
  width: 178px; }
  /* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
  #leaderboard-modal #leaderboard-nav li {
    background: url(/images/pages/play/modal/menu-tab.png);
    padding: 5px;
    margin: -5px 0;
    height: 80px;
    padding: 0; }
    /* line 74, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
    #leaderboard-modal #leaderboard-nav li.active {
      background: url(/images/pages/play/modal/menu-tab-selected.png);
      width: 197px; }
    /* line 78, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
    #leaderboard-modal #leaderboard-nav li a {
      font-size: 18px;
      line-height: 25px;
      background: none;
      color: #c3997c;
      font-weight: bold;
      padding: 14px 20px;
      font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
      text-transform: uppercase; }
      /* line 88, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
      #leaderboard-modal #leaderboard-nav li a .timespan {
        margin-left: 20px;
        opacity: 0.75; }

/* line 95, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
#leaderboard-modal .leaderboard-tab-content {
  position: absolute;
  left: 219px;
  top: 55px;
  width: 571px;
  height: 490px;
  padding: 50px;
  overflow-y: scroll; }

/* line 104, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
#leaderboard-modal ::-webkit-scrollbar {
  display: none; }

/* line 110, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
#leaderboard-modal .tooltip pre {
  text-align: left;
  width: 500px;
  font-size: 10px; }

/* line 118, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-modal.sass */
body[lang='ru'] #leaderboard-modal #leaderboard-nav a {
  font-size: 13px; }
