/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
#playback-view {
  width: calc(57% -  200px);
  height: 60px;
  padding-top: 17px;
  position: relative;
  background: transparent url(/images/level/scrubber_background.png);
  background-size: 100% 100%;
  margin-top: 40px;
  z-index: 3; }
  /* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #level-view.no-api #playback-view {
    width: 57%; }
  /* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view.controls-disabled {
    pointer-events: none;
    -webkit-filter: brightness(50%);
    -moz-filter: brightness(50%);
    -o-filter: brightness(50%);
    filter: brightness(50%); }
  /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view button {
    font-size: 26px;
    margin-left: 10px;
    background: transparent;
    opacity: 0.75;
    filter: alpha(opacity=75);
    color: #f8c592;
    text-shadow: 1px 1px 0px black; }
    /* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
    #playback-view button .glyphicon {
      position: relative; }
  /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view button:hover {
    opacity: 1;
    filter: alpha(opacity=100); }
  /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #play-button, #playback-view #volume-button, #playback-view #music-button {
    float: left;
    position: relative; }
  /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #music-button {
    opacity: 0.5;
    filter: alpha(opacity=50); }
    /* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
    #playback-view #music-button span {
      position: relative;
      left: -3px;
      top: -2px; }
    /* line 48, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
    #playback-view #music-button:hover {
      opacity: 0.75;
      filter: alpha(opacity=75); }
    /* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
    #playback-view #music-button.music-on {
      opacity: 0.75;
      filter: alpha(opacity=75); }
      /* line 52, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
      #playback-view #music-button.music-on:hover {
        opacity: 1;
        filter: alpha(opacity=100); }
  /* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #play-button .glyphicon, #playback-view #volume-button .glyphicon {
    display: none; }
  /* line 59, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #settings-button {
    padding-left: 4px;
    padding-right: 4px; }
  /* line 63, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #playback-settings {
    float: right;
    position: relative;
    margin-right: 10px; }
    /* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
    #playback-view #playback-settings ul button {
      margin: 0 10px; }
    /* line 69, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
    #playback-view #playback-settings li:hover {
      background: #add8e6; }
  /* line 72, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #play-button.disabled .glyphicon {
    opacity: 0.75;
    filter: alpha(opacity=75); }
  /* line 74, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #play-button.playing .glyphicon-pause {
    display: inline-block; }
  /* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #play-button.paused .glyphicon-play {
    display: inline-block; }
  /* line 78, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #play-button.ended .glyphicon-repeat {
    display: inline-block; }
  /* line 81, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #volume-button.vol-up .glyphicon.glyphicon-volume-up {
    display: inline-block; }
  /* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #volume-button.vol-off .glyphicon.glyphicon-volume-off {
    display: inline-block;
    opacity: 0.75;
    filter: alpha(opacity=75); }
    /* line 86, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
    #playback-view #volume-button.vol-off .glyphicon.glyphicon-volume-off:hover {
      opacity: 1;
      filter: alpha(opacity=100); }
  /* line 88, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view #volume-button.vol-down .glyphicon.glyphicon-volume-down {
    display: inline-block; }
  /* line 91, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  #playback-view .scrubber {
    position: absolute;
    left: 170px;
    top: 21px;
    bottom: 0px;
    right: 175px;
    background: #030303;
    height: 28px;
    border: 1px solid #43432c;
    border-radius: 14px; }
    /* line 102, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
    #playback-view .scrubber .scrubber-inner {
      border: 1px solid #2c261d;
      width: 100%;
      height: 100%;
      border-radius: 14px;
      padding: 6px 8px; }
    /* line 109, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
    #playback-view .scrubber .progress {
      float: left;
      width: 100%;
      height: 12px;
      cursor: pointer;
      overflow: visible;
      border: 1px solid #444;
      background: #504335;
      border-radius: 6px;
      border: 0; }
      /* line 122, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
      #playback-view .scrubber .progress.disabled, #playback-view .scrubber .progress.ui-slider-disabled {
        cursor: default; }
        /* line 125, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
        #playback-view .scrubber .progress.disabled .progress-bar .scrubber-handle, #playback-view .scrubber .progress.ui-slider-disabled .progress-bar .scrubber-handle {
          cursor: default; }
      /* line 128, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
      #playback-view .scrubber .progress .progress-bar {
        -webkit-transition: width 0s linear;
        transition: width 0s linear;
        position: relative;
        background: #f5aa31;
        border: 1px solid #3e2d10;
        border-radius: 6px; }
        /* line 136, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
        #playback-view .scrubber .progress .progress-bar .scrubber-handle {
          cursor: pointer;
          position: absolute;
          right: -18px;
          top: -11px;
          background: transparent url(/images/level/scrubber_knob.png);
          background-size: contain;
          width: 36px;
          height: 36px; }
      /* line 146, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
      #playback-view .scrubber .progress .ui-slider-handle {
        height: 100%;
        visibility: hidden;
        border: 0;
        top: 0;
        margin-left: -11px;
        margin-right: -11px;
        background: transparent none; }

/* line 156, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
body.ipad #playback-view {
  margin-top: 20px; }
  /* line 160, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  body.ipad #playback-view #playback-settings {
    display: none; }
  /* line 162, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/level-playback-view.sass */
  body.ipad #playback-view .scrubber {
    right: 25px; }
