/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-web-dev-level-view.sass */
#play-web-dev-level-view #web-surface-view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-web-dev-level-view.sass */
#play-web-dev-level-view #info-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100px;
  z-index: 1;
  background-color: transparent;
  text-align: center; }
