/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-tab-view.sass */
.leaderboard-tab-view h1 {
  margin-top: -40px;
  color: #febc44;
  font-size: 30px;
  text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-tab-view.sass */
.leaderboard-tab-view table td {
  padding: 1px 2px;
  font-size: 16px;
  text-align: center; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-tab-view.sass */
.leaderboard-tab-view table th {
  text-align: center; }

/* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-tab-view.sass */
.leaderboard-tab-view table tbody tr.viewable {
  cursor: pointer; }

/* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-tab-view.sass */
.leaderboard-tab-view table tbody .rank-cell {
  font-weight: bold; }

/* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-tab-view.sass */
.leaderboard-tab-view table tbody .name-col-cell {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/leaderboard-tab-view.sass */
.leaderboard-tab-view table tbody .hero-portrait-cell, .leaderboard-tab-view table tbody .code-language-cell {
  background: transparent url(/images/common/code_languages/javascript_small.png) no-repeat center center;
  background-size: 30px 30px;
  height: 30px;
  width: 32px; }
