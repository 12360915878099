/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/campaign/campaign-analytics-modal.sass */
#campaign-analytics-modal td {
  font-size: 9pt;
  max-width: 60px; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/campaign/campaign-analytics-modal.sass */
#campaign-analytics-modal td.completion-rate {
  max-width: 1000px; }

/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/campaign/campaign-analytics-modal.sass */
#campaign-analytics-modal td.level {
  max-width: 1000px; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/campaign/campaign-analytics-modal.sass */
#campaign-analytics-modal .modal-dialog {
  width: 85%; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/campaign/campaign-analytics-modal.sass */
#campaign-analytics-modal .level-name-container {
  position: relative;
  max-width: 1000px; }
  /* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/campaign/campaign-analytics-modal.sass */
  #campaign-analytics-modal .level-name-container .level-name-background {
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: green;
    opacity: 0.25; }

/* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/campaign/campaign-analytics-modal.sass */
#campaign-analytics-modal .level-completion-container {
  position: relative;
  max-width: 1000px; }
  /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/campaign/campaign-analytics-modal.sass */
  #campaign-analytics-modal .level-completion-container .level-completion-background {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px; }

/* line 30, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/campaign/campaign-analytics-modal.sass */
#campaign-analytics-modal .level-playtime-container {
  position: relative; }
  /* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/campaign/campaign-analytics-modal.sass */
  #campaign-analytics-modal .level-playtime-container .level-playtime-background {
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: green;
    opacity: 0.25; }
