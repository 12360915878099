/* line 1, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clans.sass */
#clans-view {
  color: black; }
  /* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clans.sass */
  #clans-view .clan-title {
    cursor: pointer; }
  /* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clans.sass */
  #clans-view .create-clan-description {
    width: 50%; }
  /* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clans.sass */
  #clans-view .popover {
    max-width: 100%; }
    /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clans.sass */
    #clans-view .popover h3 {
      background: transparent;
      border: 0;
      font-size: 30px;
      color: black; }
