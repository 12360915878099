/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/system/level-system-edit-view.sass */
#level-system-edit-view nav {
  margin-bottom: 0; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/system/level-system-edit-view.sass */
#level-system-edit-view #system-patches {
  padding: 0 10px 10px;
  background: white; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/system/level-system-edit-view.sass */
#level-system-edit-view .patches-view {
  padding: 10px 20px 10px 0px; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/system/level-system-edit-view.sass */
#level-system-edit-view .navbar-text {
  float: left; }

/* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/system/level-system-edit-view.sass */
#level-system-edit-view #system-code-editor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 35px;
  border: 2px solid black;
  border-top: none; }

/* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/system/level-system-edit-view.sass */
#level-system-edit-view .inner-editor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px; }

/* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/system/level-system-edit-view.sass */
#level-system-edit-view #patch-system-button {
  display: none; }
