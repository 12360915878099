@-webkit-keyframes levelStartedPulse {
  from {
    -webkit-box-shadow: 0px 0px 4px #333;
    box-shadow: 0px 0px 4px #333;
    margin-bottom: -0.25499%; }
  50% {
    -webkit-box-shadow: 0px 0px 22px skyblue;
    box-shadow: 0px 0px 22px skyblue;
    margin-bottom: -0.06375%; }
  to {
    -webkit-box-shadow: 0px 0px 4px #333;
    box-shadow: 0px 0px 4px #333;
    margin-bottom: -0.25499%; } }

@-moz-keyframes levelStartedPulse {
  from {
    -webkit-box-shadow: 0px 0px 4px #333;
    box-shadow: 0px 0px 4px #333;
    margin-bottom: -0.25499%; }
  50% {
    -webkit-box-shadow: 0px 0px 22px skyblue;
    box-shadow: 0px 0px 22px skyblue;
    margin-bottom: -0.06375%; }
  to {
    -webkit-box-shadow: 0px 0px 4px #333;
    box-shadow: 0px 0px 4px #333;
    margin-bottom: -0.25499%; } }

@-ms-keyframes levelStartedPulse {
  /* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  from {
    -webkit-box-shadow: 0px 0px 4px #333;
    box-shadow: 0px 0px 4px #333;
    margin-bottom: -0.25499%; }
  /* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  50% {
    -webkit-box-shadow: 0px 0px 22px skyblue;
    box-shadow: 0px 0px 22px skyblue;
    margin-bottom: -0.06375%; }
  /* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  to {
    -webkit-box-shadow: 0px 0px 4px #333;
    box-shadow: 0px 0px 4px #333;
    margin-bottom: -0.25499%; } }

@-o-keyframes levelStartedPulse {
  from {
    -webkit-box-shadow: 0px 0px 4px #333;
    box-shadow: 0px 0px 4px #333;
    margin-bottom: -0.25499%; }
  50% {
    -webkit-box-shadow: 0px 0px 22px skyblue;
    box-shadow: 0px 0px 22px skyblue;
    margin-bottom: -0.06375%; }
  to {
    -webkit-box-shadow: 0px 0px 4px #333;
    box-shadow: 0px 0px 4px #333;
    margin-bottom: -0.25499%; } }

@keyframes levelStartedPulse {
  from {
    -webkit-box-shadow: 0px 0px 4px #333;
    box-shadow: 0px 0px 4px #333;
    margin-bottom: -0.25499%; }
  50% {
    -webkit-box-shadow: 0px 0px 22px skyblue;
    box-shadow: 0px 0px 22px skyblue;
    margin-bottom: -0.06375%; }
  to {
    -webkit-box-shadow: 0px 0px 4px #333;
    box-shadow: 0px 0px 4px #333;
    margin-bottom: -0.25499%; } }

/* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
#campaign-view {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute; }
  /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .gradient {
    position: absolute;
    z-index: 0; }
    /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .gradient.horizontal-gradient {
      left: 0;
      right: 0;
      height: 3%; }
    /* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .gradient.vertical-gradient {
      top: 0;
      bottom: 0;
      width: 3%; }
    /* line 48, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .gradient.top-gradient {
      top: 0; }
    /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .gradient.right-gradient {
      right: 0; }
    /* line 54, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .gradient.bottom-gradient {
      bottom: 0; }
    /* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .gradient.left-gradient {
      left: 0; }
  /* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .map {
    position: relative; }
    /* line 63, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map #brain-pop-done-banner-wrapper {
      position: absolute;
      top: 20%;
      width: 100%;
      z-index: 3; }
      /* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map #brain-pop-done-banner-wrapper #brain-pop-done-banner {
        background: url("/images/pages/base/modal_background.png");
        background-size: 100% 100%;
        width: 400px;
        margin: 0 auto;
        padding: 20px 40px;
        text-align: center; }
        /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map #brain-pop-done-banner-wrapper #brain-pop-done-banner img {
          margin-bottom: 10px; }
    /* line 79, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map #cube-level-container {
      position: absolute;
      left: 30%;
      top: 15%;
      background-color: #5082c8; }
      /* line 85, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map #cube-level-container #spinning-cube {
        position: absolute;
        top: -32px;
        width: 32px;
        height: 32px;
        transform: translateX(-50%) scaleY(1.33);
        left: 50%;
        perspective: 800px;
        perspective-origin: 50% 100px;
        filter: drop-shadow(0px 0px 4px purple) drop-shadow(0px 0px 4px purple) drop-shadow(0px 0px 4px purple); }
        /* line 96, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map #cube-level-container #spinning-cube .cube {
          position: relative;
          width: 32px;
          transform-style: preserve-3d;
          animation: spin 5s infinite linear; }

@-webkit-keyframes spin {
  from {
    -moz-transform: rotateX(180deg) rotateY(0deg);
    -o-transform: rotateX(180deg) rotateY(0deg);
    -webkit-transform: rotateX(180deg) rotateY(0deg);
    transform: rotateX(180deg) rotateY(0deg); }
  to {
    -moz-transform: rotateX(540deg) rotateY(360deg);
    -o-transform: rotateX(540deg) rotateY(360deg);
    -webkit-transform: rotateX(540deg) rotateY(360deg);
    transform: rotateX(540deg) rotateY(360deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotateX(180deg) rotateY(0deg);
    -o-transform: rotateX(180deg) rotateY(0deg);
    -webkit-transform: rotateX(180deg) rotateY(0deg);
    transform: rotateX(180deg) rotateY(0deg); }
  to {
    -moz-transform: rotateX(540deg) rotateY(360deg);
    -o-transform: rotateX(540deg) rotateY(360deg);
    -webkit-transform: rotateX(540deg) rotateY(360deg);
    transform: rotateX(540deg) rotateY(360deg); } }

@-ms-keyframes spin {
  /* line 103, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .map #cube-level-container #spinning-cube .cube from {
    -moz-transform: rotateX(180deg) rotateY(0deg);
    -o-transform: rotateX(180deg) rotateY(0deg);
    -webkit-transform: rotateX(180deg) rotateY(0deg);
    transform: rotateX(180deg) rotateY(0deg); }
  /* line 108, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .map #cube-level-container #spinning-cube .cube to {
    -moz-transform: rotateX(540deg) rotateY(360deg);
    -o-transform: rotateX(540deg) rotateY(360deg);
    -webkit-transform: rotateX(540deg) rotateY(360deg);
    transform: rotateX(540deg) rotateY(360deg); } }

@-o-keyframes spin {
  from {
    -moz-transform: rotateX(180deg) rotateY(0deg);
    -o-transform: rotateX(180deg) rotateY(0deg);
    -webkit-transform: rotateX(180deg) rotateY(0deg);
    transform: rotateX(180deg) rotateY(0deg); }
  to {
    -moz-transform: rotateX(540deg) rotateY(360deg);
    -o-transform: rotateX(540deg) rotateY(360deg);
    -webkit-transform: rotateX(540deg) rotateY(360deg);
    transform: rotateX(540deg) rotateY(360deg); } }

@keyframes spin {
  from {
    -moz-transform: rotateX(180deg) rotateY(0deg);
    -o-transform: rotateX(180deg) rotateY(0deg);
    -webkit-transform: rotateX(180deg) rotateY(0deg);
    transform: rotateX(180deg) rotateY(0deg); }
  to {
    -moz-transform: rotateX(540deg) rotateY(360deg);
    -o-transform: rotateX(540deg) rotateY(360deg);
    -webkit-transform: rotateX(540deg) rotateY(360deg);
    transform: rotateX(540deg) rotateY(360deg); } }
          /* line 114, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .map #cube-level-container #spinning-cube .cube div {
            width: 32px;
            height: 32px;
            position: absolute;
            background-size: 100% 100%;
            color: white; }
          /* line 121, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .map #cube-level-container #spinning-cube .cube .front, #campaign-view .map #cube-level-container #spinning-cube .cube .back, #campaign-view .map #cube-level-container #spinning-cube .cube .left, #campaign-view .map #cube-level-container #spinning-cube .cube .right {
            background-image: url("/images/minecraft/grass_side.png"); }
          /* line 123, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .map #cube-level-container #spinning-cube .cube .top {
            background-image: url("/images/minecraft/grass_top.png"); }
          /* line 125, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .map #cube-level-container #spinning-cube .cube .bottom {
            background-image: url("/images/minecraft/dirt.png"); }
          /* line 127, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .map #cube-level-container #spinning-cube .cube .front {
            -moz-transform: translateY(-16px) rotateY(0deg) translateZ(-16px) rotateX(180deg);
            -o-transform: translateY(-16px) rotateY(0deg) translateZ(-16px) rotateX(180deg);
            -webkit-transform: translateY(-16px) rotateY(0deg) translateZ(-16px) rotateX(180deg);
            transform: translateY(-16px) rotateY(0deg) translateZ(-16px) rotateX(180deg); }
          /* line 132, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .map #cube-level-container #spinning-cube .cube .back {
            -moz-transform: translateY(-16px) rotateX(180deg) translateZ(-16px);
            -o-transform: translateY(-16px) rotateX(180deg) translateZ(-16px);
            -webkit-transform: translateY(-16px) rotateX(180deg) translateZ(-16px);
            transform: translateY(-16px) rotateX(180deg) translateZ(-16px); }
          /* line 137, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .map #cube-level-container #spinning-cube .cube .left {
            -moz-transform: translateY(-16px) rotateY(-89.9deg) translateZ(-16px) rotateX(180deg);
            -o-transform: translateY(-16px) rotateY(-89.9deg) translateZ(-16px) rotateX(180deg);
            -webkit-transform: translateY(-16px) rotateY(-89.9deg) translateZ(-16px) rotateX(180deg);
            transform: translateY(-16px) rotateY(-89.9deg) translateZ(-16px) rotateX(180deg); }
          /* line 142, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .map #cube-level-container #spinning-cube .cube .right {
            -moz-transform: translateY(-16px) rotateY(89.9deg) translateZ(-16px) rotateX(180deg);
            -o-transform: translateY(-16px) rotateY(89.9deg) translateZ(-16px) rotateX(180deg);
            -webkit-transform: translateY(-16px) rotateY(89.9deg) translateZ(-16px) rotateX(180deg);
            transform: translateY(-16px) rotateY(89.9deg) translateZ(-16px) rotateX(180deg); }
          /* line 147, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .map #cube-level-container #spinning-cube .cube .top {
            -moz-transform: translateY(-16px) rotateX(89.9deg) translateZ(-16px);
            -o-transform: translateY(-16px) rotateX(89.9deg) translateZ(-16px);
            -webkit-transform: translateY(-16px) rotateX(89.9deg) translateZ(-16px);
            transform: translateY(-16px) rotateX(89.9deg) translateZ(-16px); }
          /* line 152, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .map #cube-level-container #spinning-cube .cube .bottom {
            -moz-transform: translateY(-16px) rotateX(-89.9deg) translateZ(-16px);
            -o-transform: translateY(-16px) rotateX(-89.9deg) translateZ(-16px);
            -webkit-transform: translateY(-16px) rotateX(-89.9deg) translateZ(-16px);
            transform: translateY(-16px) rotateX(-89.9deg) translateZ(-16px); }
    /* line 159, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map .map-background {
      width: 100%;
      height: 100%;
      background-size: 100%;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none; }
    /* line 165, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map .level, #campaign-view .map .level-shadow {
      position: absolute;
      border-radius: 50%;
      -webkit-transform: scaleY(0.75);
      transform: scaleY(0.75); }
    /* line 171, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map .level {
      z-index: 2;
      width: 2%;
      height: 3.0599%;
      margin-left: -1%;
      margin-bottom: -0.25499%;
      border: 2px groove white;
      -webkit-transition: margin-bottom 0.5s ease;
      transition: margin-bottom 0.5s ease;
      cursor: pointer; }
      /* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level .treasure-chest {
        position: absolute;
        left: 50%;
        width: 150%;
        transform: translateX(-50%) translateX(50%) translateY(-130%) scaleY(1.33) scale(0.75);
        cursor: pointer;
        z-index: -1;
        filter: drop-shadow(0px 0px 4px gold); }
      /* line 190, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level.disabled, #campaign-view .map .level.locked {
        opacity: 0.7;
        width: 1.34%;
        height: 2.05013%;
        margin-left: -0.67%;
        margin-bottom: -0.30089%; }
        /* line 201, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map .level.disabled .treasure-chest, #campaign-view .map .level.locked .treasure-chest {
          transform: translateX(-50%) translateY(-200%) scaleY(1.33) scale(0.95); }
      /* line 205, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level.started, #campaign-view .map .level.next {
        border: 3px solid lightgreen;
        -webkit-box-shadow: 0px 0px 35px skyblue;
        box-shadow: 0px 0px 35px skyblue; }
      /* line 215, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level.complete {
        border: 3px solid gold;
        -webkit-box-shadow: 0px 0px 35px skyblue;
        box-shadow: 0px 0px 35px skyblue; }
      /* line 219, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level .level-difficulty-banner-text {
        position: absolute;
        bottom: 160%;
        pointer-events: none;
        color: #f6d002;
        text-shadow: 0px 1px 0px black;
        font-size: 1.8vw;
        z-index: 1;
        width: 100%;
        text-align: center; }
      /* line 230, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level img.banner {
        position: absolute;
        bottom: 38%;
        width: 170%;
        left: -35%; }
      /* line 236, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level img.star {
        width: 100%;
        bottom: 4%;
        position: absolute;
        pointer-events: none; }
      /* line 242, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level .level-acronym, #campaign-view .map .level .level-kind {
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        color: black;
        text-align: center;
        font-size: 0.8vw;
        text-shadow: 0px 1px 0px white; }
      /* line 254, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level .level-kind {
        bottom: auto;
        top: 100%;
        color: white;
        text-shadow: 0px 1px 0px black, 0px -1px 0px black, -1px 0px 0px black, 1px 0px 0px black; }
        /* line 260, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map .level .level-kind.demo {
          color: #80ff80; }
        /* line 262, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map .level .level-kind.usage {
          color: #8080ff; }
        /* line 264, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map .level .level-kind.mastery {
          color: #ff8080; }
        /* line 266, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map .level .level-kind.practice {
          color: #8080ff; }
        /* line 268, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map .level .level-kind.advanced {
          color: #80ffff; }
        /* line 270, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map .level .level-kind.challenge {
          color: #ffff80; }
      /* line 273, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level img.hero-portrait, #campaign-view .map .level img.item-portrait {
        width: 120%;
        height: auto;
        bottom: 75%;
        left: 75%;
        margin-left: 0;
        margin-bottom: 0; }
    /* line 281, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map img.premium-hero-banner {
      position: absolute;
      height: 250%;
      transform: translate(-50%, -100%) translateX(-20%) translateY(-10%) scaleY(1.33);
      filter: drop-shadow(0px 0px 10px black);
      z-index: 3; }
    /* line 288, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map img.pet-banner {
      position: absolute;
      height: 250%;
      transform: translate(-50%, -80%) translateX(-20%) translateY(-10%) scaleY(1.33) scale(0.625);
      filter: drop-shadow(0px 0px 10px black);
      z-index: 3; }
    /* line 295, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map img.hero-portrait, #campaign-view .map img.item-portrait {
      position: absolute;
      border: 1px solid black;
      border-radius: 50%;
      background: white;
      width: 3%;
      height: 2.62277%;
      margin-left: -1.5%;
      margin-bottom: -0.87426%; }
      /* line 305, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map img.hero-portrait.hero-portrait-on-banner, #campaign-view .map img.hero-portrait.item-portrait-on-banner, #campaign-view .map img.item-portrait.hero-portrait-on-banner, #campaign-view .map img.item-portrait.item-portrait-on-banner {
        bottom: 170%;
        left: 0%;
        width: 100%; }
      /* line 310, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map img.hero-portrait.item-portrait-on-banner, #campaign-view .map img.item-portrait.item-portrait-on-banner {
        background: transparent;
        border: 0;
        border-radius: 0; }
    /* line 315, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map .level-shadow {
      z-index: 1;
      width: 1.6%;
      height: 2.44792%;
      margin-left: -0.8%;
      margin-bottom: -0.81597%;
      background-color: black;
      -webkit-box-shadow: 0px 0px 10px black;
      box-shadow: 0px 0px 10px black;
      opacity: 0.75;
      filter: alpha(opacity=75); }
      /* line 325, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-shadow.locked {
        opacity: 0.5;
        filter: alpha(opacity=50);
        width: 1.072%;
        height: 1.6401%;
        margin-left: -0.536%;
        margin-bottom: -0.5467%; }
    /* line 332, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map .level:hover {
      -moz-transform: rotate(0);
      margin-bottom: 0.1275%;
      -webkit-box-shadow: 0px 0px 35px skyblue;
      box-shadow: 0px 0px 35px skyblue; }
    /* line 342, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map .level a {
      display: block;
      padding: 20px;
      margin-left: -10px;
      margin-top: -10px;
      border-radius: 20px; }
    /* line 349, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map .tooltip {
      z-index: 3;
      pointer-events: none; }
      /* line 353, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .tooltip .tooltip-arrow {
        display: none; }
    /* line 356, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map .level-info-container {
      display: none;
      position: absolute;
      z-index: 3;
      width: 362px;
      padding: 17px 20px 20px 20px;
      border: 0;
      background: transparent url(/images/pages/play/level-info-background.png) no-repeat center center;
      background-size: 100% 100%; }
      /* line 369, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info h3 {
        color: #e8d957;
        text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0;
        padding: 0 2px;
        margin: 0 0 10px 31px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      /* line 378, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info .level-description {
        color: black; }
        /* line 381, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map .level-info-container .level-info .level-description img {
          display: block;
          margin: 0px auto;
          max-width: 100%; }
      /* line 386, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info kbd {
        margin: 0 2px 2px 0;
        display: inline-block;
        font-size: 12px; }
      /* line 391, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info .level-status {
        background: transparent url(/images/pages/play/level-info-status-spritesheet.png) no-repeat 0 0;
        width: 60px;
        height: 60px;
        position: absolute;
        left: -15px;
        top: -15px; }
      /* line 399, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info.complete .level-status {
        background-position: -60px 0; }
      /* line 402, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info.premium .level-status {
        background-position: -120px 0; }
      /* line 405, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info.complete.premium .level-status {
        background-position: -180px 0; }
      /* line 408, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info .start-level {
        min-width: 200px;
        display: block;
        margin: 10px auto 0 auto;
        position: relative; }
        /* line 414, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .map .level-info-container .level-info .start-level .badge {
          position: absolute;
          top: auto;
          left: auto;
          right: -25px;
          bottom: -25px;
          font-size: 20px;
          color: black;
          border: 1px solid black;
          background-color: #e8d957;
          border-radius: 50%;
          opacity: 1;
          padding: 3px 9px; }
      /* line 429, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info.shows-leaderboard .start-level, #campaign-view .map .level-info-container .level-info.shows-leaderboard .view-solutions {
        min-width: calc(50% - 5px);
        display: inline-block;
        width: calc(50% - 5px); }
      /* line 434, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info.shows-leaderboard .start-level {
        margin: 10px 0 0 5px; }
      /* line 437, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info.shows-leaderboard .view-solutions {
        margin: 10px 5px 0 0; }
      /* line 440, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .level-info-container .level-info .course-version {
        text-align: center; }
    /* line 443, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .map .campaign-switch {
      color: purple;
      position: absolute;
      z-index: 1;
      font-size: 2vw;
      text-shadow: 0 0 0.3vw white, 0 0 0.3vw white; }
      /* line 450, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .map .campaign-switch:hover {
        text-decoration: none; }
  /* line 453, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .next-level-line {
    height: 8px;
    position: absolute; }
    /* line 457, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .next-level-line .line {
      width: calc(100% - 24px);
      float: left;
      margin-top: 2px;
      margin-bottom: 2px;
      height: 4px;
      background: repeating-linear-gradient(-45deg, #AF9F7D, #DFC89C 5px, #F1EAC0 5px, #AF9F7D 10px);
      box-shadow: 0px 0px 4px black; }
    /* line 466, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .next-level-line .point {
      width: 24px;
      height: 24px;
      float: right;
      margin-top: -8px;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 24px solid #faf8ea; }
  /* line 475, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .game-controls {
    position: absolute;
    right: 1%;
    bottom: 1%;
    z-index: 2; }
    /* line 481, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .game-controls .btn {
      width: 80px;
      height: 80px;
      background: url(/images/pages/play/menu_icons.png) no-repeat;
      position: relative;
      background-size: cover;
      -webkit-transition: 0.5s ease;
      transition: 0.5s ease;
      -webkit-box-shadow: 2px 2px 4px black;
      box-shadow: 2px 2px 4px black;
      border: 0;
      border-radius: 12px;
      filter: none; }
      /* line 482, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn:not(:first-child) {
        margin-left: 30px; }
        @media only screen and (max-height: 650px) {
          /* line 482, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .game-controls .btn:not(:first-child) {
            margin-left: 0; } }
      @media only screen and (max-height: 650px) {
        /* line 481, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .game-controls .btn {
          -webkit-transform: scale(0.67);
          -ms-transform: scale(0.67);
          transform: scale(0.67); } }
      /* line 496, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
      /* line 511, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn:hover {
        -webkit-box-shadow: 0 0 12px #bbf;
        box-shadow: 0 0 12px #bbf; }
      /* line 514, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn:active, #campaign-view .game-controls .btn.highlighted {
        -webkit-box-shadow: 0 0 20px white;
        box-shadow: 0 0 20px white; }
      /* line 517, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn.items {
        background-position: -80px 0px; }
      /* line 519, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn.heroes {
        background-position: -160px 0px; }
      /* line 521, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn.achievements {
        background-position: -240px 0px; }
      /* line 523, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn.account {
        background-position: -400px 0px; }
      /* line 525, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn.settings {
        background-position: -400px 0px; }
      /* line 527, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn.gems {
        background-position: -480px 0px; }
      /* line 529, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn.poll {
        background-position: -320px 0px; }
      /* line 531, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .btn.premium-menu-icon {
        background: url(/images/pages/play/premium-menu-icon.png) no-repeat;
        background-position: 50% 50%;
        background-size: 100%;
        transform: scale(1.31) translateY(-5px) translateX(-6px);
        box-shadow: initial;
        filter: drop-shadow(2px 2px 4px black);
        outline: none; }
        @media only screen and (max-height: 650px) {
          /* line 531, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .game-controls .btn.premium-menu-icon {
            transform: scale(0.67) scale(1.31) translateY(-5px) translateX(-6px); } }
        /* line 541, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .game-controls .btn.premium-menu-icon:hover {
          filter: drop-shadow(0 0 2px #bbf); }
    /* line 545, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .game-controls .tooltip {
      font-size: 24px; }
      /* line 548, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .game-controls .tooltip .tooltip-arrow {
        display: none; }
  /* line 551, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .user-status {
    position: absolute;
    bottom: 16px;
    left: 8px;
    text-align: center;
    font-size: 24px;
    color: white;
    text-shadow: 0px 2px 1px black, 0px -2px 1px black, -2px 0px 1px black, 2px 0px 1px black;
    height: 32px;
    line-height: 32px; }
    /* line 562, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .user-status .user-status-line {
      position: relative; }
      /* line 565, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .user-status .user-status-line button.btn.btn-illustrated {
        margin-left: 10px;
        min-width: 90px;
        height: 32px;
        color: white; }
      /* line 571, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .user-status .user-status-line .gem, #campaign-view .user-status .user-status-line .player-hero-icon {
        position: absolute;
        top: 1px; }
      /* line 575, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .user-status .user-status-line #gems-count {
        margin-left: 40px; }
      /* line 578, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .user-status .user-status-line .player-level {
        margin-left: 5px; }
      /* line 581, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .user-status .user-status-line .player-name {
        margin-left: 45px; }
      /* line 584, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .user-status .user-status-line a {
        color: white; }
      /* line 589, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .user-status .user-status-line .player-hero-icon {
        background: transparent url(/images/pages/play/play-spritesheet.png);
        background-size: cover;
        background-position: -60px 0;
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 0px 2px; }
      /* line 598, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .user-status .user-status-line .level-indicator {
        margin-left: 15px;
        color: white;
        display: inline-block;
        margin: 0 2px; }
      /* line 604, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .user-status .user-status-line .player-hero-icon {
        margin-left: 10px;
        background-position: -120px 0; }
        /* line 608, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .user-status .user-status-line .player-hero-icon.knight {
          background-position: -150px 0; }
        /* line 610, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .user-status .user-status-line .player-hero-icon.librarian {
          background-position: -180px 0; }
        /* line 612, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .user-status .user-status-line .player-hero-icon.ninja {
          background-position: -210px 0; }
        /* line 614, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .user-status .user-status-line .player-hero-icon.potion-master {
          background-position: -240px 0; }
        /* line 616, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .user-status .user-status-line .player-hero-icon.samurai {
          background-position: -270px 0; }
        /* line 618, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .user-status .user-status-line .player-hero-icon.trapper {
          background-position: -300px 0; }
        /* line 620, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .user-status .user-status-line .player-hero-icon.forest-archer {
          background-position: -330px 0; }
        /* line 622, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .user-status .user-status-line .player-hero-icon.sorcerer {
          background-position: -360px 0; }
  /* line 625, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .campaign-control-button {
    position: absolute;
    right: 1%;
    top: 1%;
    padding: 3px 8px;
    opacity: 0.75;
    filter: alpha(opacity=75); }
    /* line 632, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .campaign-control-button:hover {
      opacity: 1;
      filter: alpha(opacity=100); }
    /* line 635, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .campaign-control-button .glyphicon {
      font-size: 32px; }
  /* line 639, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view #volume-button .glyphicon {
    display: none; }
  /* line 642, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view #volume-button.vol-up .glyphicon.glyphicon-volume-up {
    display: inline-block; }
  /* line 645, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view #volume-button.vol-off .glyphicon.glyphicon-volume-off {
    display: inline-block;
    opacity: 0.5;
    filter: alpha(opacity=50); }
    /* line 648, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view #volume-button.vol-off .glyphicon.glyphicon-volume-off:hover {
      opacity: 0.75;
      filter: alpha(opacity=75); }
  /* line 651, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view #volume-button.vol-down .glyphicon.glyphicon-volume-down {
    display: inline-block; }
  /* line 654, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view #back-button, #campaign-view #clear-storage-button {
    position: absolute;
    right: 70px;
    right: -webkit-calc(1% + 115px);
    right: calc(1% + 115px); }
  /* line 659, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view #fullscreen-button {
    position: absolute;
    right: 70px;
    right: -webkit-calc(1% + 55px);
    right: calc(1% + 55px); }
  /* line 665, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view #campaign-status {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    color: #e8d957;
    font-size: 28px;
    text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0;
    z-index: 30;
    pointer-events: none; }
    /* line 678, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view #campaign-status .campaign-status-background {
      background: transparent url(/images/pages/play/campaign-banner.png) no-repeat center center;
      border-radius: 10px;
      padding-top: 30px;
      display: inline-block;
      min-width: 250px;
      height: 106px; }
      /* line 686, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view #campaign-status .campaign-status-background .campaign-name {
        line-height: 26px; }
      /* line 689, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view #campaign-status .campaign-status-background .levels-completed {
        font-size: 22px; }
  /* line 692, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .particle-man {
    z-index: 2; }
  /* line 695, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .portal {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent url(/images/pages/play/portal-background.png);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; }
    /* line 705, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .portal .portals {
      height: 760.8px;
      flex-wrap: nowrap;
      display: flex;
      overflow: hidden; }
      /* line 714, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .portal .portals .campaign {
        width: 317px;
        height: 634px;
        margin-top: 63.4px;
        background: transparent url(/images/pages/play/portal-campaigns.png) no-repeat 0 0;
        display: inline-block;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
        -webkit-transition: 0.25s cubic-bezier(0.11, 0.67, 0.8, 1.42);
        transition: 0.25s cubic-bezier(0.11, 0.67, 0.8, 1.42); }
        /* line 726, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .portal .portals .campaign:hover {
          -webkit-transform: scale(1.2);
          -ms-transform: scale(1.2);
          transform: scale(1.2); }
        /* line 729, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .portal .portals .campaign.silhouette {
          -webkit-filter: contrast(50%) brightness(65%);
          -moz-filter: contrast(50%) brightness(65%);
          -o-filter: contrast(50%) brightness(65%);
          filter: contrast(50%) brightness(65%);
          pointer-events: none; }
        /* line 733, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .portal .portals .campaign.locked {
          -webkit-filter: contrast(80%) brightness(80%);
          -moz-filter: contrast(80%) brightness(80%);
          -o-filter: contrast(80%) brightness(80%);
          filter: contrast(80%) brightness(80%);
          pointer-events: none; }
        /* line 737, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .portal .portals .campaign.forest {
          background-position: -317px 0; }
        /* line 739, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .portal .portals .campaign.desert {
          background-position: -634px 0; }
        /* line 741, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .portal .portals .campaign.mountain {
          background-position: -951px 0; }
        /* line 743, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .portal .portals .campaign.glacier {
          background-position: -1268px 0; }
        /* line 745, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .portal .portals .campaign.volcano {
          background-position: -1585px 0; }
        /* line 748, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .portal .portals .campaign .campaign-label {
          position: absolute;
          top: 45%;
          width: 100%;
          text-align: center; }
          /* line 754, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .portal .portals .campaign .campaign-label .campaign-name, #campaign-view .portal .portals .campaign .campaign-label .levels-completed, #campaign-view .portal .portals .campaign .campaign-label .campaign-locked {
            margin: 0;
            color: white;
            text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0; }
          /* line 759, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .portal .portals .campaign .campaign-label .campaign-locked {
            margin: 32px 0; }
          /* line 762, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .portal .portals .campaign .campaign-label .campaign-description {
            margin: 0px 40px;
            background: transparent url(/images/level/popover_border_background.png) no-repeat;
            background-size: 100% 100%;
            padding: 12px;
            color: black; }
          /* line 769, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .portal .portals .campaign .campaign-label .levels-completed {
            font-size: 22px; }
          /* line 772, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .portal .portals .campaign .campaign-label .play-button {
            margin: 15px 0;
            min-width: 100px; }
      /* line 776, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
      #campaign-view .portal .portals .beta-container {
        width: 317px;
        height: 634px;
        display: inline-block;
        flex-shrink: 0;
        position: relative; }
        /* line 784, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
        #campaign-view .portal .portals .beta-container .beta-campaign {
          width: 317px;
          height: 317px;
          display: inline-block;
          flex-shrink: 0;
          position: relative;
          cursor: pointer;
          -webkit-transition: 0.25s cubic-bezier(0.11, 0.67, 0.8, 1.42);
          transition: 0.25s cubic-bezier(0.11, 0.67, 0.8, 1.42); }
          /* line 794, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .portal .portals .beta-container .beta-campaign:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2); }
          /* line 797, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .portal .portals .beta-container .beta-campaign.silhouette {
            -webkit-filter: contrast(50%) brightness(65%);
            -moz-filter: contrast(50%) brightness(65%);
            -o-filter: contrast(50%) brightness(65%);
            filter: contrast(50%) brightness(65%);
            pointer-events: none; }
          /* line 801, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .portal .portals .beta-container .beta-campaign.locked {
            -webkit-filter: contrast(80%) brightness(80%);
            -moz-filter: contrast(80%) brightness(80%);
            -o-filter: contrast(80%) brightness(80%);
            filter: contrast(80%) brightness(80%);
            pointer-events: none; }
          /* line 805, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .portal .portals .beta-container .beta-campaign .campaign-label {
            position: absolute;
            top: 40%;
            width: 100%;
            text-align: center; }
            /* line 811, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
            #campaign-view .portal .portals .beta-container .beta-campaign .campaign-label .campaign-name, #campaign-view .portal .portals .beta-container .beta-campaign .campaign-label .levels-completed, #campaign-view .portal .portals .beta-container .beta-campaign .campaign-label .campaign-locked {
              margin: 0;
              color: white;
              text-shadow: black 2px 2px 0, black -2px -2px 0, black 2px -2px 0, black -2px 2px 0, black 2px 0px 0, black 0px -2px 0, black -2px 0px 0, black 0px 2px 0; }
            /* line 816, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
            #campaign-view .portal .portals .beta-container .beta-campaign .campaign-label .campaign-locked {
              margin: 32px 0; }
            /* line 819, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
            #campaign-view .portal .portals .beta-container .beta-campaign .campaign-label .campaign-description {
              margin: 0px 40px;
              background: transparent url(/images/level/popover_border_background.png) no-repeat;
              background-size: 100% 100%;
              padding: 12px;
              color: black;
              font-size: 12px; }
            /* line 827, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
            #campaign-view .portal .portals .beta-container .beta-campaign .campaign-label .levels-completed {
              font-size: 22px; }
            /* line 830, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
            #campaign-view .portal .portals .beta-container .beta-campaign .campaign-label .play-button {
              margin: 10px 0;
              min-width: 100px;
              color: white; }
          /* line 835, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
          #campaign-view .portal .portals .beta-container .beta-campaign .background-container {
            position: absolute;
            left: 79.25px;
            width: 158.5px;
            height: 317px;
            background: transparent url(/images/pages/play/portal-beta-campaigns.png) no-repeat 0 0;
            background-size: 951px; }
            /* line 843, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
            #campaign-view .portal .portals .beta-container .beta-campaign .background-container.campaign-web-dev-1 {
              background-position: -151px 0px; }
            /* line 845, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
            #campaign-view .portal .portals .beta-container .beta-campaign .background-container.campaign-game-dev-1 {
              background-position: -454px 0px; }
            /* line 847, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
            #campaign-view .portal .portals .beta-container .beta-campaign .background-container.campaign-web-dev-2 {
              background-position: -291px 0px; }
            /* line 849, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
            #campaign-view .portal .portals .beta-container .beta-campaign .background-container.campaign-game-dev-2 {
              background-position: -628px 0px; }
  /* line 853, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .small-nav-logo, #campaign-view .picoctf-powered-by {
    position: absolute;
    top: 1%;
    left: 1%;
    height: 60px;
    z-index: 1; }
  /* line 860, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .picoctf-logo .small-nav-logo {
    height: 90px; }
  /* line 863, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .under-logo {
    position: absolute;
    top: calc(1% + 64px);
    left: 1%;
    z-index: 1;
    width: 193px; }
  /* line 870, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .picoctf-powered-by {
    color: #e3ad35;
    top: calc(1% + 90px);
    margin: 10px 10px 10px 15px; }
    /* line 875, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
    #campaign-view .picoctf-powered-by img {
      height: 30px; }
  /* line 878, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .code-ninjas-logo {
    top: calc(1% + 5px);
    left: calc(1% + 200px); }
  /* line 882, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .codeplay-logo {
    top: calc(1% + 5px);
    left: calc(1% + 5px);
    height: 100px; }
  /* line 887, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .ad-container {
    width: 100%;
    height: 90px;
    text-align: center; }
  /* line 892, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view .gameplay-container {
    position: absolute;
    height: 100%;
    width: 100%; }
  /* line 897, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
  #campaign-view #codeplay-ad {
    position: absolute;
    right: 10px;
    top: 60px;
    height: calc(100% - 160px);
    z-index: 2; }

/* line 906, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
body.ipad #campaign-view .campaign-switch {
  display: none; }

/* line 909, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/campaign-view.sass */
body[lang='ru'] .portals h2 {
  font-size: 26px; }
