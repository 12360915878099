/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal #noob-view p {
  font-size: 30px; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal #skip-tutorial-button {
  font-size: 16px; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .tutorial-suggestion {
  text-align: center;
  font-size: 18px;
  margin: 10px 0 30px; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .play-option {
  margin-bottom: 15px;
  width: 100%;
  height: 100px;
  overflow: hidden;
  background: white;
  border-radius: 8px;
  position: relative;
  -webkit-transition: opacity 0.3s ease-in-out, margin 0.1s linear, box-shadow 0.1s linear;
  transition: opacity 0.3s ease-in-out, margin 0.1s linear, box-shadow 0.1s linear;
  box-shadow: 2px 2px 4px black; }
  /* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
  #ladder-play-modal .play-option .only-one {
    opacity: 0; }

/* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal #normal-view:hover .play-option {
  opacity: 0.4; }

/* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal #normal-view:hover .play-option:hover {
  opacity: 1;
  box-shadow: 4px 4px 6px black;
  margin-left: -2px;
  margin-top: -2px;
  margin-bottom: 17px; }
  /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
  #ladder-play-modal #normal-view:hover .play-option:hover .only-one {
    opacity: 1; }

/* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .my-icon {
  position: relative;
  left: 0;
  top: -10px;
  z-index: 1; }

/* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .my-team-icon {
  height: 60px;
  position: relative;
  top: -3.5px;
  left: 13.5px;
  z-index: 0; }

/* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .opponent-team-icon {
  height: 60px;
  position: relative;
  top: 16.5px;
  right: 13.5px;
  z-index: 0;
  float: right;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

/* line 71, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .opponent-icon {
  position: relative;
  float: right;
  right: 0;
  top: -10px;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  z-index: 1; }

/* line 84, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .name-label {
  height: 20px;
  width: 40%;
  position: absolute;
  bottom: 0;
  color: white;
  font-weight: bold;
  text-shadow: -1px -1px 0px black;
  text-align: center;
  z-index: 2; }
  /* line 95, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
  #ladder-play-modal .name-label span {
    position: relative;
    top: 4px; }
  /* line 99, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
  #ladder-play-modal .name-label .code-language {
    position: absolute;
    background: transparent url(/images/common/code_languages/javascript_small.png) no-repeat center center;
    background-size: contain;
    width: 50px;
    height: 50px;
    right: -0px;
    top: -30px;
    display: block; }

/* line 109, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .my-name {
  border-right: 15px solid transparent;
  left: 0; }
  /* line 112, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
  #ladder-play-modal .my-name span {
    left: 3px; }

/* line 115, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .opponent-name {
  border-left: 15px solid transparent;
  right: 0; }
  /* line 119, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
  #ladder-play-modal .opponent-name span {
    right: 3px; }

/* line 122, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .difficulty {
  height: 0;
  width: 30%;
  position: absolute;
  left: 35%;
  top: 0;
  color: black;
  text-align: center;
  font-size: 18px;
  font-weight: bold; }
  /* line 133, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
  #ladder-play-modal .difficulty span {
    position: relative;
    top: 6px; }

/* line 137, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .play-option {
  background-image: url(/images/pages/play/ladder/warmup_button.png); }

/* line 140, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .easy-option {
  background-image: url(/images/pages/play/ladder/easy_button.png); }

/* line 143, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .medium-option {
  background-image: url(/images/pages/play/ladder/medium_button.png); }

/* line 146, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .hard-option {
  background-image: url(/images/pages/play/ladder/hard_button.png); }
  /* line 148, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
  #ladder-play-modal .hard-option .difficulty {
    color: white; }

/* line 151, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/ladder/play_modal.sass */
#ladder-play-modal .vs {
  position: absolute;
  left: 40%;
  right: 40%;
  text-align: center;
  top: 35px;
  font-size: 40px;
  font-weight: bolder;
  color: black; }
