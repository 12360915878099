/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_debug.sass */
.spell-debug-view {
  position: absolute;
  z-index: 9001;
  min-width: 250px;
  max-width: 400px;
  padding: 10px;
  border-style: solid;
  border-image: url(/images/level/popover_border_background.png) 16 12 fill round;
  border-width: 8px 6px; }
  /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_debug.sass */
  .spell-debug-view .progress {
    position: relative; }
    /* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_debug.sass */
    .spell-debug-view .progress span {
      position: absolute;
      display: block;
      color: black;
      width: 100%; }

/* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_debug.sass */
html.no-borderimage .spell-debug-view {
  background: transparent url(/images/level/popover_background.png);
  background-size: 100% 100%;
  border: 0; }
