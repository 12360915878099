/* line 1, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin.sass */
#admin-view {
  color: black; }
  /* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin.sass */
  #admin-view #free-sub-input {
    min-width: 50%; }
  /* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin.sass */
  #admin-view .search-help {
    position: relative;
    display: none;
    width: 500px;
    border: 1px solid black;
    background-color: white;
    padding: 5px; }
  /* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin.sass */
  #admin-view #user-search-result .avatar {
    max-width: 18px;
    max-height: 18px; }
