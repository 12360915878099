/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view h1 {
  margin-bottom: 30px; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view #main-content {
  max-width: 720px;
  margin: 0 auto; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view #play-now-to-learn-header {
  margin-top: 60px; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view #benefits-ul {
  margin: 0 auto 40px;
  width: 360px; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view hr {
  border-top: 1px solid grey;
  margin: 5px 0;
  padding-bottom: 20px;
  opacity: 0.5; }

/* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view .view-levels-btn {
  font-size: 13px;
  padding-left: 10px; }

/* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view .view-project-gallery-link {
  font-size: 13px;
  padding-left: 10px;
  float: right; }

/* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view .text-uppercase {
  margin-top: 40px; }

/* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view #just-added-text {
  color: #009999; }

/* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view .just-added {
  border: 1px solid #009999;
  margin: 0 -20px;
  padding: 0 20px; }

/* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view .course-instance-entry {
  padding-bottom: 10px; }
  /* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
  #courses-view .course-instance-entry .progress-bar {
    min-width: 15%; }
  /* line 48, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
  #courses-view .course-instance-entry .btn {
    margin-left: 20px;
    min-width: 180px; }

/* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view #join-class-form .alert, #courses-view #join-class-form .progress {
  margin-top: 20px; }

/* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
#courses-view .current-hero-container {
  display: flex;
  justify-content: center; }
  /* line 61, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
  #courses-view .current-hero-container .current-hero-text {
    font-size: 16pt; }
  /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
  #courses-view .current-hero-container .hero-avatar {
    background-color: #f8f8f8;
    box-shadow: 0 0 0 1px gray;
    margin-right: 25px; }
  /* line 69, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/courses-view.sass */
  #courses-view .current-hero-container .current-hero-right-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start; }
