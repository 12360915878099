@charset "UTF-8";
/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .modal-dialog {
  margin: 60px auto 0 auto;
  padding: 0;
  width: 746px;
  height: 520px;
  background: none; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal #subscribe-background {
  position: absolute;
  top: -61px;
  left: 0px; }

/* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal h1 {
  position: absolute;
  left: 170px;
  top: 25px;
  margin: 0;
  width: 410px;
  text-align: center;
  color: #febc44;
  font-size: 38px;
  text-shadow: black 4px 4px 0, black -4px -4px 0, black 4px -4px 0, black -4px 4px 0, black 4px 0px 0, black 0px -4px 0, black -4px 0px 0, black 0px 4px 0, black 6px 6px 6px;
  font-variant: normal;
  text-transform: uppercase; }

/* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal #close-modal {
  position: absolute;
  left: 568px;
  top: 17px;
  width: 60px;
  height: 60px;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 15px;
  cursor: pointer;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg); }
  /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal #close-modal:hover {
    color: yellow; }

/* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .popover {
  z-index: 1050;
  min-width: 400px; }
  /* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .popover h3 {
    background: transparent;
    border: 0;
    font-size: 30px;
    color: black; }

/* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .subscribe-image {
  position: absolute;
  top: 114px;
  right: 65px; }

/* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .paper-area {
  position: absolute;
  top: 114px;
  left: 31px;
  min-height: 370px;
  width: 684px;
  background: #d4c9b1;
  border: 3px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  /* line 87, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area .benefits-header, #subscribe-modal .paper-area .big-text {
    font-weight: bold;
    font-size: 20px;
    margin: 10px 0; }
  /* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area ul, #subscribe-modal .paper-area .med-text {
    font-size: 16px;
    font-weight: bold; }
  /* line 96, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area table {
    width: 100%;
    margin: 20px 0;
    text-align: center; }
  /* line 101, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area ul {
    list-style: none;
    padding: 0; }
    /* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
    #subscribe-modal .paper-area ul li::before {
      content: "• "; }
  /* line 108, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area hr {
    border-top: 1px solid gray;
    margin: 20px 40px; }
  /* line 112, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area th {
    font-size: 36px;
    text-align: center;
    line-height: 36px;
    color: #59882f;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); }
  /* line 119, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area .option-col {
    text-align: center; }
  /* line 122, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area .option-header {
    font-weight: bold;
    font-size: 16px; }
  /* line 126, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area .price {
    font-weight: bold;
    font-size: 24px; }
  /* line 130, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area .old-price {
    text-decoration: line-through;
    font-size: 20px;
    color: red;
    margin-left: -85px;
    margin-right: 10px; }
  /* line 137, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area p {
    margin: 5px 0 0 20px; }
  /* line 140, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area button {
    min-width: 250px; }
  /* line 143, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .paper-area .row {
    margin-bottom: 17px; }

/* line 149, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .comparison-blurb, #subscribe-modal .premium-pricing {
  position: absolute;
  left: 8.5%;
  top: 135px;
  width: 620px;
  background: transparent;
  font-weight: normal;
  line-height: 18px;
  color: black;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  text-align: center; }

/* line 162, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .premium-pricing {
  top: 355px; }

/* line 165, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .comparison-table {
  position: absolute;
  left: 8.5%;
  top: 160px;
  width: 620px;
  background: transparent;
  border-width: 0px; }
  /* line 172, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .comparison-table .free-cell {
    border-right-width: 1px;
    min-width: 40px; }
  /* line 177, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .comparison-table thead tr th {
    font-size: 24px;
    font-variant: small-caps;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    line-height: 1.1;
    color: #317EAC;
    padding: 4px;
    border-width: 0px;
    border-color: rgba(85, 85, 85, 0.1);
    text-align: right; }
  /* line 188, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .comparison-table tbody {
    font-size: 14px; }
    /* line 190, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
    #subscribe-modal .comparison-table tbody .center-ok {
      text-align: center; }
    /* line 193, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
    #subscribe-modal .comparison-table tbody tr td {
      padding: 2px;
      border-width: 0px;
      border-top-width: 1px;
      border-color: rgba(85, 85, 85, 0.1); }

/* line 201, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal #parents-info {
  position: absolute;
  left: 38px;
  top: 389px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  line-height: 18px;
  color: black;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px; }

/* line 213, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .popover-title {
  line-height: 26px; }

/* line 218, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal #payment-methods-info {
  position: absolute;
  right: 38px;
  top: 389px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  line-height: 18px;
  color: black;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px; }

/* line 232, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .subscribe-actions {
  position: absolute;
  top: 430px;
  left: 18px;
  right: 18px;
  display: flex; }
  /* line 239, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .subscribe-actions > button {
    height: 70px;
    margin: 0 5px; }

/* line 245, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .purchase-button {
  flex-grow: 1;
  font-size: 24px;
  line-height: 30px;
  border-style: solid;
  border-image: url(/images/common/button-background-primary-active.png) 14 20 20 20 fill round;
  border-width: 14px 20px 20px 20px;
  color: #f2f2f2; }
  /* line 254, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .purchase-button span {
    pointer-events: none; }
  /* line 257, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .purchase-button:hover {
    border-image: url(/images/common/button-background-primary-disabled.png) 14 20 20 20 fill round; }
  /* line 260, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .purchase-button:active {
    border-image: url(/images/common/button-background-primary-pressed.png) 14 20 20 20 fill round;
    padding: 2px 0 0 2px;
    color: white; }

/* line 267, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .lifetime-button {
  font-size: 24px;
  line-height: 30px;
  border-style: solid;
  border-image: url(/images/common/button-background-success-active.png) 14 20 20 20 fill round;
  border-width: 14px 20px 20px 20px;
  color: #f2f2f2; }
  /* line 272, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .lifetime-button:hover {
    border-image: url(/images/common/button-background-success-inactive.png) 14 20 20 20 fill round; }
  /* line 274, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .lifetime-button:active {
    border-image: url(/images/common/button-background-success-pressed.png) 14 20 20 20 fill round; }
  /* line 279, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
  #subscribe-modal .lifetime-button span {
    pointer-events: none; }

/* line 284, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .payment-selection-screen .big-text:first-child {
  color: black; }

/* line 287, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .payment-selection-screen #paypal-button-container {
  height: 45px;
  width: 276px;
  display: block;
  margin: 0 auto 10px auto; }

/* line 295, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .email-parent-form .email_invalid {
  color: red;
  display: none; }

/* line 298, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .email-parent-complete {
  display: none; }

/* line 303, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
#subscribe-modal .alert {
  position: absolute;
  left: 10%;
  width: 80%;
  top: 20px;
  border: 5px solid gray; }

/* line 312, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
body[lang='fr'] #subscribe-modal {
  font-size: 21px; }

/* line 315, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
body[lang='fr'] #subscribe-modal .comparison-table tbody {
  font-size: 13px; }

/* line 319, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
body[lang='de-DE'] #subscribe-modal .comparison-blurb {
  font-size: 16px; }

/* line 322, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
body[lang='de-DE'] #subscribe-modal .comparison-table tbody {
  font-size: 12px; }

/* line 326, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
body[lang='pt-PT'] #subscribe-modal .comparison-blurb {
  font-size: 16px; }

/* line 329, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
body[lang='pt-PT'] #subscribe-modal .comparison-table tbody {
  font-size: 12px; }

/* line 332, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/subscribe-modal.sass */
body[lang='pt-PT'] #subscribe-modal {
  font-size: 18px; }
