/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/poll/poll-edit-view.sass */
#editor-poll-edit-view .treema-root {
  margin: 28px 0px 20px; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/poll/poll-edit-view.sass */
#editor-poll-edit-view .poll-tool-button {
  float: right;
  margin-top: 15px;
  margin-left: 10px; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/poll/poll-edit-view.sass */
#editor-poll-edit-view textarea {
  width: 92%;
  height: 300px; }

/* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/poll/poll-edit-view.sass */
#editor-poll-edit-view #poll-view {
  min-height: 200px;
  position: relative;
  z-index: 0; }
