/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal .modal-body {
  padding: 10px 50px 30px 20px; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal .modal-footer {
  text-align: left; }
  /* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
  #save-version-modal .modal-footer .buttons {
    text-align: right; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal #cla-link {
  cursor: pointer;
  text-decoration: underline; }

/* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal #agreement-button {
  margin-left: 10px; }

/* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal .loading {
  text-align: center; }

/* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal .progress {
  margin: 0 20%;
  width: 60%; }

/* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal .progress-bar {
  width: 100%; }

/* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal .modal textarea {
  width: 92%;
  height: 300px; }

/* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal .help-block {
  font-size: 0.9em;
  font-style: italic; }

/* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal .delta-view {
  overflow-y: auto;
  padding: 10px;
  border: 1px solid black;
  background: #eff8fa;
  margin-bottom: 10px; }
  /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
  #save-version-modal .delta-view ul {
    padding-left: 20px; }

/* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal form {
  width: 100%; }

/* line 48, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal .commit-message {
  display: block;
  width: 100%; }

/* line 52, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal .checkbox {
  margin: 10px 10px 0; }
  /* line 54, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
  #save-version-modal .checkbox input {
    margin-right: 5px; }

/* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/modal/save-version-modal.sass */
#save-version-modal #errors-wrapper {
  margin-top: 20px;
  margin-bottom: 0; }
