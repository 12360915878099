/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/hero-select-modal.sass */
#hero-select-modal .modal-dialog {
  width: auto;
  max-width: 900px; }

/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/hero-select-modal.sass */
#hero-select-modal .modal-header, #hero-select-modal .modal-body:not(.secret), #hero-select-modal .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/hero-select-modal.sass */
#hero-select-modal .modal-footer {
  margin: 30px; }

/* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/hero-select-modal.sass */
#hero-select-modal h4 {
  max-width: 500px; }
