/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/keyboard_shortcuts.sass */
#keyboard-shortcuts-modal dl.dl-horizontal dt {
  width: 120px; }
  /* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/keyboard_shortcuts.sass */
  #keyboard-shortcuts-modal dl.dl-horizontal dt code {
    color: #333;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 100%; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/modal/keyboard_shortcuts.sass */
#keyboard-shortcuts-modal dl.dl-horizontal dd {
  margin-left: 140px; }
