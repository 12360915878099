/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .choose-option {
  margin-bottom: 15px;
  width: 48%;
  margin-right: 1%;
  margin-left: 1%;
  height: 200px;
  overflow: hidden;
  background: white;
  border: 1px solid #333;
  position: relative;
  float: left;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.4;
  border-radius: 5px; }
  /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
  #generate-terrain-modal .choose-option .only-one {
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 0; }

/* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .choose-option:hover {
  opacity: 1; }
  /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
  #generate-terrain-modal .choose-option:hover .only-one {
    opacity: 1; }

/* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .my-icon {
  position: relative;
  left: 0;
  top: -10px;
  z-index: 1; }

/* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .my-team-icon {
  height: 60px;
  position: relative;
  top: -10px;
  left: 10px;
  z-index: 0; }

/* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .opponent-team-icon {
  height: 60px;
  position: relative;
  top: 10px;
  right: 10px;
  z-index: 0;
  float: right;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

/* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .opponent-icon {
  position: relative;
  float: right;
  right: 0;
  top: -10px;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  z-index: 1; }

/* line 77, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .name-label {
  border-bottom: 20px solid lightslategray;
  height: 0;
  width: 40%;
  position: absolute;
  bottom: 0;
  color: black;
  font-weight: bold;
  text-align: center;
  z-index: 2; }
  /* line 88, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
  #generate-terrain-modal .name-label span {
    position: relative;
    top: 1px; }

/* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .my-name {
  border-right: 15px solid transparent;
  left: 0; }
  /* line 95, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
  #generate-terrain-modal .my-name span {
    left: 3px; }

/* line 98, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .preset-size {
  border-left: 15px solid transparent;
  right: 0; }
  /* line 102, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
  #generate-terrain-modal .preset-size span {
    right: 3px; }

/* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .preset-name {
  border-top: 25px solid darkgray;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  height: 0;
  width: 30%;
  position: absolute;
  left: 35%;
  top: 0;
  color: black;
  text-align: center;
  font-size: 18px;
  font-weight: bold; }
  /* line 119, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
  #generate-terrain-modal .preset-name span {
    position: relative;
    top: -25px; }

/* line 123, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .easy-option .preset-name {
  border-top: 25px solid limegreen; }

/* line 126, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .medium-option .preset-name {
  border-top: 25px solid darkorange; }

/* line 129, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .hard-option .preset-name {
  border-top: 25px solid black;
  color: white; }

/* line 133, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/modal/generate-terrain-modal.sass */
#generate-terrain-modal .capitalize {
  text-transform: capitalize; }
