/* line 1, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/level-feedback-view.sass */
#level-feedback-view {
  height: 100%; }
  /* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/level-feedback-view.sass */
  #level-feedback-view .user-feedback-list {
    height: 90%;
    height: -webkit-calc(100% - 80px);
    height: calc(100% - 80px);
    overflow-y: scroll;
    overflow-x: hidden; }
