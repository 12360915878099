/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-courses-view.sass */
#teacher-courses-view h1 {
  margin-top: 50px; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-courses-view.sass */
#teacher-courses-view #site-content-area {
  margin-bottom: 250px; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-courses-view.sass */
#teacher-courses-view .access-restricted {
  margin-top: 75px; }

/* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-courses-view.sass */
#teacher-courses-view .courses {
  margin-top: 20px; }
  /* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-courses-view.sass */
  #teacher-courses-view .courses .course {
    padding: 30px; }
    /* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-courses-view.sass */
    #teacher-courses-view .courses .course .concepts {
      font-weight: 600; }
    /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-courses-view.sass */
    #teacher-courses-view .courses .course .play-level-form {
      font-weight: normal; }

/* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-courses-view.sass */
#teacher-courses-view .course:nth-child(2n+1) {
  background-color: #ebebeb; }

/* line 30, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-courses-view.sass */
#teacher-courses-view .course:nth-child(2n) {
  background-color: #F5F5F5; }
