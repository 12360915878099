/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/code-play-create-account-modal.sass */
#code-play-create-account-modal .modal-dialog {
  margin: 0px auto 0 auto;
  padding: 0;
  height: 609px;
  width: 774px;
  background: none; }

/* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/code-play-create-account-modal.sass */
#code-play-create-account-modal .modal-content {
  height: 100%;
  width: 100%; }

/* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/code-play-create-account-modal.sass */
#code-play-create-account-modal .background-img {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%; }

/* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/code-play-create-account-modal.sass */
#code-play-create-account-modal button.close {
  position: absolute;
  right: 30px;
  top: 270px;
  font-size: 50px; }

/* line 30, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/code-play-create-account-modal.sass */
#code-play-create-account-modal .blurb-container {
  position: absolute;
  right: 162px;
  top: 280px;
  margin: 0;
  width: 450px;
  text-align: center;
  opacity: 0; }
  /* line 39, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/code-play-create-account-modal.sass */
  #code-play-create-account-modal .blurb-container h1 {
    font-size: 48px;
    font-weight: bold;
    color: black; }
  /* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/code-play-create-account-modal.sass */
  #code-play-create-account-modal .blurb-container button.code-play-sign-up-button {
    position: absolute;
    top: 195px;
    left: 12%;
    text-transform: uppercase;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 80%; }
