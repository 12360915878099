/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/community.sass */
#community-view .community-columns {
  width: 330px;
  float: left;
  padding-left: 10px;
  padding-right: 10px; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/community.sass */
#community-view .half-width {
  width: 50%;
  height: 175px;
  float: left; }

/* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/community.sass */
#community-view .logo-row {
  padding-top: 10px;
  text-align: center; }

/* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/community.sass */
#community-view .logo-row img {
  height: 50px;
  margin: 7px; }

/* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/community.sass */
#community-view .lower-titles {
  text-align: center; }

/* line 29, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/community.sass */
#community-view .logo-row img, #community-view .community-columns img {
  border-radius: 20px;
  -webkit-transition: background-color 0.2s linear, box-shadow 0.2s linear;
  transition: background-color 0.2s linear, box-shadow 0.2s linear; }
  /* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/community.sass */
  #community-view .logo-row img:hover, #community-view .community-columns img:hover {
    background-color: #7abee3;
    box-shadow: 0 0 20px #7abee3; }
