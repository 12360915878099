/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
#spell-palette-view {
  position: absolute;
  right: 43%;
  width: 200px;
  bottom: 0px;
  top: 0px;
  padding: 0 4px 0px 10px;
  background: transparent url(/images/level/wood_texture.png);
  background-size: auto 100%;
  z-index: 7;
  -webkit-transition: top 0.25s ease-in-out, width 0.25s ease-in-out;
  transition: top 0.25s ease-in-out, width 0.25s ease-in-out;
  box-shadow: 10px 4px 4px black;
  overflow: hidden; }
  /* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
  #level-view.real-time #spell-palette-view {
    display: none;
    visibility: hidden; }
  /* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
  #spell-palette-view.open {
    width: 560px; }
  /* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
  #spell-palette-view .container {
    width: 560px;
    height: 100%;
    position: relative; }
    /* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
    #spell-palette-view .container .left {
      position: absolute;
      left: 0;
      top: 80px;
      bottom: 0px;
      height: auto;
      width: 187px; }
      /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .left .section-header {
        display: block;
        color: white;
        margin-right: 10px; }
      /* line 39, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .left .spell-palette-thang-entry-view {
        display: inline-block;
        border: 5px solid black;
        border-image: url(/images/level/thang_avatar_frame.png) 20 20 20 20 fill round;
        margin: 1px; }
        /* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
        #spell-palette-view .container .left .spell-palette-thang-entry-view img {
          background-color: black;
          width: 72px;
          height: 72px;
          border: 1px solid transparent;
          cursor: pointer; }
        /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
        #spell-palette-view .container .left .spell-palette-thang-entry-view:hover img {
          border: 1px solid #f3cd90; }
        /* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
        #spell-palette-view .container .left .spell-palette-thang-entry-view.selected img {
          border: 1px solid white;
          cursor: auto; }
      /* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .left .nano-slider {
        background-color: rgba(243, 169, 49, 0.5); }
    /* line 59, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
    #spell-palette-view .container .right {
      position: absolute;
      right: 20px;
      min-width: 200px;
      left: 190px;
      top: 60px;
      bottom: 0px;
      -webkit-box-shadow: 0 0 0 #000;
      box-shadow: 0 0 0 #000; }
      /* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right .scrollArea {
        max-height: calc(100% - 20px);
        width: 100%;
        border-style: solid;
        border-image: url(/images/level/popover_border_background.png) 16 12 fill round;
        border-width: 16px 12px;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
        overflow-y: auto; }
      /* line 79, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right .always-scroll-y {
        overflow-y: scroll; }
        /* line 82, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
        #spell-palette-view .container .right .always-scroll-y .description img {
          float: left; }
      /* line 85, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right .closeBtn {
        position: absolute;
        right: -10px;
        top: 15px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        font-size: 20px;
        z-index: 2; }
      /* line 95, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right h1:not(.not-code), #spell-palette-view .container .right h2:not(.not-code), #spell-palette-view .container .right h3:not(.not-code), #spell-palette-view .container .right h4:not(.not-code), #spell-palette-view .container .right h5:not(.not-code), #spell-palette-view .container .right h6:not(.not-code) {
        font-family: Monaco, Menlo, Ubuntu Mono, Consolas, "source-code-pro", monospace !important;
        font-variant: normal;
        color: purple;
        text-transform: auto; }
      /* line 101, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right .popover-title {
        background-color: transparent;
        margin: 0 14px;
        padding: 8px 0;
        border-bottom-color: #ccc; }
      /* line 107, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right .popover-content {
        max-height: 100%;
        overflow-y: auto;
        margin-right: 10px; }
        /* line 111, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
        #spell-palette-view .container .right .popover-content img {
          float: right; }
      /* line 114, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right.top .arrow {
        bottom: -2%; }
      /* line 116, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right.bottom .arrow {
        top: -2%; }
      /* line 118, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right.left .arrow {
        right: 0%; }
      /* line 120, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right.right .arrow {
        left: -3%; }
      /* line 123, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right .docs-ace-container {
        padding: 2px 4px;
        border-radius: 4px; }
        /* line 127, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
        #spell-palette-view .container .right .docs-ace-container, #spell-palette-view .container .right .docs-ace-container .docs-ace {
          background-color: #f9f2f4;
          font-size: 12px;
          font-family: Monaco, Menlo, Ubuntu Mono, Consolas, "source-code-pro", monospace !important; }
        /* line 133, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
        #spell-palette-view .container .right .docs-ace-container .docs-ace .ace_cursor, #spell-palette-view .container .right .docs-ace-container .docs-ace .ace_bracket {
          display: none; }
      /* line 136, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container .right code {
        color: black;
        font-family: Monaco, Menlo, Ubuntu Mono, Consolas, "source-code-pro", monospace !important;
        font-size: 12px; }
    /* line 143, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
    #spell-palette-view .container h4.tab {
      color: white;
      font-size: 16px;
      line-height: 16px;
      margin: 25px 0 5px 2px;
      font-weight: normal;
      text-transform: uppercase; }
    /* line 151, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
    #spell-palette-view .container .nav > li > a {
      padding: 2px 20px 0px 20px;
      margin-bottom: 3px; }
    /* line 155, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
    #spell-palette-view .container ul.nav.nav-pills {
      margin-top: 3%; }
      /* line 158, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container ul.nav.nav-pills h4 {
        margin-top: 2px; }
      /* line 160, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container ul.nav.nav-pills li.active a {
        background-color: transparent; }
      /* line 162, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container ul.nav.nav-pills.multiple-tabs li.active a {
        background-color: #b5972a; }
      /* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container ul.nav.nav-pills.multiple-tabs li:not(.active) a {
        cursor: pointer; }
    /* line 168, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
    #spell-palette-view .container:not(.hero) .tab-content {
      height: 80px; }
      /* line 170, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view .container:not(.hero) .tab-content .nano-pane {
        width: 7px;
        right: 5px; }
    /* line 177, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
    #spell-palette-view .container .property-entry-column {
      display: inline-block;
      margin-right: 3px;
      vertical-align: top; }
  /* line 182, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
  #spell-palette-view #spell-palette-help-button {
    margin: 3% 0px 4px; }
  /* line 185, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
  #spell-palette-view.hero .properties {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-content: flex-start;
    -ms-flex-align-content: flex-start;
    align-content: flex-start; }
    /* line 191, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
    #spell-palette-view.hero .properties .property-entry-item-group {
      display: inline-block;
      min-height: 38px;
      width: 212px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      background-color: #140d08;
      margin: 1px; }
      /* line 202, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view.hero .properties .property-entry-item-group img.item-image {
        width: 38px;
        height: 38px;
        position: absolute;
        top: 0px; }
      /* line 208, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
      #spell-palette-view.hero .properties .property-entry-item-group:not(:hover) img.item-image {
        -webkit-filter: contrast(50%) sepia(100%) saturate(500%) hue-rotate(7deg);
        filter: contrast(50%) sepia(100%) saturate(1000%) hue-rotate(7deg); }
  /* line 213, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
  #spell-palette-view.shortenize.hero .properties .property-entry-item-group {
    width: 175px; }
    /* line 216, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
    #spell-palette-view.shortenize.hero .properties .property-entry-item-group .spell-palette-entry-view {
      width: 137px;
      width: -webkit-calc(100% - 38px);
      width: calc(100% - 38px); }
  /* line 222, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
  #spell-palette-view.web-dev.hero .properties .property-entry-item-group {
    width: 100px; }
    /* line 225, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
    #spell-palette-view.web-dev.hero .properties .property-entry-item-group .spell-palette-entry-view {
      margin-left: 0;
      width: 100px; }

@media only screen and (max-width: 1100px) {
  /* line 230, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-palette-view.sass */
  #spell-palette-view {
    padding-left: 12px; } }
