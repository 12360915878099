/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/classroom-settings-modal.sass */
#classroom-settings-modal #name-input {
  width: 50%; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/classroom-settings-modal.sass */
#classroom-settings-modal .age-range-select {
  width: 170px;
  display: inline-block; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/classroom-settings-modal.sass */
#classroom-settings-modal label {
  margin-top: 15px; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/classroom-settings-modal.sass */
#classroom-settings-modal .help-block {
  margin: 0; }
