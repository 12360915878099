/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
#thang-hud {
  width: calc(57% - 200px);
  height: 80px;
  position: relative;
  overflow: visible; }
  /* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #level-view.no-api #thang-hud {
    width: 57%; }
  /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud.controls-disabled {
    pointer-events: none; }
    /* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
    #thang-hud.controls-disabled .wood-background, #thang-hud.controls-disabled .hinge, #thang-hud.controls-disabled .avatar-wrapper-container, #thang-hud.controls-disabled .center {
      -webkit-filter: brightness(50%);
      -moz-filter: brightness(50%);
      -o-filter: brightness(50%);
      filter: brightness(50%); }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud .wood-background {
    position: absolute;
    left: 0;
    top: -2px;
    background: transparent url(/images/level/hud_wood_background.png);
    background-size: auto 100%;
    width: 100%;
    height: 100px;
    z-index: 4; }
  /* line 29, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud .hinge {
    position: absolute;
    top: -16px;
    background: transparent url(/images/level/hud_hinge.png);
    width: 27px;
    height: 44px;
    background-size: contain;
    z-index: 4;
    pointer-events: none; }
  /* line 39, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud .hinge-0 {
    left: 3%; }
  /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud .hinge-1 {
    left: 12%; }
  /* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud .hinge-2 {
    left: 86%;
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1); }
  /* line 46, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud .hinge-3 {
    left: 95%;
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1); }
  /* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud .avatar-wrapper-container {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0;
    left: 18%;
    left: -webkit-calc(50% - (560px - 100px) / 2 - 10px);
    left: calc(50% - (560px - 100px) / 2 - 10px);
    z-index: 5; }
    /* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
    #thang-hud .avatar-wrapper-container .thang-canvas-wrapper {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 4px;
      background-color: #4f4f4f;
      background-image: -webkit-linear-gradient(top, #bdbdbd 0%, #4f4f4f 100%);
      background-image: linear-gradient(to bottom, #bdbdbd 0%, #4f4f4f 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFBDBDBD', endColorstr='#FF4F4F4F', GradientType=0);
      background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(#bdbdbd 20%), to(#4f4f4f 70%));
      background-image: -webkit-radial-gradient(circle, #bdbdbd 20%, #4f4f4f 70%);
      background-image: -moz-radial-gradient(circle, #bdbdbd 20%, #4f4f4f 70%);
      background-image: -o-radial-gradient(circle, #bdbdbd 20%, #4f4f4f 70%);
      background-image: radial-gradient(circle, #bdbdbd 20%, #4f4f4f 70%);
      background-repeat: no-repeat; }
      /* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .avatar-wrapper-container .thang-canvas-wrapper.team-humans {
        border-color: darkred;
        background-color: #e62b1e;
        background-image: -webkit-linear-gradient(top, #f28f88 0%, #e62b1e 100%);
        background-image: linear-gradient(to bottom, #f28f88 0%, #e62b1e 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF28F88', endColorstr='#FFE62B1E', GradientType=0);
        background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(#f28f88 20%), to(#e62b1e 70%));
        background-image: -webkit-radial-gradient(circle, #f28f88 20%, #e62b1e 70%);
        background-image: -moz-radial-gradient(circle, #f28f88 20%, #e62b1e 70%);
        background-image: -o-radial-gradient(circle, #f28f88 20%, #e62b1e 70%);
        background-image: radial-gradient(circle, #f28f88 20%, #e62b1e 70%);
        background-repeat: no-repeat; }
      /* line 71, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .avatar-wrapper-container .thang-canvas-wrapper.team-ogres {
        border-color: darkblue;
        background-color: #005c9e;
        background-image: -webkit-linear-gradient(top, #7ac8ff 0%, #005c9e 100%);
        background-image: linear-gradient(to bottom, #7ac8ff 0%, #005c9e 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF7AC8FF', endColorstr='#FF005C9E', GradientType=0);
        background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(#7ac8ff 20%), to(#005c9e 70%));
        background-image: -webkit-radial-gradient(circle, #7ac8ff 20%, #005c9e 70%);
        background-image: -moz-radial-gradient(circle, #7ac8ff 20%, #005c9e 70%);
        background-image: -o-radial-gradient(circle, #7ac8ff 20%, #005c9e 70%);
        background-image: radial-gradient(circle, #7ac8ff 20%, #005c9e 70%);
        background-repeat: no-repeat; }
      /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .avatar-wrapper-container .thang-canvas-wrapper.team-allies, #thang-hud .avatar-wrapper-container .thang-canvas-wrapper.team-minions {
        border-color: darkgreen;
        background-color: #188e10;
        background-image: -webkit-linear-gradient(top, #8ff288 0%, #188e10 100%);
        background-image: linear-gradient(to bottom, #8ff288 0%, #188e10 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF8FF288', endColorstr='#FF188E10', GradientType=0);
        background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(#8ff288 20%), to(#188e10 70%));
        background-image: -webkit-radial-gradient(circle, #8ff288 20%, #188e10 70%);
        background-image: -moz-radial-gradient(circle, #8ff288 20%, #188e10 70%);
        background-image: -o-radial-gradient(circle, #8ff288 20%, #188e10 70%);
        background-image: radial-gradient(circle, #8ff288 20%, #188e10 70%);
        background-repeat: no-repeat; }
      /* line 79, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .avatar-wrapper-container .thang-canvas-wrapper .thang-canvas {
        width: 100%; }
      /* line 82, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .avatar-wrapper-container .thang-canvas-wrapper .avatar-frame {
        position: absolute;
        left: -18%;
        top: -19%;
        width: 145%; }
  /* line 88, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud.hide-hud-properties .center:hover {
    top: 24px; }
  /* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud .center {
    width: 560px;
    height: 166px;
    position: absolute;
    top: 24px;
    left: 13%;
    left: -webkit-calc(50% - 560px / 2);
    left: calc(50% - 560px / 2);
    padding: 10px 20px 0 145px;
    background-image: url(/images/level/hud_background.png);
    color: white;
    text-transform: uppercase;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 16px;
    z-index: 4;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease; }
    /* line 110, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
    #thang-hud .center:hover {
      top: -36px; }
    /* line 113, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
    #thang-hud .center .thang-name {
      font-size: 18px;
      margin: 10px 0 0 0; }
    /* line 117, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
    #thang-hud .center .thang-props {
      margin: 24px 0 0 0;
      float: left;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      -o-user-select: text;
      user-select: text; }
      /* line 122, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .center .thang-props .prop:not([name="health"]) {
        min-width: 120px;
        display: inline-block;
        line-height: 16px; }
      /* line 127, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .center .thang-props.nonexistent {
        visibility: hidden; }
      /* line 130, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .center .thang-props .text-prop {
        width: 50%; }
      /* line 133, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .center .thang-props .prop-label-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        background: transparent url(/images/level/hud_info_icons.png) no-repeat;
        background-size: auto 16px;
        float: left; }
        /* line 143, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-pos {
          background-position: -16px 0px; }
        /* line 145, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-target {
          background-position: -32px 0px; }
        /* line 147, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-collectedThangIDs {
          background-position: -48px 0px; }
        /* line 149, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-visualRange {
          background-position: -64px 0px; }
        /* line 151, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-attackDamage {
          background-position: -80px 0px; }
        /* line 153, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-attackRange, #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-attackNearbyEnemyRange {
          background-position: -96px 0px; }
        /* line 155, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-maxSpeed {
          background-position: -112px 0px; }
        /* line 157, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-gold, #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-bountyGold, #thang-hud .center .thang-props .prop-label-icon.prop-label-icon-value {
          background-position: -128px 0px; }
      /* line 160, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .center .thang-props .prop[name="health"] {
        position: absolute;
        right: 35px;
        top: 23px;
        height: 18px;
        line-height: 18px;
        font-size: 18px; }
        /* line 168, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop[name="health"] .prop-value.bar-prop {
          width: 150px;
          margin: 1px 10px 0 0;
          height: 16px;
          background: #201b15;
          padding: 4px;
          border-radius: 8px;
          border: 0; }
          /* line 177, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
          #thang-hud .center .thang-props .prop[name="health"] .prop-value.bar-prop .bar {
            background: #ea232d;
            height: 8px;
            border-radius: 4px; }
        /* line 182, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop[name="health"] .bar-prop-value {
          vertical-align: top; }
      /* line 185, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .center .thang-props .prop-value.bar-prop {
        width: 100px;
        display: inline-block;
        height: 6px;
        background: #ddd;
        border: 1px solid black;
        border-radius: 6px;
        overflow: hidden; }
        /* line 194, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .prop-value.bar-prop .bar {
          background: black;
          width: 100%;
          height: 100%; }
      /* line 199, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
      #thang-hud .center .thang-props .message {
        text-align: center;
        display: table;
        height: 100%;
        width: 100%; }
        /* line 205, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
        #thang-hud .center .thang-props .message p {
          display: table-cell;
          vertical-align: middle;
          font-size: 20px; }
  /* line 211, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud.code-play .avatar-wrapper-container {
    left: calc(50% - (560px - 100px) / 2 - 10px + 50px); }
  /* line 214, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud.code-play .center .thang-name {
    margin-left: 45px; }
  /* line 217, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
  #thang-hud.code-play .center .thang-props .prop[name="health"] {
    right: 90px; }
    /* line 219, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/hud.sass */
    #thang-hud.code-play .center .thang-props .prop[name="health"] .prop-value.bar-prop {
      width: 100px; }
