/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
#level-view.hero .spell-palette-entry-view {
  cursor: default; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
.spell-palette-entry-view {
  display: block;
  padding: 0px 4px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 12px;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  -o-user-select: all;
  user-select: all;
  height: 19px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 38px;
  width: 174px;
  width: calc(100% - 38px);
  color: #f3a931; }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-entry-view span {
    cursor: pointer; }
  /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-entry-view:hover {
    color: #f3cd90; }
  /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-entry-view.selected {
    color: white; }
  /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-entry-view:hover {
    border: 1px solid #000000; }
  /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-entry-view.pinned {
    background-color: #bfbfbf; }
  /* line 40, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-entry-view.single-entry {
    height: 38px;
    line-height: 38px; }

/* line 63, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
body.dialogue-view-active .spell-palette-popover.popover {
  top: 50px !important; }

/* line 66, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
.spell-palette-popover.popover {
  top: 80px !important;
  left: auto !important;
  right: 45%;
  max-width: 600px;
  min-width: 500px;
  padding: 0;
  border-style: solid;
  border-image: url(/images/level/popover_border_background.png) 16 12 fill round;
  border-width: 16px 12px;
  -webkit-box-shadow: 0 0 0 #000;
  box-shadow: 0 0 0 #000; }

@-webkit-keyframes jiggle {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(1deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-1deg); }
  100% {
    transform: rotate(0deg); } }

@-moz-keyframes jiggle {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(1deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-1deg); }
  100% {
    transform: rotate(0deg); } }

@-ms-keyframes jiggle {
  /* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover 0% {
    transform: rotate(0deg); }
  /* line 85, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover 25% {
    transform: rotate(1deg); }
  /* line 87, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover 50% {
    transform: rotate(0deg); }
  /* line 89, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover 75% {
    transform: rotate(-1deg); }
  /* line 91, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover 100% {
    transform: rotate(0deg); } }

@-o-keyframes jiggle {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(1deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-1deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes jiggle {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(1deg); }
  50% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(-1deg); }
  100% {
    transform: rotate(0deg); } }
  /* line 94, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover.jiggling {
    -webkit-animation: jiggle 0.3s infinite;
    animation: jiggle 0.3s infinite; }
  /* line 97, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover.pinned {
    top: 50px !important;
    right: 45%;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    -o-user-select: text;
    user-select: text;
    min-width: 45%;
    height: 60%; }
    /* line 107, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
    .spell-palette-popover.popover.pinned .arrow {
      display: none; }
    /* line 110, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
    .spell-palette-popover.popover.pinned .close {
      position: absolute;
      top: -7px;
      right: 2px;
      font-size: 28px;
      font-weight: bold;
      opacity: 0.6;
      filter: alpha(opacity=60);
      text-shadow: 0 1px 0 white; }
      /* line 119, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
      .spell-palette-popover.popover.pinned .close:hover {
        opacity: 1;
        filter: alpha(opacity=100); }
  /* line 122, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover h1:not(.not-code), .spell-palette-popover.popover h2:not(.not-code), .spell-palette-popover.popover h3:not(.not-code), .spell-palette-popover.popover h4:not(.not-code), .spell-palette-popover.popover h5:not(.not-code), .spell-palette-popover.popover h6:not(.not-code) {
    font-family: Monaco, Menlo, Ubuntu Mono, Consolas, "source-code-pro", monospace !important;
    font-variant: normal; }
  /* line 126, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover .popover-title {
    background-color: transparent;
    margin: 0 14px;
    padding: 8px 0;
    border-bottom-color: #ccc; }
  /* line 132, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover .popover-content {
    max-height: 100%;
    overflow-y: auto;
    margin-right: 10px; }
    /* line 136, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
    .spell-palette-popover.popover .popover-content img {
      float: right; }
  /* line 139, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover.top .arrow {
    bottom: -2%; }
  /* line 141, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover.bottom .arrow {
    top: -2%; }
  /* line 143, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover.left .arrow {
    right: 0%; }
  /* line 145, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover.right .arrow {
    left: -3%; }
  /* line 148, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover .docs-ace-container {
    padding: 2px 4px;
    border-radius: 4px; }
    /* line 152, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
    .spell-palette-popover.popover .docs-ace-container, .spell-palette-popover.popover .docs-ace-container .docs-ace {
      background-color: #f9f2f4;
      font-size: 12px;
      font-family: Monaco, Menlo, Ubuntu Mono, Consolas, "source-code-pro", monospace !important; }
    /* line 158, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
    .spell-palette-popover.popover .docs-ace-container .docs-ace .ace_cursor, .spell-palette-popover.popover .docs-ace-container .docs-ace .ace_bracket {
      display: none; }
  /* line 161, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .spell-palette-popover.popover code {
    color: black;
    font-family: Monaco, Menlo, Ubuntu Mono, Consolas, "source-code-pro", monospace !important;
    font-size: 12px; }

/* line 167, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
html.no-borderimage .spell-palette-popover.popover {
  background: transparent url(/images/level/popover_background.png);
  background-size: 100% 100%;
  border: 0; }

/* line 173, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
html.fullscreen-editor .spell-palette-popover.popover.pinned {
  min-width: 600px;
  bottom: inherit;
  right: 50%;
  margin-right: -300px; }

/* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
.tome-inventory-property-documentation {
  background-color: #e3d39a;
  padding: 10px;
  padding-bottom: 65px;
  width: 320px;
  min-height: 480px; }
  /* line 188, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_palette_entry.sass */
  .tome-inventory-property-documentation h1:not(.not-code), .tome-inventory-property-documentation h2:not(.not-code), .tome-inventory-property-documentation h3:not(.not-code), .tome-inventory-property-documentation h4:not(.not-code), .tome-inventory-property-documentation h5:not(.not-code), .tome-inventory-property-documentation h6:not(.not-code) {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }
