/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
.delta-view .panel-heading {
  font-size: 13px;
  padding: 0 4px; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
.delta-view .row {
  padding: 5px 10px; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
.delta-view .delta-added {
  border-color: green; }
  /* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
  .delta-view .delta-added > .panel-heading {
    background-color: #e6ffe6; }
    /* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
    .delta-view .delta-added > .panel-heading strong {
      color: green; }

/* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
.delta-view .delta-modified {
  border-color: darkgoldenrod; }
  /* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
  .delta-view .delta-modified > .panel-heading {
    background-color: #f9dc96; }
    /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
    .delta-view .delta-modified > .panel-heading strong {
      color: darkgoldenrod; }

/* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
.delta-view .delta-text-diff {
  border-color: blue; }
  /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
  .delta-view .delta-text-diff > .panel-heading {
    background-color: #e6e6ff; }
    /* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
    .delta-view .delta-text-diff > .panel-heading strong {
      color: blue; }
  /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
  .delta-view .delta-text-diff table {
    width: 100%; }

/* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
.delta-view .delta-deleted {
  border-color: red; }
  /* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
  .delta-view .delta-deleted > .panel-heading {
    background-color: #ffd6d6; }
    /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
    .delta-view .delta-deleted > .panel-heading strong {
      color: red; }

/* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
.delta-view .delta-moved-index {
  border-color: darkslategray; }
  /* line 40, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
  .delta-view .delta-moved-index > .panel-heading {
    background-color: #cee2e2; }
    /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/delta.sass */
    .delta-view .delta-moved-index > .panel-heading strong {
      color: darkslategray; }
