/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
#teacher-course-solution-view .print {
  text-align: center; }
  /* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
  #teacher-course-solution-view .print .btn a {
    color: white; }

/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
#teacher-course-solution-view #site-content-area {
  background-color: white;
  color: black;
  font-family: sans-serif;
  margin: 0px;
  padding: 24px; }
  /* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
  #teacher-course-solution-view #site-content-area hr {
    display: block;
    border-style: inset;
    border-width: 1px; }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
  #teacher-course-solution-view #site-content-area h1, #teacher-course-solution-view #site-content-area h2, #teacher-course-solution-view #site-content-area h3, #teacher-course-solution-view #site-content-area h4, #teacher-course-solution-view #site-content-area h5 {
    color: black;
    font-family: sans-serif;
    font-variant: normal;
    margin: 20px 0px; }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
  #teacher-course-solution-view #site-content-area h4 {
    color: gray; }
  /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
  #teacher-course-solution-view #site-content-area img {
    display: block;
    margin: auto; }
  /* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
  #teacher-course-solution-view #site-content-area p {
    margin: 16px 0px; }
  /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
  #teacher-course-solution-view #site-content-area .page-break-before {
    page-break-before: always; }
  @media screen {
    /* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
    #teacher-course-solution-view #site-content-area {
      max-width: 900px;
      background-color: #f8f8f8;
      border-radius: 10px;
      border: 1px solid #ddd;
      padding: 40px 100px;
      font-size: 0.85em;
      margin: 20px auto; } }

@media print {
  /* line 48, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
  #teacher-course-solution-view #main-nav, #teacher-course-solution-view #teacher-dashboard-nav, #teacher-course-solution-view #footer, #teacher-course-solution-view #final-footer, #teacher-course-solution-view .do-not-print {
    display: none; } }

/* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
#teacher-course-solution-view .ace_content {
  background-color: #f5f5f5; }
  /* line 54, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
  #teacher-course-solution-view .ace_content .ace_marker-layer .ace_bracket {
    display: none; }
  /* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/teachers/teacher-course-solution-view.sass */
  #teacher-course-solution-view .ace_content .ace_cursor-layer .ace_cursor {
    display: none; }
