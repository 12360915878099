/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
#admin-classrooms-progress-view h1 {
  margin-left: 10px; }

/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
#admin-classrooms-progress-view .small {
  margin-left: 10px;
  font-size: 12px; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
#admin-classrooms-progress-view table td, #admin-classrooms-progress-view table th {
  padding: 0px; }

/* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
#admin-classrooms-progress-view .range-container {
  position: relative;
  width: 80%; }
  /* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
  #admin-classrooms-progress-view .range-container .course-cell {
    border-right: solid 1px black;
    position: absolute;
    height: 100%;
    top: 0px;
    line-height: 20px; }
    /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
    #admin-classrooms-progress-view .range-container .course-cell .course-cell-text {
      font-size: 8px;
      text-align: center; }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
  #admin-classrooms-progress-view .range-container .missing-course-cell {
    border-right: solid 1px black;
    position: absolute;
    height: 100%;
    top: 0px;
    line-height: 20px; }
    /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
    #admin-classrooms-progress-view .range-container .missing-course-cell .course-cell-text {
      font-size: 8px;
      text-align: center; }
  /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
  #admin-classrooms-progress-view .range-container .student-cell {
    position: absolute;
    height: 100%;
    top: 0px;
    width: 1px;
    background-color: red;
    opacity: 0.3; }
  /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
  #admin-classrooms-progress-view .range-container .level-cell {
    border-right: solid 1px black;
    position: absolute;
    height: 25%;
    top: 0px; }
  /* line 46, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
  #admin-classrooms-progress-view .range-container .missing-level-cell {
    position: absolute;
    height: 100%;
    top: 0px;
    width: 1px;
    background-color: blue;
    opacity: 0.3; }
  /* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
  #admin-classrooms-progress-view .range-container .completed-background {
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: red;
    opacity: 0.2; }
  /* line 60, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
  #admin-classrooms-progress-view .range-container .remaining-background {
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: green;
    opacity: 0.1; }
  /* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
  #admin-classrooms-progress-view .range-container .available-background {
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: blue;
    opacity: 0.1; }
  /* line 74, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/admin-classrooms-progress.sass */
  #admin-classrooms-progress-view .range-container .range-dates {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px; }
