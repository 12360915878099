/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
#buy-gems-modal-nl h1 {
  position: absolute;
  left: 325px;
  top: -70px;
  margin: 0;
  width: 612px;
  text-align: center;
  color: #febc44;
  font-size: 38px;
  text-shadow: black 4px 4px 0, black -4px -4px 0, black 4px -4px 0, black -4px 4px 0, black 4px 0px 0, black 0px -4px 0, black -4px 0px 0, black 0px 4px 0; }

/* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
#buy-gems-modal-nl .modal-dialog {
  margin: 150px auto 0 auto;
  padding: 0;
  width: 1300px;
  height: 460px;
  background: none; }

/* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
#buy-gems-modal-nl #buy-gems-background {
  position: absolute;
  top: -157px;
  left: -2px; }

/* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
#buy-gems-modal-nl #close-modal {
  position: absolute;
  left: 1005px;
  top: -80px;
  width: 60px;
  height: 60px;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 15px;
  cursor: pointer;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg); }
  /* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
  #buy-gems-modal-nl #close-modal:hover {
    color: yellow; }

/* line 52, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
#buy-gems-modal-nl #products {
  position: absolute;
  left: 55px;
  top: 242px;
  width: 1300px;
  height: 140px; }
  /* line 59, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
  #buy-gems-modal-nl #products .product {
    width: 228px;
    height: 136px;
    float: left;
    text-align: center;
    margin-right: 12px;
    position: relative; }
    /* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
    #buy-gems-modal-nl #products .product h4 {
      font-size: 20px;
      color: #161005; }
    /* line 71, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
    #buy-gems-modal-nl #products .product h3 {
      margin-top: 10px;
      text-transform: uppercase;
      color: #161005; }
    /* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
    #buy-gems-modal-nl #products .product button {
      position: absolute;
      width: 80%;
      left: 10%;
      bottom: -30px; }
    /* line 82, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
    #buy-gems-modal-nl #products .product .btn-ideal {
      border-image: url(/images/common/lang-nl/button-background-ideal-active-border.png) 14 20 20 fill round;
      color: #f2f2f2; }
    /* line 86, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
    #buy-gems-modal-nl #products .product a {
      color: #f2f2f2;
      text-decoration: none; }

/* line 91, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/lang-nl/buy-gems-modal-nl.sass */
#buy-gems-modal-nl .alert {
  position: absolute;
  left: 10%;
  width: 80%;
  top: 20px;
  border: 5px solid gray; }
