/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
#play-game-dev-level-view .container-fluid {
  overflow: hidden;
  background: #333;
  padding: 15px;
  min-height: 100vh; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
#play-game-dev-level-view #game-row {
  display: flex; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
#play-game-dev-level-view #canvas-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
  border-radius: 5px; }

/* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
#play-game-dev-level-view #webgl-surface {
  background-color: #333; }

/* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
#play-game-dev-level-view #normal-surface {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none; }

/* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
#play-game-dev-level-view canvas#webgl-surface, #play-game-dev-level-view canvas#normal-surface {
  display: block;
  z-index: 2; }

/* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
#play-game-dev-level-view #info-col .panel {
  height: 100%;
  max-height: calc(100vh - 30px);
  display: flex;
  flex-direction: column; }

/* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
#play-game-dev-level-view #info-col .panel-body {
  flex-grow: 1;
  overflow: scroll; }

/* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
#play-game-dev-level-view #info-col .panel-footer {
  min-height: 70px; }

/* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
#play-game-dev-level-view #share-panel-body {
  display: flex;
  align-items: center; }
  /* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
  #play-game-dev-level-view #share-panel-body #share-text-div, #play-game-dev-level-view #share-panel-body #copy-url-div {
    flex-grow: 1; }
  /* line 52, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
  #play-game-dev-level-view #share-panel-body #share-text-div {
    margin-right: 20px; }
  /* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
  #play-game-dev-level-view #share-panel-body #copy-url-input {
    width: 50%; }
  /* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/play-game-dev-level-view.sass */
  #play-game-dev-level-view #share-panel-body #copy-url-div {
    margin-left: 20px; }
