/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
.site-chrome {
  background-color: white;
  padding-top: 185px;
  max-width: 1920px;
  margin: 0 auto; }
  /* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
  .site-chrome.show-background {
    background: url(/images/pages/base/background.jpg) top center no-repeat;
    background-color: #96ca44; }
    @media screen and (max-height: 800px) {
      /* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome.show-background {
        background-position: center -226px; } }
  @media screen and (max-height: 800px) {
    /* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome {
      padding-top: 50px; } }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
  .site-chrome #site-nav {
    position: absolute;
    background: url(/images/pages/base/nav_background.png) top center no-repeat;
    left: 0;
    top: 56px;
    right: 0;
    height: 144px;
    text-align: center;
    min-width: 1024px;
    z-index: 1; }
    @media screen and (max-height: 800px) {
      /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-nav {
        top: -80px; } }
    /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-nav #nav-logo {
      position: absolute;
      margin-right: auto;
      margin-left: auto;
      left: 0;
      right: 0;
      top: -45px; }
      @media screen and (max-height: 800px) {
        /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-nav #nav-logo {
          display: none; } }
    /* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-nav #small-nav-logo {
      display: none; }
      @media screen and (max-height: 800px) {
        /* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-nav #small-nav-logo {
          display: inline-block;
          height: 30px; } }
    /* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-nav #site-nav-links {
      position: absolute;
      bottom: 21px;
      left: 0;
      right: 0; }
      /* line 59, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-nav #site-nav-links > a {
        color: #9e8777; }
        /* line 61, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-nav #site-nav-links > a:hover {
          color: #fff; }
      /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-nav #site-nav-links > a, .site-chrome #site-nav #site-nav-links button, .site-chrome #site-nav #site-nav-links select {
        font-size: 18px;
        text-transform: uppercase;
        font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
        margin: 0 7px; }
    /* line 70, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-nav button, .site-chrome #site-nav select {
      position: relative;
      top: -3px;
      height: 32px;
      color: #444; }
    /* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-nav .signup-button {
      background: red;
      color: white; }
    /* line 80, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-nav .login-button {
      background: white;
      color: black; }
    /* line 84, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-nav .language-dropdown {
      width: auto;
      padding: 0px 10px;
      display: inline-block; }
    /* line 89, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-nav #site-nav-smooth-edge {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 5px; }
    /* line 100, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-nav .dropdown .account-settings-image {
      width: 18px; }
    /* line 103, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-nav .dropdown .dropdown-menu {
      width: 180px;
      padding: 0px;
      border-radius: 0px; }
      /* line 108, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-nav .dropdown .dropdown-menu .user-dropdown-header {
        background: #E4CF8C;
        height: auto;
        padding: 10px;
        text-align: center;
        color: black;
        border-bottom: #32281e 1px solid; }
        /* line 116, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-nav .dropdown .dropdown-menu .user-dropdown-header .img-circle {
          background-position: center;
          background-size: cover;
          border: #e3be7a 8px solid;
          width: 98px;
          height: 98px;
          display: inline-block;
          vertical-align: middle; }
          /* line 124, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
          .site-chrome #site-nav .dropdown .dropdown-menu .user-dropdown-header .img-circle:hover {
            box-shadow: 0 0 20px #e3be7a; }
        /* line 127, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-nav .dropdown .dropdown-menu .user-dropdown-header h3 {
          font-variant: small-caps;
          font-family: Open Sans Condensed;
          margin-top: 10px;
          text-shadow: 2px 2px 3px white;
          color: #31281E; }
        /* line 134, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-nav .dropdown .dropdown-menu .user-dropdown-header .user-level {
          position: absolute;
          top: 73px;
          right: 40px;
          color: gold;
          text-shadow: 1px 1px black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black; }
      /* line 141, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-nav .dropdown .dropdown-menu li {
        color: black;
        font-size: 16px; }
      /* line 145, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-nav .dropdown .dropdown-menu #logout-button {
        font-weight: bold; }
  /* line 150, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
  .site-chrome #site-content-area {
    background: #f0e5c7;
    margin: 0 auto -20px;
    width: 1024px;
    border: 5px solid #6e5829;
    padding: 20px 12px;
    min-height: 300px; }
  /* line 161, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
  .site-chrome #site-footer {
    width: 100%;
    height: 130px;
    position: relative;
    overflow: hidden; }
    /* line 168, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-footer #footer-background {
      transform: rotate(180deg);
      margin: 0 auto;
      display: block; }
    /* line 173, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-footer #footer-links, .site-chrome #site-footer #footer-credits {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center; }
    /* line 179, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-footer #footer-links {
      top: 20px;
      height: 45px;
      padding: 11px; }
      /* line 184, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-footer #footer-links a {
        color: #9e8777;
        font-size: 18px;
        text-transform: uppercase;
        font-family: Open Sans Condensed;
        margin: 0 7px; }
        /* line 191, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-footer #footer-links a:hover {
          color: #fff; }
      /* line 194, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-footer #footer-links .share-buttons {
        display: inline-block;
        position: relative;
        top: 3px;
        margin-left: 20px; }
      /* line 200, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-footer #footer-links .share-buttons, .site-chrome #site-footer #footer-links .partner-badges {
        padding-bottom: 10px;
        text-align: center;
        opacity: 0.75;
        filter: alpha(opacity=75); }
        /* line 205, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-footer #footer-links .share-buttons.fade-in, .site-chrome #site-footer #footer-links .partner-badges.fade-in {
          opacity: 0;
          filter: alpha(opacity=0); }
        /* line 208, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-footer #footer-links .share-buttons:hover, .site-chrome #site-footer #footer-links .share-buttons:active, .site-chrome #site-footer #footer-links .partner-badges:hover, .site-chrome #site-footer #footer-links .partner-badges:active {
          opacity: 1;
          filter: alpha(opacity=100);
          -webkit-transition: opacity 0.1s linear;
          transition: opacity 0.1s linear; }
        /* line 212, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-footer #footer-links .share-buttons .github-star-button, .site-chrome #site-footer #footer-links .partner-badges .github-star-button {
          margin-left: 20px; }
        /* line 215, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
        .site-chrome #site-footer #footer-links .share-buttons > div, .site-chrome #site-footer #footer-links .partner-badges > div {
          display: inline-block;
          vertical-align: top; }
    /* line 219, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
    .site-chrome #site-footer #footer-credits {
      top: 79px;
      height: 50px;
      color: #9e8777;
      font-size: 12px; }
      /* line 225, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-footer #footer-credits > * {
        margin: 0 15px;
        width: 186px;
        display: inline-block; }
      /* line 230, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-footer #footer-credits #footer-logo {
        width: 160px; }
      /* line 233, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-footer #footer-credits > span {
        position: relative;
        top: 8px; }
      /* line 237, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/common/site-chrome.sass */
      .site-chrome #site-footer #footer-credits a {
        color: #eee383; }
