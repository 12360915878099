/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
#user-home .left-column {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    /* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
    #user-home .left-column {
      float: left;
      width: 33.33333%; } }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
#user-home .right-column {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
    #user-home .right-column {
      float: left;
      width: 66.66667%; } }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
#user-home .profile-wrapper {
  text-align: center;
  outline: 1px solid darkgrey;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  /* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
  #user-home .profile-wrapper > .picture {
    width: 100%;
    background-color: #ffe4bc;
    border: 4px solid white; }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
  #user-home .profile-wrapper > .profile-info {
    background: white; }
    /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
    #user-home .profile-wrapper > .profile-info .extra-info {
      padding-bottom: 3px; }
      /* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
      #user-home .profile-wrapper > .profile-info .extra-info:empty {
        display: none; }
  /* line 30, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
  #user-home .profile-wrapper .name {
    margin: 0px auto;
    padding: 10px inherit;
    color: white;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000; }

/* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
#user-home .profile-menu {
  padding-left: 0px;
  width: 100%; }
  /* line 39, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
  #user-home .profile-menu > a {
    border-radius: 0;
    border-width: 1px 0px 0px 0px;
    border-color: darkgrey; }
    /* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
    #user-home .profile-menu > a:hover {
      border-color: #888; }
    /* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
    #user-home .profile-menu > a > span {
      color: #555555;
      font-size: 15px;
      margin-left: 5px; }

/* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
#user-home .panel-footer {
  text-align: right; }

/* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
.contributor-categories {
  list-style: none;
  padding: 0px;
  margin-top: 15px; }
  /* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
  .contributor-categories > .contributor-category {
    outline: 1px solid black;
    margin-bottom: 15px; }
    /* line 62, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
    .contributor-categories > .contributor-category > .contributor-image {
      border: none;
      width: 100%;
      border-bottom: 1px solid black; }
    /* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
    .contributor-categories > .contributor-category > .contributor-title {
      text-align: center;
      padding: 5px 0px;
      margin: 0px;
      background: white; }

/* line 73, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/user/main-user-view.sass */
.vertical-buffer {
  padding: 10px 0px; }
