/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute.sass */
#contribute-view .class_tile {
  position: relative;
  width: 330px;
  padding: 5px;
  float: left;
  z-index: 0; }
  /* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute.sass */
  #contribute-view .class_tile:hover img {
    outline: 3px solid #161a9e; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute.sass */
#contribute-view .class_text {
  position: absolute;
  bottom: 5px;
  width: 300px;
  padding: 12px 12px 0 12px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5); }
  /* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute.sass */
  #contribute-view .class_text p {
    color: black; }
  /* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/contribute/contribute.sass */
  #contribute-view .class_text h3 {
    color: black;
    padding-top: 0px;
    margin-top: 0px; }
