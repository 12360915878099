/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal h3 {
  font-size: 20px; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .subtitle {
  font-size: 14px;
  line-height: 14px; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .modal-dialog {
  width: 850px;
  height: 550px;
  max-height: 100vh; }

/* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .modal-footer {
  display: none; }

/* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .modal-header {
  padding: 0;
  min-height: 0; }

/* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .modal-body-content {
  height: 485px; }

/* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }

/* line 30, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .image-list {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  margin: 0; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
  #image-gallery-modal .image-list::-webkit-scrollbar {
    border: thin solid gray;
    width: 14px; }
  /* line 40, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
  #image-gallery-modal .image-list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); }

/* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .flex-col {
  display: flex;
  flex-direction: column;
  height: 100%; }

/* line 48, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .image-list {
  height: 440px;
  max-height: 100vh;
  padding: 0;
  display: flex;
  flex-wrap: wrap; }

/* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .image-list-item {
  margin: 16px;
  list-style-type: none;
  background-color: #f8f8f8;
  box-shadow: 0 0 0 1px gray; }
  /* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
  #image-gallery-modal .image-list-item img {
    width: 72px;
    height: 72px; }
  /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
  #image-gallery-modal .image-list-item.selected {
    box-shadow: 0 0 0 6px #F2BE19; }

/* line 69, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .copy-row {
  display: flex;
  align-items: center; }
  /* line 73, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
  #image-gallery-modal .copy-row .copy-textarea-col {
    flex-grow: 1; }
    /* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
    #image-gallery-modal .copy-row .copy-textarea-col textarea {
      width: 100%;
      height: 55px; }
  /* line 80, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
  #image-gallery-modal .copy-row .copy-button-col {
    padding-left: 10px; }

/* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .copyable {
  font-size: 10px;
  line-height: 12px; }

/* line 87, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .how-to-copy-paste {
  font-size: 13px;
  line-height: 16px;
  font-style: italic;
  color: gray; }

/* line 93, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .close-button {
  flex-grow: 1;
  align-self: flex-end;
  display: flex;
  align-items: flex-end; }

/* line 100, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .button.close {
  z-index: 1; }

/* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
#image-gallery-modal .hr-text {
  position: relative; }
  /* line 107, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
  #image-gallery-modal .hr-text hr {
    width: 50%;
    padding: 0;
    border: none;
    border-top: thin solid #444;
    color: #444; }
  /* line 113, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/image-gallery-modal.sass */
  #image-gallery-modal .hr-text span {
    position: absolute;
    left: 50%;
    top: 0.45em;
    transform: translateX(-50%);
    padding: 0 0.75em;
    font-weight: bold;
    font-size: 10pt;
    background: white; }
