/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
#add-thangs-view {
  width: 314px;
  background: white;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 5px;
  border: 1px solid;
  z-index: 3; }
  /* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
  #add-thangs-view input {
    width: 100%;
    margin-top: 5px; }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
  #add-thangs-view #thangs-list {
    position: absolute;
    left: 8px;
    top: 80px;
    bottom: 0px;
    overflow: scroll;
    margin: 0; }
  /* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
  #add-thangs-view h3 {
    margin: 0 0 10px;
    width: 100%; }
  /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
  #add-thangs-view h4 {
    margin: 0 0 10px;
    clear: both;
    padding: 5px;
    background: rgba(150, 150, 150, 0.5);
    width: 294px;
    box-sizing: border-box; }
  /* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
  #add-thangs-view .clearfix {
    margin-bottom: 20px; }
  /* line 48, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
  #add-thangs-view .add-thang-palette-icon {
    position: relative;
    float: left;
    background: white;
    padding: 0px;
    margin: 4px;
    cursor: pointer;
    width: 40px;
    height: 40px; }
    /* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
    #add-thangs-view .add-thang-palette-icon img {
      position: absolute;
      width: 40px;
      height: 40px;
      transition: box-shadow 0.25s ease-out; }
    /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
    #add-thangs-view .add-thang-palette-icon:hover {
      outline: 1px dotted blue; }
      /* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
      #add-thangs-view .add-thang-palette-icon:hover img {
        left: -4px;
        top: -4px;
        width: 48px;
        height: 48px; }
    /* line 73, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/add-thangs-view.sass */
    #add-thangs-view .add-thang-palette-icon.selected {
      outline: 1px solid blue;
      -webkit-box-shadow: 0px 5px 25px rgba(79, 79, 213, 0.6);
      box-shadow: 0px 5px 25px rgba(79, 79, 213, 0.6);
      background: #add8e6; }
