/* line 1, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
#admin-analytics-view {
  background-position: center -226px;
  padding-top: 50px; }
  /* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view #site-nav {
    top: -80px; }
    /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
    #admin-analytics-view #site-nav #nav-logo {
      display: none; }
    /* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
    #admin-analytics-view #site-nav #small-nav-logo {
      display: inline-block;
      height: 30px; }
  /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view #site-content-area {
    width: 100%; }
  /* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view #nav-bar {
    border: none; }
  /* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view .active-classes {
    color: blue; }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view .recurring-revenue {
    color: green; }
  /* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view .campaign-active-users {
    color: purple; }
  /* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view .classroom-active-users {
    color: red; }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view .count {
    font-size: 70pt; }
  /* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view .description {
    font-size: 8pt; }
  /* line 29, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view .kpi-table {
    margin: 0px;
    padding: 0px;
    text-align: center; }
    /* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
    #admin-analytics-view .kpi-table th {
      border: none;
      margin: 0px;
      padding: 0px; }
    /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
    #admin-analytics-view .kpi-table td {
      border: none;
      margin: 0px;
      padding: 0px; }
  /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view .line-chart-container {
    height: 500px;
    width: 100%; }
    /* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
    #admin-analytics-view .line-chart-container .x.axis {
      font-size: 9pt; }
      /* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
      #admin-analytics-view .line-chart-container .x.axis path {
        display: none; }
    /* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
    #admin-analytics-view .line-chart-container .y.axis {
      font-size: 9pt; }
      /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
      #admin-analytics-view .line-chart-container .y.axis path {
        display: none; }
  /* line 54, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/admin/analytics.sass */
  #admin-analytics-view .key-text {
    font-size: 20pt; }
