/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
.nowrap {
  white-space: nowrap; }

/* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
.alternating-background:nth-child(2n+1) {
  background-color: #ebebeb; }

/* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
.alternating-background:nth-child(2n) {
  background-color: #F5F5F5; }

/* line 15, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .breadcrumbs {
  color: #065e73; }
  /* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .breadcrumbs a {
    color: #065e73; }

/* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view h3 {
  display: inline-block; }

/* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view h3 ~ .edit-classroom {
  color: black;
  text-decoration: underline; }

/* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .classroom-details .small-details {
  margin-bottom: 4px; }

/* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .concept span {
  white-space: nowrap; }

/* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .concept.forest {
  color: #20572B; }

/* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .concept.gold {
  color: #F2BE19; }

/* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .concept + .concept:before {
  content: ', '; }

/* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view ul.nav-tabs {
  border: none; }
  /* line 43, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view ul.nav-tabs .tab-spacer {
    float: left;
    width: 15px;
    height: 48px;
    border-bottom: thin solid #979797; }
  /* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view ul.nav-tabs .tab-filler {
    overflow: hidden;
    height: 48px;
    border-bottom: thin solid #979797; }
  /* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view ul.nav-tabs li > a {
    margin: 0;
    border: thin solid #979797;
    border-bottom: none;
    background: white;
    border-radius: 5px 5px 0 0;
    width: 175px;
    color: black;
    font-family: "Arvo", serif; }
  /* line 65, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view ul.nav-tabs li {
    border-bottom: thin solid #979797; }
  /* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view ul.nav-tabs li.active {
    border-bottom: none; }

/* line 70, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .bulk-assign-controls {
  position: relative;
  float: right;
  margin-bottom: -9999px;
  margin-top: 20px; }
  /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .bulk-assign-controls select {
    margin-left: 7px; }
  /* line 77, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .bulk-assign-controls .assign-to-selected-students {
    margin-left: 10px; }
  /* line 79, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .bulk-assign-controls .enroll-selected-students {
    margin-left: 56px; }
  /* line 81, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .bulk-assign-controls .cant-assign-to-unenrolled, #teacher-class-view .bulk-assign-controls .no-students-selected {
    position: absolute;
    top: -24px;
    color: red;
    font-size: 13px;
    visibility: hidden; }
    /* line 87, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
    #teacher-class-view .bulk-assign-controls .cant-assign-to-unenrolled.visible, #teacher-class-view .bulk-assign-controls .no-students-selected.visible {
      visibility: visible; }

/* line 90, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .students-table {
  width: 100%; }
  /* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .students-table .student-info-col {
    width: 240px; }
  /* line 94, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .students-table .checkbox-col {
    width: 75px; }
  /* line 96, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .students-table .latest-level-col {
    width: 320px; }
  /* line 98, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .students-table .view-class-col {
    width: 20px; }
  /* line 101, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .students-table th {
    font-weight: normal;
    white-space: nowrap;
    vertical-align: bottom; }
  /* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .students-table td {
    height: 66px; }
  /* line 108, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .students-table .select-all {
    padding-top: 5px; }
    /* line 111, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
    #teacher-class-view .students-table .select-all .checkbox-flat {
      margin-top: 3px; }
  /* line 114, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .students-table .enroll-student-button {
    margin-left: 33%;
    margin-left: calc((100% - 120px - 36px) * 0.6); }

/* line 118, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .inline-student-list {
  padding-left: 0;
  list-style: none; }
  /* line 122, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .inline-student-list:before {
    content: '('; }
  /* line 124, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .inline-student-list:after {
    content: ')'; }
  /* line 128, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .inline-student-list li {
    display: inline; }
    /* line 130, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
    #teacher-class-view .inline-student-list li .inline-student-name {
      white-space: nowrap; }
  /* line 134, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .inline-student-list li:not(:last-child):after {
    content: ', '; }

/* line 137, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .student-info {
  max-width: 200px;
  line-height: 20px; }
  /* line 141, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .student-info .student-name, #teacher-class-view .student-info .student-email {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

/* line 146, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .level-name {
  white-space: nowrap;
  overflow: ellipsis;
  font-weight: bold;
  font-size: 14px; }

/* line 152, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .sort-button {
  border: none;
  background: none;
  font-weight: bold;
  text-decoration: underline; }

/* line 158, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .edit-student-button {
  color: black;
  font-weight: bold;
  text-decoration: underline; }
  /* line 163, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .edit-student-button .glyphicon {
    color: #999; }

/* line 166, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .edit-student-link {
  color: black; }

/* line 169, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .remove-student-link {
  color: #7D0101; }

/* line 172, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .edit-student-link, #teacher-class-view .remove-student-link {
  display: inline-block;
  font-weight: bold;
  text-decoration: underline;
  line-height: 16px; }

/* line 183, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #course-progress-tab #progress-color-key-row .col, #teacher-class-view #student-projects-tab #progress-color-key-row .col {
  min-height: 40px; }
  /* line 185, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #course-progress-tab #progress-color-key-row .col .progress-dot, #teacher-class-view #student-projects-tab #progress-color-key-row .col .progress-dot {
    position: absolute;
    top: 0;
    padding-top: 5px; }
    /* line 189, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
    #teacher-class-view #course-progress-tab #progress-color-key-row .col .progress-dot.practice, #teacher-class-view #student-projects-tab #progress-color-key-row .col .progress-dot.practice {
      margin-left: 26px;
      margin-top: 14px; }
  /* line 192, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #course-progress-tab #progress-color-key-row .col .key-text, #teacher-class-view #student-projects-tab #progress-color-key-row .col .key-text {
    line-height: 18px;
    margin-top: 9px;
    margin-left: 50px; }

/* line 197, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #course-progress-tab .course-overview-row, #teacher-class-view #student-projects-tab .course-overview-row {
  margin-top: 50px;
  border: thin solid gray;
  border-radius: 8px; }

/* line 202, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #course-progress-tab .student-levels-table, #teacher-class-view #student-projects-tab .student-levels-table {
  margin-top: 80px; }
  /* line 205, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #course-progress-tab .student-levels-table .sort-buttons, #teacher-class-view #student-projects-tab .student-levels-table .sort-buttons {
    padding-left: 75px;
    margin-bottom: 5px; }

/* line 209, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #course-progress-tab .student-levels-row, #teacher-class-view #course-progress-tab .course-overview-row, #teacher-class-view #student-projects-tab .student-levels-row, #teacher-class-view #student-projects-tab .course-overview-row {
  padding-left: 75px;
  padding-top: 15px;
  padding-bottom: 30px; }

/* line 214, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #course-progress-tab .student-levels-progress, #teacher-class-view #course-progress-tab .course-overview-progress, #teacher-class-view #student-projects-tab .student-levels-progress, #teacher-class-view #student-projects-tab .course-overview-progress {
  max-width: 880px;
  margin: -5px;
  margin-top: 5px; }

/* line 219, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #course-progress-tab .progress-dot, #teacher-class-view #student-projects-tab .progress-dot {
  margin: 5px; }

/* line 222, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #course-progress-tab .unassigned-students, #teacher-class-view #student-projects-tab .unassigned-students {
  margin-top: 75px;
  line-height: 45px; }
  /* line 225, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #course-progress-tab .unassigned-students .student-name, #teacher-class-view #course-progress-tab .unassigned-students .student-email, #teacher-class-view #course-progress-tab .unassigned-students .latest-completed, #teacher-class-view #student-projects-tab .unassigned-students .student-name, #teacher-class-view #student-projects-tab .unassigned-students .student-email, #teacher-class-view #student-projects-tab .unassigned-students .latest-completed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  /* line 230, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #course-progress-tab .unassigned-students .small-details, #teacher-class-view #course-progress-tab .unassigned-students .small, #teacher-class-view #student-projects-tab .unassigned-students .small-details, #teacher-class-view #student-projects-tab .unassigned-students .small {
    line-height: 45px; }
  /* line 232, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #course-progress-tab .unassigned-students .latest-completed, #teacher-class-view #student-projects-tab .unassigned-students .latest-completed {
    white-space: nowrap; }
    /* line 234, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
    #teacher-class-view #course-progress-tab .unassigned-students .latest-completed .level-name, #teacher-class-view #student-projects-tab .unassigned-students .latest-completed .level-name {
      display: inline; }
  /* line 237, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #course-progress-tab .unassigned-students .btn, #teacher-class-view #student-projects-tab .unassigned-students .btn {
    margin-top: 6.5px;
    margin-bottom: 6.5px; }

/* line 242, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #student-projects-tab .student-levels-table {
  margin-top: 0px; }

/* line 245, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #student-projects-tab .student-info {
  margin-top: 5px; }

/* line 248, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #student-projects-tab .student-levels-row {
  padding-top: 10px;
  padding-bottom: 15px; }

/* line 252, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #student-projects-tab .btn-view-project-level {
  margin-left: 15px; }

/* line 256, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .checkbox-flat {
  margin: 8px auto; }

/* line 260, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .view-class-arrow {
  float: right;
  margin-right: 16px;
  color: #999;
  font-size: 35px;
  line-height: 35px;
  align-self: center; }
  /* line 268, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .view-class-arrow .view-class-arrow-inner {
    color: #F5F5F5; }
    /* line 270, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
    #teacher-class-view .view-class-arrow .view-class-arrow-inner:hover {
      text-decoration: none; }

/* line 273, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .progress-dot {
  display: inline-block;
  margin-right: 6px;
  min-width: 34px;
  height: 34px;
  border-radius: 16px;
  padding: 0 5px;
  background: #999;
  position: relative; }
  /* line 284, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .progress-dot a {
    text-decoration: none; }
  /* line 287, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .progress-dot .dot-label {
    padding-top: 2px; }
    /* line 289, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
    #teacher-class-view .progress-dot .dot-label .dot-label-inner {
      font-size: 11px;
      font-weight: bold;
      color: white; }

/* line 294, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .progress-dot-lg .dot-label .dot-label-inner {
  font-size: 13px; }

/* line 297, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .progress-dot.forest {
  background: #20572B; }
  /* line 299, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .progress-dot.forest .tooltip-inner {
    color: #20572B;
    border-color: #20572B; }
  /* line 302, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .progress-dot.forest .tooltip-arrow {
    border-top-color: #20572B; }

/* line 305, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .progress-dot.gold {
  background: #F2BE19; }
  /* line 307, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .progress-dot.gold .tooltip-inner {
    color: #0E4C60;
    border-color: #0E4C60; }
  /* line 310, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .progress-dot.gold .tooltip-arrow {
    border-top-color: #0E4C60; }

/* line 313, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .progress-dot.navy {
  background: #0E4C60; }
  /* line 315, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .progress-dot.navy .tooltip-inner {
    color: #0E4C60;
    border-color: #0E4C60; }
  /* line 318, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .progress-dot.navy .tooltip-arrow {
    border-top-color: #0E4C60; }

/* line 321, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .progress-dot.practice {
  background: #999;
  height: 12px;
  position: relative;
  top: 6px;
  min-width: 12px;
  border-radius: 6px; }
  /* line 330, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view .progress-dot.practice .dot-label .dot-label-inner {
    color: #999; }

/* line 335, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .class-code-blurb {
  margin-top: 5px; }

/* line 339, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .copy-button-group input {
  height: 50px;
  width: 220px;
  background: #fafafa;
  border: thin solid #979797;
  text-align: center; }

/* line 346, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .copy-button-group #join-url-input {
  font-size: 14px; }

/* line 349, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .copy-button-group button {
  height: 50px;
  width: 210px;
  float: right; }

/* line 354, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view .export-student-progress-btn {
  margin-top: 10px; }

/* line 359, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #search-form-group {
  position: relative; }
  /* line 361, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #search-form-group input {
    width: auto; }
  /* line 363, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #search-form-group .glyphicon {
    color: #555;
    position: absolute;
    top: 8px;
    right: 5px; }

/* line 371, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #license-status-table .checkbox-col {
  width: 75px; }

/* line 373, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #license-status-table .student-info-col {
  width: 240px; }

/* line 375, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #license-status-table .status-col {
  width: 300px; }

/* line 377, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #license-status-table .enroll-col {
  width: 140px; }

/* line 379, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #license-status-table .revoke-col {
  width: 170px; }

/* line 381, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #license-status-table td {
  vertical-align: middle; }

/* line 385, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
#teacher-class-view #visualisation {
  fill: none;
  stroke: #777;
  shape-rendering: crispEdges; }
  /* line 386, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #visualisation .axis path {
    fill: none;
    stroke: #777;
    shape-rendering: crispEdges; }
  /* line 391, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #visualisation .axis text {
    font-family: Lato;
    font-size: 13px; }
  /* line 395, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #visualisation .legend {
    font-size: 14px;
    font-weight: bold; }
  /* line 404, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #visualisation .axis text {
    font-family: 'Arial';
    font-size: 13px; }
  /* line 407, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #visualisation .tick {
    stroke-dasharray: 1, 2; }
  /* line 409, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-class-view.sass */
  #teacher-class-view #visualisation .bar {
    fill: FireBrick; }
