/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
#spell-top-bar-view {
  width: 100%;
  height: 87px;
  padding: 10px 25px 25px 25px;
  white-space: nowrap;
  position: relative;
  background: transparent url(/images/level/code_editor_top_bar_wood_background.png);
  background-size: 100% 100%;
  z-index: 2; }
  /* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view #codeplay-powered-by-banner {
    width: 350px;
    position: relative;
    z-index: 1;
    top: 15px; }
  /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .hinge {
    position: absolute;
    top: 0;
    background: transparent url(/images/level/code_editor_top_bar_hinge.png);
    width: 24px;
    height: 20px;
    background-size: contain; }
  /* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .hinge-0 {
    left: 20%; }
  /* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .hinge-1 {
    left: 40%; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .hinge-2 {
    left: 60%; }
  /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .hinge-3 {
    left: 80%; }
  /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view.read-only {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), url(/images/level/code_editor_top_bar_wood_background.png);
    background-size: 100% 100%; }
    /* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
    #spell-top-bar-view.read-only > *:not(.spell-tool-buttons) {
      opacity: 0.5;
      filter: alpha(opacity=50); }
  /* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .btn.btn-small {
    margin-top: 15px;
    margin-right: 0.8vw;
    font-size: 18px; }
    /* line 52, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
    #spell-top-bar-view .btn.btn-small.reload-code {
      margin-right: 1.3vw; }
    /* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
    #spell-top-bar-view .btn.btn-small .glyphicon {
      font-size: 16px; }
  /* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .btn.btn-small.spell-list-button {
    float: left;
    margin-top: 5px; }
  /* line 62, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .spell-tool-buttons {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 3; }
  /* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .reload-code {
    float: right;
    display: none; }
  /* line 72, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .beautify-code {
    float: right; }
  /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .fullscreen-code {
    float: right; }
    /* line 78, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
    #spell-top-bar-view .fullscreen-code:not(.maximized) .glyphicon-resize-small {
      display: none; }
    /* line 81, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
    #spell-top-bar-view .fullscreen-code.maximized .glyphicon-fullscreen {
      display: none; }
  /* line 84, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .hints-button {
    float: right;
    border-style: solid;
    border-image: url(/images/common/button-background-primary-active.png) 14 20 20 20 fill round;
    border-width: 7px 10px 10px 10px;
    color: white; }
    /* line 90, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
    #spell-top-bar-view .hints-button:hover, #spell-top-bar-view .hints-button:active {
      border-image: url(/images/common/button-background-primary-pressed.png) 14 20 20 20 fill round; }
  /* line 93, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell-top-bar-view.sass */
  #spell-top-bar-view .thang-avatar-wrapper {
    border-width: 0; }
