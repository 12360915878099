/* line 1, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/docs/systems-documentation-view.sass */
#systems-documentation-view {
  background-color: #e4cf8c;
  height: calc(100% - 90px); }
  /* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/docs/systems-documentation-view.sass */
  #systems-documentation-view #toggle-all-system-code {
    margin: 10px; }
  /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/docs/systems-documentation-view.sass */
  #systems-documentation-view .container, #systems-documentation-view .row {
    height: 100%; }
  /* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/docs/systems-documentation-view.sass */
  #systems-documentation-view .index-column, #systems-documentation-view .documentation-column {
    overflow-x: hidden; }
    /* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/docs/systems-documentation-view.sass */
    #systems-documentation-view .index-column > ul, #systems-documentation-view .documentation-column > ul {
      padding: 0px 20px 20px 20px; }
    /* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/docs/systems-documentation-view.sass */
    #systems-documentation-view .index-column .doc-name, #systems-documentation-view .documentation-column .doc-name {
      color: saddlebrown; }
  /* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/docs/systems-documentation-view.sass */
  #systems-documentation-view .index-column {
    width: 25%; }
  /* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/docs/systems-documentation-view.sass */
  #systems-documentation-view .documentation-column {
    width: 75%; }
    /* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/docs/systems-documentation-view.sass */
    #systems-documentation-view .documentation-column .special-list, #systems-documentation-view .documentation-column .doc-description, #systems-documentation-view .documentation-column .code-block {
      list-style-type: none; }
