/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_translation.sass */
.spell-translation-view {
  position: absolute;
  z-index: 9001;
  max-width: 400px; }
  /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_translation.sass */
  .spell-translation-view pre {
    margin-bottom: 0; }
    /* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_translation.sass */
    .spell-translation-view pre code {
      white-space: nowrap; }

/* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/spell_translation.sass */
html.no-borderimage .spell-translation-view {
  background: transparent url(/images/level/popover_background.png);
  background-size: 100% 100%;
  border: 0; }
