@media (min-width: 992px) {
  /* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
  #enrollments-view #prepaids-col {
    padding-right: 40px; }
  /* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
  #enrollments-view #actions-col {
    border-left: 1px solid gray;
    padding-left: 40px; } }

/* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
#enrollments-view .prepaid-card {
  border-radius: 10px; }
  /* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
  #enrollments-view .prepaid-card p {
    color: white; }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
  #enrollments-view .prepaid-card h1 {
    font-size: 68px; }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
  #enrollments-view .prepaid-card.pending-prepaid-card {
    background: #6e939f; }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
  #enrollments-view .prepaid-card.empty-prepaid-card {
    background: #bbbbbb; }

/* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
#enrollments-view .share-details .share-licenses-link {
  color: black;
  text-decoration: underline; }

/* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
#enrollments-view .prepaid-card-container {
  height: 309px; }

/* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
#enrollments-view .share-details {
  text-align: right; }

/* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
#enrollments-view .how-to-enroll {
  padding: 10px;
  border: thin gray solid;
  border-radius: 5px; }
  /* line 40, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
  #enrollments-view .how-to-enroll ol {
    padding-left: 20px;
    line-height: 18px; }

/* line 46, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
#enrollments-view #students-input {
  width: 220px;
  height: 80px;
  font-size: 50px; }
  /* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
  #enrollments-view #students-input::-webkit-inner-spin-button, #enrollments-view #students-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

/* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
#enrollments-view #enrollment-stats-table td, #enrollments-view #enrollment-stats-table th {
  border: none; }

/* line 59, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
#enrollments-view #enrollment-stats-table .classroom-name-td {
  padding-left: 20px; }

/* line 62, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/enrollments-view.sass */
#enrollments-view #enrollment-stats-table th {
  padding-bottom: 10px; }
