/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
#control-bar-view {
  width: 57%;
  height: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: visible;
  position: absolute;
  z-index: 8;
  text-transform: uppercase;
  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold; }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
  #control-bar-view.controls-disabled {
    -webkit-filter: brightness(50%);
    -moz-filter: brightness(50%);
    -o-filter: brightness(50%);
    filter: brightness(50%); }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
  #control-bar-view .levels-link-area {
    position: absolute;
    left: 40px;
    width: 160px;
    text-align: center;
    cursor: pointer; }
    /* line 29, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
    #control-bar-view .levels-link-area a.levels-link {
      margin: 0;
      height: 50px;
      line-height: 50px;
      color: white;
      font-size: 16px; }
      /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
      #control-bar-view .levels-link-area a.levels-link .glyphicon {
        margin-left: -20px;
        margin-right: 10px;
        -webkit-transform: scaleX(-1.25) scaleY(1.25);
        -ms-transform: scaleX(-1.25) scaleY(1.25);
        transform: scaleX(-1.25) scaleY(1.25);
        color: #e3ad35;
        text-shadow: 1px 1px 0px #8f7b3e; }
    /* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
    #control-bar-view .levels-link-area:hover a.levels-link {
      text-decoration: underline; }
      /* line 46, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
      #control-bar-view .levels-link-area:hover a.levels-link .glyphicon {
        color: #f3d33b; }
  /* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
  #control-bar-view .left-cap, #control-bar-view .right-cap, #control-bar-view .center-chain, #control-bar-view .right-chain, #control-bar-view .wood-background {
    position: absolute;
    top: 0;
    pointer-events: none; }
  /* line 54, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
  #control-bar-view .left-cap {
    background: transparent url(/images/level/control_bar_cap_left.png);
    background-size: cover;
    width: 217px;
    height: 63px;
    left: 0; }
  /* line 61, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
  #control-bar-view .right-cap {
    background: transparent url(/images/level/control_bar_cap_right.png);
    background-size: cover;
    right: 0;
    width: 78px;
    height: 60px; }
  /* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
  #control-bar-view .center-chain {
    background: transparent url(/images/level/control_bar_chain_center.png);
    background-size: cover;
    left: 30%;
    width: 111px;
    height: 24px;
    z-index: 1; }
  /* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
  #control-bar-view .right-chain {
    background: transparent url(/images/level/control_bar_chain_right.png);
    background-size: cover;
    top: 30px;
    right: 0;
    width: 97px;
    height: 51px;
    z-index: -1; }
  /* line 85, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
  #control-bar-view .wood-background {
    background: transparent url(/images/level/control_bar_background.png);
    background-size: contain;
    width: 100%;
    height: 55px;
    z-index: -1; }
  /* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
  #control-bar-view .level-name-area-container {
    position: relative;
    width: 100%;
    pointer-events: none;
    z-index: 1; }
    /* line 98, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
    #control-bar-view .level-name-area-container .level-name-area {
      min-width: 200px;
      max-width: 293px;
      margin: 0 auto;
      padding: 8px;
      border-style: solid;
      border-image: url(/images/level/control_bar_level_name_background.png) 20 fill round;
      border-width: 0 10px 10px 10px;
      text-align: center;
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0); }
      /* line 111, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
      #control-bar-view .level-name-area-container .level-name-area .level-label {
        font-size: 12px;
        color: #f3d33b;
        margin-bottom: -5px; }
      /* line 116, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
      #control-bar-view .level-name-area-container .level-name-area .level-name {
        color: white;
        font-size: 18px; }
      /* line 120, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
      #control-bar-view .level-name-area-container .level-name-area .level-difficulty {
        font-size: 28px;
        color: #f3d33b;
        display: inline-block;
        -webkit-transform: rotate(-15deg);
        -ms-transform: rotate(-15deg);
        transform: rotate(-15deg);
        vertical-align: middle; }
  /* line 127, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
  #control-bar-view .buttons-area {
    position: absolute;
    right: 35px;
    top: 8px; }
    /* line 132, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
    #control-bar-view .buttons-area button {
      float: right;
      margin-left: 10px;
      position: relative; }
    /* line 137, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
    #control-bar-view .buttons-area #game-menu-button {
      background: transparent;
      border: 0;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-transform: uppercase;
      font-size: 18px; }
      /* line 145, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
      #control-bar-view .buttons-area #game-menu-button .hamburger {
        display: inline-block; }
        /* line 148, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
        #control-bar-view .buttons-area #game-menu-button .hamburger span.icon-bar {
          display: block;
          border-radius: 4px;
          width: 18px;
          height: 4px;
          margin: 4px;
          background: #e3ad35; }
      /* line 156, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
      #control-bar-view .buttons-area #game-menu-button .game-menu-text {
        display: inline-block;
        vertical-align: middle;
        margin-top: -18px; }
      /* line 161, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
      #control-bar-view .buttons-area #game-menu-button:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05); }
        /* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
        #control-bar-view .buttons-area #game-menu-button:hover .hamburger span.icon-bar {
          background-color: #f3d33b; }
    /* line 167, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
    #control-bar-view .buttons-area #level-done-button, #control-bar-view .buttons-area #next-game-button, #control-bar-view .buttons-area #control-bar-sign-up-button, #control-bar-view .buttons-area #version-switch-button {
      top: 7px;
      font-size: 13px;
      height: 24px; }
    /* line 172, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
    #control-bar-view .buttons-area #level-done-button {
      display: none; }

/* line 177, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/control-bar-view.sass */
html.no-borderimage #control-bar-view .level-name-area {
  border: 0;
  background: transparent url(/images/level/control_bar_level_name_background.png);
  background-size: contain;
  background-repeat: no-repeat; }
