/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/colors_tab.sass */
#editor-thang-colors-tab-view #color-group-settings {
  width: 75%;
  box-sizing: border-box;
  float: right; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/colors_tab.sass */
#editor-thang-colors-tab-view #color-groups-treema {
  float: left;
  width: 25%;
  height: 550px;
  box-sizing: border-box;
  margin-bottom: 20px; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/colors_tab.sass */
#editor-thang-colors-tab-view #shape-buttons {
  border: 1px solid saddlebrown;
  margin: 0 20px 0 10px;
  width: 30%;
  float: left;
  height: 550px;
  overflow: scroll;
  background: sandybrown;
  padding: 5px;
  box-sizing: border-box; }
  /* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/colors_tab.sass */
  #editor-thang-colors-tab-view #shape-buttons button {
    width: 36px;
    height: 36px;
    margin: 7px;
    padding: 0;
    border: 7px solid white; }
    /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/colors_tab.sass */
    #editor-thang-colors-tab-view #shape-buttons button.selected {
      width: 48px;
      height: 48px;
      margin: 1px;
      border: 2px solid black; }

/* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/colors_tab.sass */
#editor-thang-colors-tab-view #controls {
  float: left;
  width: 60%;
  text-align: center;
  background-color: aliceblue;
  border: 1px solid blue; }
  /* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/colors_tab.sass */
  #editor-thang-colors-tab-view #controls .slider-cell {
    margin: 20px 10px;
    border-color: black; }

/* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/thang/colors_tab.sass */
#editor-thang-colors-tab-view canvas {
  width: 60%;
  background: steelblue;
  border: 1px solid darkblue; }
