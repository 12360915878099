/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
#tome-view {
  height: 100%;
  margin-bottom: -20px;
  overflow: hidden; }
  /* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
  #tome-view > .popover {
    max-width: 600px; }
    /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
    #tome-view > .popover.pinned {
      left: auto !important;
      top: auto !important;
      right: 100%;
      bottom: 151px;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      -o-user-select: text;
      user-select: text;
      min-width: 100%;
      height: 60%; }
      /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
      #tome-view > .popover.pinned .arrow {
        display: none; }
      /* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
      #tome-view > .popover.pinned .close {
        position: absolute;
        top: 5%;
        right: 5%;
        font-size: 28px;
        font-weight: bold;
        opacity: 0.6;
        filter: alpha(opacity=60);
        text-shadow: 0 1px 0 white; }
        /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
        #tome-view > .popover.pinned .close:hover {
          opacity: 1;
          filter: alpha(opacity=100); }
  /* line 39, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
  #tome-view .popover {
    padding: 10px 10px 30px 10px;
    border-style: solid;
    border-image: url(/images/level/popover_border_background.png) 16 12 fill round;
    border-width: 16px 12px;
    -webkit-box-shadow: 0 0 0 #000;
    box-shadow: 0 0 0 #000; }
    /* line 46, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
    #tome-view .popover h1:not(.not-code), #tome-view .popover h2:not(.not-code), #tome-view .popover h3:not(.not-code), #tome-view .popover h4:not(.not-code), #tome-view .popover h5:not(.not-code), #tome-view .popover h6:not(.not-code) {
      font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }
    /* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
    #tome-view .popover .popover-title {
      background-color: transparent;
      margin: 0 14px;
      padding: 8px 0;
      border-bottom-color: #ccc; }
    /* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
    #tome-view .popover .popover-content {
      max-height: 100%;
      overflow-y: auto;
      margin-right: 10px; }
      /* line 59, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
      #tome-view .popover .popover-content img {
        float: right; }
    /* line 62, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
    #tome-view .popover.top .arrow {
      bottom: -2%; }
    /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
    #tome-view .popover.bottom .arrow {
      top: -2%; }
    /* line 66, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
    #tome-view .popover.left .arrow {
      right: 0%; }
    /* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
    #tome-view .popover.right .arrow {
      left: -3%; }
  /* line 71, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
  #tome-view pre {
    display: inline-block;
    padding: 5px; }

/* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
.ace_editor.ace_autocomplete {
  z-index: 20 !important; }
  /* line 79, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
  .ace_editor.ace_autocomplete .ace_content .ace_line .ace_rightAlignedText {
    display: none; }
  /* line 81, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
  .ace_editor.ace_autocomplete .ace_content .ace_line.ace_selected .ace_rightAlignedText {
    display: inline-block; }

/* line 86, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
html.no-borderimage #tome-view .popover {
  background: transparent url(/images/level/popover_background.png);
  background-size: 100% 100%;
  border: 0; }

/* line 93, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/tome/tome.sass */
#code-area.fullscreen-editor #tome-view .popover.pinned {
  min-width: 600px;
  bottom: inherit;
  right: 50%;
  margin-right: -300px; }
