/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
#play-achievements-modal .modal-dialog {
  width: 800px; }

/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
#play-achievements-modal .modal-header {
  padding-bottom: 20px; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
#play-achievements-modal img.icon {
  float: left;
  width: 40px;
  margin-right: 10px; }

/* line 18, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
#play-achievements-modal .panel {
  margin: 5px 0;
  position: relative;
  border: 2px solid #4b4b4b;
  padding: 2px;
  opacity: 0.75;
  filter: alpha(opacity=75); }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .panel h3 {
    margin: 0 0 0 50px;
    color: #4b4b4b; }
  /* line 29, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .panel p {
    margin: 0 0 0 50px;
    color: #4b4b4b; }
  /* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .panel .panel-body {
    padding: 5px 150px 5px 5px; }
  /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .panel .created {
    position: absolute;
    right: 10px;
    top: 5px;
    color: #4b4b4b;
    font-size: 12px; }

/* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
#play-achievements-modal .panel.earned {
  background: #322821;
  border: 5px solid #1a1511;
  padding: 0;
  opacity: 1;
  filter: alpha(opacity=100); }
  /* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .panel.earned h3 {
    color: white; }
  /* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .panel.earned p {
    color: #cbaa94; }
  /* line 59, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .panel.earned .panel-body {
    border: 2px solid #4b3e33; }
  /* line 62, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .panel.earned .created {
    color: #ffbd44; }

/* line 68, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
#play-achievements-modal .rewards {
  position: absolute;
  right: .2em;
  top: 29px; }
  /* line 74, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .rewards .label {
    font-size: 18px;
    margin-left: 5px;
    color: #322821;
    background: #cbaa94; }
  /* line 80, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .rewards .gems {
    background: #94ccc7; }
  /* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .rewards .worth {
    background: #d8c488; }
  /* line 86, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/modal/play-achievements-modal.sass */
  #play-achievements-modal .rewards img {
    width: 12px;
    height: 12px; }
