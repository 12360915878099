/* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
#duel-stats-view {
  position: absolute;
  right: calc(45% + 200px);
  bottom: 133px;
  z-index: 3;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding: 4px 10px 0 4px;
  background: transparent url(/images/level/hud_background.png) no-repeat;
  background-size: 100% auto;
  border-radius: 4px;
  width: 500px;
  height: 60px;
  display: none;
  flex-direction: row; }
  /* line 22, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
  #level-view.no-api #duel-stats-view {
    right: 45%; }
  /* line 25, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
  #duel-stats-view:hover {
    opacity: 0.1; }
  /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
  #duel-stats-view .player-container {
    width: 50%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #e62b1e;
    text-shadow: 0px 1px 0px black, 0px -1px 0px black, 1px 0px 0px black, -1px 0px 0px black; }
    /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container.team-humans {
      padding-right: 10px; }
      /* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
      #duel-stats-view .player-container.team-humans .player-power, #duel-stats-view .player-container.team-humans .player-gold {
        margin-right: 5px; }
    /* line 47, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container.team-ogres {
      padding-left: 10px;
      flex-direction: row-reverse;
      color: #0597ff; }
      /* line 52, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
      #duel-stats-view .player-container.team-ogres .name-and-power {
        flex-direction: row-reverse; }
        /* line 55, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
        #duel-stats-view .player-container.team-ogres .name-and-power .player-name {
          text-align: right; }
        /* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
        #duel-stats-view .player-container.team-ogres .name-and-power .player-power, #duel-stats-view .player-container.team-ogres .name-and-power .player-gold {
          margin-left: 5px;
          flex-direction: row-reverse;
          text-align: right; }
      /* line 63, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
      #duel-stats-view .player-container.team-ogres .player-health {
        flex-direction: row-reverse; }
        /* line 66, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
        #duel-stats-view .player-container.team-ogres .player-health .health-bar-container .health-bar {
          background: #0597ff; }
      /* line 69, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
      #duel-stats-view .player-container.team-ogres .thang-avatar-view {
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        transform: scaleX(-1); }
    /* line 72, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container .name-and-power {
      display: flex;
      flex-direction: row; }
    /* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container .player-portrait {
      margin: 0 12px; }
      /* line 79, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
      #duel-stats-view .player-container .player-portrait .thang-avatar-view {
        width: 30px; }
    /* line 82, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container .player-name {
      height: 50%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 130px; }
    /* line 91, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container .health-icon, #duel-stats-view .player-container .power-icon, #duel-stats-view .player-container .gold-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: transparent url(/images/level/hud_info_icons.png) no-repeat;
      background-size: auto 16px; }
    /* line 98, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container .player-health, #duel-stats-view .player-container .player-power, #duel-stats-view .player-container .player-gold {
      height: 50%;
      display: flex;
      flex-direction: row;
      height: 18px; }
    /* line 104, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container .player-health {
      line-height: 16px; }
      /* line 107, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
      #duel-stats-view .player-container .player-health .health-bar-container {
        width: 100px;
        display: inline-block;
        margin: 1px 5px;
        height: 16px;
        background: #201b15;
        padding: 4px;
        border-radius: 8px;
        border: 0;
        overflow: hidden; }
        /* line 118, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
        #duel-stats-view .player-container .player-health .health-bar-container .health-bar {
          background: #ea232d;
          width: 100%;
          height: 8px;
          border-radius: 4px; }
      /* line 124, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
      #duel-stats-view .player-container .player-health .health-value {
        vertical-align: top; }
    /* line 129, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container .player-power .power-icon {
      margin-top: 4px;
      background-position: -80px 0px; }
    /* line 133, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container .player-power .power-value {
      min-width: 20px;
      margin: 0px 5px; }
    /* line 139, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container .player-gold .gold-icon {
      margin-top: 4px;
      background-position: -128px 0px; }
    /* line 143, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/duel-stats-view.sass */
    #duel-stats-view .player-container .player-gold .gold-value {
      min-width: 20px;
      margin: 0px 5px; }
