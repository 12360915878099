/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
#thangs-tab-view {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: -15px; }
  /* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
  #thangs-tab-view #thangs-container-toggle, #thangs-tab-view #thangs-palette-toggle {
    top: 5px;
    position: absolute;
    z-index: 12;
    padding: 8px; }
  /* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
  #thangs-tab-view #thangs-container-toggle {
    left: 5px;
    box-shadow: 1px 1px 5px black; }
  /* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
  #thangs-tab-view #thangs-palette-toggle {
    right: 5px;
    box-shadow: -1px 1px 5px black; }
  /* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
  #thangs-tab-view .thangs-container {
    background: white;
    width: 250px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 11;
    padding: 5px;
    border: 1px solid black; }
    /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container h3 {
      text-align: right;
      margin: 0; }
    /* line 42, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container .editor-nano-container {
      height: 90%;
      position: relative;
      top: 20px; }
    /* line 46, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container #thangs-treema {
      position: absolute;
      top: 45px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: scroll;
      margin: 0;
      outline: thin;
      border: none;
      padding-top: 0; }
    /* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container .treema-schema-select {
      display: none; }
    /* line 61, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container .treema-children .treema-row * {
      cursor: pointer !important; }
    /* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container .treema-key {
      display: none; }
    /* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container h6 {
      margin: 0;
      line-height: 20px; }
    /* line 71, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container .treema-thangs-folder {
      margin-top: 2px;
      color: #253341; }
    /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container .treema-thang {
      color: #253341;
      position: relative;
      left: -20px; }
    /* line 80, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container .treema-node {
      border: none; }
    /* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .thangs-container img {
      float: left;
      width: 18px;
      margin-right: 5px;
      margin-left: 5px;
      position: relative;
      border: 1px solid gray; }
  /* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
  #thangs-tab-view .world-container {
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box; }
  /* line 97, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
  #thangs-tab-view .world-container {
    position: relative; }
    /* line 100, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .world-container #canvas-wrapper {
      width: 100%;
      position: relative;
      text-align: center; }
      /* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
      #thangs-tab-view .world-container #canvas-wrapper .generate-terrain-button {
        position: absolute;
        top: 45%; }
    /* line 110, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .world-container canvas {
      width: 100%;
      display: block;
      border: 1px solid black; }
    /* line 115, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .world-container #webgl-surface {
      background-color: #ddd;
      z-index: 1; }
    /* line 119, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .world-container #normal-surface {
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none; }
    /* line 126, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
    #thangs-tab-view .world-container #contextmenu {
      text-align: left; }
  /* line 130, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/thangs-tab-view.sass */
  #thangs-tab-view #thang-components-edit-view #thang-component-configs {
    padding-bottom: 400px; }
