/* line 1, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/settings_tab.sass */
#editor-level-settings-tab-view {
  color: black; }
  /* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/settings_tab.sass */
  #editor-level-settings-tab-view .treema-value img {
    max-width: 100%; }
  /* line 8, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/settings_tab.sass */
  #editor-level-settings-tab-view .treema-node.concept-automatic .treema-value {
    color: #333; }
  /* line 11, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/editor/level/settings_tab.sass */
  #editor-level-settings-tab-view .treema-node.concept-deprecated .treema-value {
    color: gray; }
