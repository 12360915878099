/* line 7, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view #site-content-area {
  margin-bottom: 65px; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .access-restricted {
  margin-top: 100px; }

/* line 13, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .teacher-account-blurb {
  margin-top: 100px;
  margin-bottom: 700px; }

/* line 17, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view h1 {
  margin-top: 50px; }

/* line 20, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view #survey {
  background-color: #cff2fc;
  border-radius: 10px;
  padding: 20px 60px;
  margin: 0 0 30px 0;
  text-align: center; }
  /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view #survey p.survey-button {
    text-align: center; }
  /* line 33, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view #survey h3 {
    text-align: center;
    font-size: 1.5em; }

/* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .language {
  display: inline-block;
  margin-right: 10px; }

/* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .student-count {
  display: inline-block; }

/* line 45, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .class-links a {
  font-weight: bold;
  color: black;
  margin-right: 1rem;
  text-decoration: underline; }

/* line 51, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .classes {
  margin-top: 20px; }
  /* line 54, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .classes .class {
    padding: 20px;
    display: flex; }
    /* line 58, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
    #teacher-classes-view .classes .class .flex-right {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end; }
    /* line 63, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
    #teacher-classes-view .classes .class .progress-col {
      display: flex;
      align-self: center;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end; }

/* line 70, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .class:nth-child(2n+1) {
  background-color: #ebebeb; }

/* line 73, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .class:nth-child(2n) {
  background-color: #F5F5F5; }

/* line 76, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .view-class-arrow {
  color: #222;
  font-size: 35px;
  line-height: 35px;
  margin-left: 15px;
  align-self: center; }
  /* line 82, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .view-class-arrow .view-class-arrow-inner {
    color: #999; }
    /* line 84, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
    #teacher-classes-view .view-class-arrow .view-class-arrow-inner:hover {
      text-decoration: none; }

/* line 87, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .progress-dot {
  display: inline-block;
  margin: 10px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background: #999;
  display: flex;
  justify-content: center;
  align-items: center; }
  /* line 97, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .progress-dot .dot-label {
    color: white; }

/* line 100, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .progress-dot.forest {
  background: #20572B; }
  /* line 102, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .progress-dot.forest .tooltip-inner {
    color: #20572B;
    border: 1px solid #20572B; }
  /* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .progress-dot.forest .tooltip-arrow {
    border-top-color: #20572B; }

/* line 108, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .progress-dot.gold {
  background: #F2BE19; }
  /* line 110, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .progress-dot.gold .tooltip-inner {
    color: #0E4C60;
    border: 1px solid #0E4C60; }
  /* line 113, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .progress-dot.gold .tooltip-arrow {
    border-top-color: #0E4C60; }

/* line 116, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .add-students {
  margin-left: auto;
  margin-right: auto; }

/* line 120, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .create-class {
  margin-top: 65px;
  margin-bottom: 65px; }

/* line 124, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .center {
  text-align: center; }

/* line 127, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
#teacher-classes-view .teacher-quests {
  border: 1px solid gray;
  margin: 20px 80px 40px 80px;
  padding: 10px;
  border-radius: 15px; }
  /* line 132, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .teacher-quests .refresh-help {
    color: grey;
    font-size: 12px; }
  /* line 135, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .teacher-quests .completion-label {
    font-size: 14px;
    font-weight: bold; }
  /* line 138, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .teacher-quests .glyphicon {
    margin-right: 7px; }
  /* line 140, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .teacher-quests .quest-progress {
    font-weight: 600;
    font-size: 50px;
    margin: 20px 0px; }
  /* line 146, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .teacher-quests .quests-list-col ul {
    list-style: none; }
  /* line 148, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .teacher-quests .quests-list-col > ul {
    padding-left: 0;
    margin-left: 0; }
  /* line 151, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .teacher-quests .quests-list-col li.quest-complete {
    padding-left: 20px;
    font-size: 12px;
    opacity: 0.5;
    line-height: 14px; }
    /* line 156, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
    #teacher-classes-view .teacher-quests .quests-list-col li.quest-complete > div > p {
      margin-bottom: 7px; }
  /* line 159, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .teacher-quests .quests-list-col li.quest-incomplete li {
    font-size: 15px; }
  /* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
  #teacher-classes-view .teacher-quests .el svg {
    transform: rotate(-90deg); }
    /* line 166, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
    #teacher-classes-view .teacher-quests .el svg circle.top {
      fill: #f2be18;
      stroke-width: 50;
      stroke: #0e4b60; }
    /* line 170, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/courses/teacher-classes-view.sass */
    #teacher-classes-view .teacher-quests .el svg circle.bottom {
      fill: #f2be18; }
