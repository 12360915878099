/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/gold.sass */
#gold-view {
  display: none;
  position: absolute;
  right: calc(45% + 10px + 200px);
  top: 62px;
  z-index: 6;
  -webkit-transition: box-shadow 0.2s linear;
  transition: box-shadow 0.2s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding: 0.4vw;
  background: transparent url(/images/level/gold_background.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 4px; }
  /* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/gold.sass */
  #level-view.no-api #gold-view {
    right: calc(50% + 20px);
    z-index: 10; }
  /* line 21, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/gold.sass */
  #gold-view:hover {
    box-shadow: 2px 2px 2px black; }
  /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/gold.sass */
  #gold-view .team-gold {
    font-size: 1.4vw;
    line-height: 1.4vw;
    margin: 0;
    color: #828282;
    display: inline-block;
    padding: 0px 4px; }
    /* line 32, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/gold.sass */
    #gold-view .team-gold.team-humans {
      color: #e62b1e; }
    /* line 35, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/gold.sass */
    #gold-view .team-gold.team-ogres {
      color: #0597ff; }
    /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/gold.sass */
    #gold-view .team-gold.team-allies, #gold-view .team-gold.team-minions {
      color: #2be61e; }
    /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/gold.sass */
    #gold-view .team-gold img {
      width: 1.2vw;
      height: 1.2vw;
      border-radius: 2px;
      padding: 0.1vw;
      margin-top: -0.2vw;
      margin-right: 0.1vw; }
    /* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/level/gold.sass */
    #gold-view .team-gold .gold-amount {
      display: inline-block;
      min-width: 20px; }
