.treema-target-of-copy-paste {
  transform: translateX(10px); }

.treema-node {
  transition: 0.1s ease-in-out transform;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  position: relative;
  font-family: Helvetica;
  clear: both;
  border-bottom: 1px solid #ccc;
  font-size: 13px;
  cursor: pointer; }
  .treema-node.treema-root > .treema-row .treema-value, .treema-node.treema-root > .treema-row .treema-type-select {
    display: none; }
  .treema-node.treema-open > .treema-children {
    padding-top: 1px; }
  .treema-node.treema-root {
    outline: none; }
  .treema-node input, .treema-node select {
    font-size: 13px;
    font-family: Helvetica; }
  .treema-node input {
    margin: -3px 0;
    width: 200px; }
  .treema-node .treema-new-prop {
    margin: -1px 0 10px; }

.treema-type-select, .treema-schema-select {
  margin-right: 5px;
  float: left; }

.treema-children {
  margin-left: 15px;
  clear: both; }

.treema-add-child {
  background-color: #eef;
  border: 1px solid #aaf;
  cursor: pointer;
  display: inline-block;
  margin: 3px 0 10px;
  padding: 0px 5px;
  font-weight: bold;
  position: relative;
  left: -10px; }
  .treema-add-child:hover {
    background-color: #ccf; }

.treema-full > .treema-children > .treema-add-child {
  display: none; }

.treema-full.treema-open > .treema-children {
  margin-bottom: 5px; }

.treema-row {
  padding: 2px 3px 2px 3px; }

.treema-value {
  cursor: text;
  display: block;
  float: left;
  max-width: 100%;
  min-width: 50px; }

.treema-key {
  color: #5353ac;
  float: left;
  display: block;
  cursor: pointer;
  margin-right: 5px; }

.treema-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  background-color: rgba(64, 128, 255, 0);
  pointer-events: none;
  width: 100%;
  cursor: pointer; }

.treema-description {
  float: right;
  opacity: 0.8;
  font-size: 11px;
  line-height: 13px;
  min-width: 200px;
  text-align: right;
  display: none; }

.treema-selected > .treema-row > .treema-description {
  display: inline; }

.treema-edit + .treema-description {
  display: inline; }

.treema-selected > .treema-row {
  background-color: rgba(64, 128, 255, 0.25); }

.treema-error {
  float: right;
  color: #8b0000;
  margin: 2px 10px; }

.treema-has-error {
  background-color: #ffb6c1;
  border: 1px solid #8b0000; }

.treema-temp-error {
  background-color: #ffb6c1;
  padding: 2px 3px;
  color: #8b0000;
  margin: 0 5px;
  border: 1px solid #8b0000; }

.treema-toggle-hit-area {
  cursor: pointer;
  width: 15px;
  float: left;
  position: absolute;
  left: -15px;
  top: 0;
  bottom: 0; }
  .treema-toggle-hit-area:hover {
    background-color: rgba(128, 128, 128, 0.1); }
    .treema-toggle-hit-area:hover .treema-toggle {
      opacity: 1; }
  .treema-toggle-hit-area .treema-toggle {
    width: 0;
    height: 0;
    opacity: 0.7;
    position: absolute; }
    .treema-closed > .treema-toggle-hit-area .treema-toggle {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 8px solid #666;
      top: 5px;
      left: 5px; }
    .treema-open > .treema-toggle-hit-area .treema-toggle {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid #666;
      top: 7px;
      left: 3px; }

.treema-clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.treema-shortened {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.treema-multiline {
  width: inherit;
  margin-top: 20px;
  float: none !important; }

.treema-clipboard-container {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 0px;
  height: 0px;
  z-index: 100;
  display: none;
  opacity: 0; }
  .treema-clipboard-container .treema-clipboard {
    width: 1px;
    height: 1px;
    padding: 0px; }

.treema-filter-hidden {
  display: none; }

.treema-default-stub {
  opacity: 0.7; }

.treema-string {
  color: #998500; }

.treema-number {
  color: #699900; }

.treema-null {
  color: #524059;
  font-weight: bold; }

.treema-array {
  color: #009905;
  cursor: row-resize; }

.treema-object {
  color: #008799;
  cursor: row-resize; }

.treema-boolean {
  color: #140099;
  cursor: pointer; }
  .treema-boolean.treema-edit {
    background-color: rgba(64, 128, 255, 0.25); }
  .treema-boolean input {
    opacity: 0; }
  .treema-boolean:hover + .treema-description {
    display: inline; }

.treema-point2d input, .treema-point3d input {
  width: 40px; }

.treema-search-results {
  width: 500px;
  margin-top: 10px;
  cursor: pointer; }

.treema-search-result-row:hover {
  background-color: rgba(166, 196, 255, 0.25); }

.treema-search-selected {
  background-color: rgba(64, 128, 255, 0.25) !important; }

.treema-ace.treema-display .treema-shortened {
  font-family: monospace; }

.treema-ace .ace_editor {
  width: 100%;
  border: 1px solid gray; }

.treema-long-string textarea {
  width: 100%;
  height: 300px; }


/*# sourceMappingURL=treema.css.map*/