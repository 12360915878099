/* line 2, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/save-load-view.sass */
#save-load-view #save-granularity-toggle {
  width: 100%; }
  /* line 4, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/save-load-view.sass */
  #save-load-view #save-granularity-toggle label {
    width: 50%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/save-load-view.sass */
#save-load-view .save-list .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/* line 14, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/menu/save-load-view.sass */
#save-load-view .save-pane {
  overflow: hidden; }
