/* line 5, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
body.is-playing {
  background-color: black; }

/* line 10, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
#level-view {
  margin: 0 auto;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 10px;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  min-width: 1024px; }
  /* line 24, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time.real-time.game-dev #canvas-wrapper {
    width: 80%; }
  /* line 27, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time #canvas-wrapper {
    width: 100%; }
    /* line 29, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view.real-time #canvas-wrapper canvas {
      margin: 0 auto; }
    /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view.real-time #canvas-wrapper #normal-surface {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto; }
  /* line 36, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time #control-bar-view {
    width: 100%; }
    /* line 38, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view.real-time #control-bar-view button, #level-view.real-time #control-bar-view h4 {
      display: none; }
  /* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time:not(.flags) #playback-view {
    width: 100%; }
  /* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time.flags #playback-view {
    width: 90%;
    width: -webkit-calc(100% - 200px);
    width: calc(100% - 200px);
    left: 200px; }
  /* line 50, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time #code-area, #level-view.real-time #thang-hud, #level-view.real-time #goals-view {
    display: none;
    visibility: hidden; }
  /* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time #gold-view {
    right: 1%;
    -webkit-box-shadow: -1px 1px 10px cyan;
    box-shadow: -1px 1px 10px cyan; }
    /* line 56, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view.real-time #gold-view .team-gold {
      font-size: 2vw;
      line-height: 2vw; }
      /* line 59, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
      #level-view.real-time #gold-view .team-gold img {
        width: 1.8vw;
        height: 1.8vw; }
  /* line 62, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time #duel-stats-view {
    right: calc(1% + 100px);
    bottom: 50px; }
  /* line 65, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time #control-bar-view .title {
    left: 20%;
    width: 60%;
    text-align: center; }
  /* line 69, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time #stop-real-time-playback-button {
    display: block;
    z-index: 20; }
  /* line 72, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time #level-dialogue-view {
    display: none; }
  /* line 75, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.real-time .level-content {
    margin: 0px auto; }
  /* line 78, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view .level-content {
    position: relative;
    min-height: 555px; }
  /* line 83, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #canvas-wrapper {
    top: 50px;
    width: calc(57% - 200px);
    position: relative;
    overflow: hidden;
    background-color: black;
    -webkit-transition: all 0.5s ease-out, z-index 1.2s linear;
    transition: all 0.5s ease-out, z-index 1.2s linear;
    z-index: 0; }
    /* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view #canvas-wrapper.preview-overlay {
      z-index: 20; }
      /* line 94, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
      #level-view #canvas-wrapper.preview-overlay #goals-view {
        visibility: hidden; }
  /* line 97, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view canvas#webgl-surface {
    background-color: #333;
    z-index: 1; }
  /* line 101, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view canvas#normal-surface {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none; }
  /* line 108, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view canvas#webgl-surface, #level-view canvas#normal-surface {
    display: block;
    z-index: 2;
    -webkit-transition: 0.5s ease-out;
    transition: 0.5s ease-out; }
    /* line 113, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view canvas#webgl-surface.grabbable:not(.flag-color-selected), #level-view canvas#normal-surface.grabbable:not(.flag-color-selected) {
      cursor: -moz-grab;
      cursor: -webkit-grab;
      cursor: grab; }
      /* line 118, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
      #level-view canvas#webgl-surface.grabbable:not(.flag-color-selected):active, #level-view canvas#normal-surface.grabbable:not(.flag-color-selected):active {
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
        cursor: grabbing; }
    /* line 123, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view canvas#webgl-surface.flag-color-selected, #level-view canvas#normal-surface.flag-color-selected {
      cursor: crosshair; }
  /* line 126, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #ascii-surface {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    pointer-events: none;
    white-space: pre;
    font-family: Courier, monospace;
    color: white;
    background-color: rgba(14, 59, 130, 0.25);
    transform-origin: 0 0 0;
    line-height: 15px; }
  /* line 141, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #code-area {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px 0.9% 10px 0;
    width: 43.5%;
    background: transparent url(/images/level/wood_texture.png);
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    top: 0px;
    bottom: 0;
    -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
    transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
    z-index: 2; }
  /* line 154, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #game-area {
    position: relative;
    overflow: hidden; }
  /* line 159, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view .ui-effects-transfer {
    border: 2px dotted gray; }
  /* line 163, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view .modal img {
    float: right; }
  /* line 166, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view .modal img.diagram {
    float: none; }
  /* line 169, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #multiplayer-join-link {
    font-size: 12px; }
  /* line 173, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view .btn.banner {
    background-color: #ededed;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#e8e8e8), color-stop(16%, #e8e8e8), color-stop(17%, #FFF), color-stop(83%, #FFF), color-stop(84%, #e8e8e8), to(#e8e8e8));
    background-image: -webkit-linear-gradient(#e8e8e8, #e8e8e8 16%, #FFF 16%, #FFF 83%, #e8e8e8 84%, #e8e8e8);
    background-image: -moz-linear-gradient(top, #e8e8e8, #e8e8e8 16%, #FFF 16%, #FFF 83%, #e8e8e8 84%, #e8e8e8);
    background-image: -o-linear-gradient(#e8e8e8, #e8e8e8 16%, #FFF 16%, #FFF 83%, #e8e8e8 84%, #e8e8e8);
    background-image: linear-gradient(#e8e8e8, #e8e8e8 16%, #FFF 16%, #FFF 83%, #e8e8e8 84%, #e8e8e8);
    background-repeat: no-repeat;
    color: #333;
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    border: 1px solid black;
    text-shadow: none; }
    /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
    #level-view .btn.banner:hover:not(.disabled):not([disabled]), #level-view .btn.banner:focus:not(.disabled):not([disabled]) {
      background-color: #f9f9f9;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f7f7f7), color-stop(16%, #f7f7f7), color-stop(17%, white), color-stop(83%, white), color-stop(84%, #f7f7f7), to(#f7f7f7));
      background-image: -webkit-linear-gradient(#f7f7f7, #f7f7f7 16%, white 16%, white 83%, #f7f7f7 84%, #f7f7f7);
      background-image: -moz-linear-gradient(top, #f7f7f7, #f7f7f7 16%, white 16%, white 83%, #f7f7f7 84%, #f7f7f7);
      background-image: -o-linear-gradient(#f7f7f7, #f7f7f7 16%, white 16%, white 83%, #f7f7f7 84%, #f7f7f7);
      background-image: linear-gradient(#f7f7f7, #f7f7f7 16%, white 16%, white 83%, #f7f7f7 84%, #f7f7f7);
      background-repeat: no-repeat; }
    /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
    #level-view .btn.banner.active, #level-view .btn.banner:active {
      background-image: none;
      outline: 0;
      -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
    /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
    #level-view .btn.banner.disabled, #level-view .btn.banner[disabled] {
      cursor: default;
      background-image: none;
      opacity: 0.65;
      filter: alpha(opacity=65);
      -webkit-box-shadow: none;
      box-shadow: none; }
    /* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view .btn.banner.btn-primary {
      background-color: #4c95e5;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4490e4), color-stop(16%, #4490e4), color-stop(17%, #6CA8EA), color-stop(83%, #6CA8EA), color-stop(84%, #4490e4), to(#4490e4));
      background-image: -webkit-linear-gradient(#4490e4, #4490e4 16%, #6CA8EA 16%, #6CA8EA 83%, #4490e4 84%, #4490e4);
      background-image: -moz-linear-gradient(top, #4490e4, #4490e4 16%, #6CA8EA 16%, #6CA8EA 83%, #4490e4 84%, #4490e4);
      background-image: -o-linear-gradient(#4490e4, #4490e4 16%, #6CA8EA 16%, #6CA8EA 83%, #4490e4 84%, #4490e4);
      background-image: linear-gradient(#4490e4, #4490e4 16%, #6CA8EA 16%, #6CA8EA 83%, #4490e4 84%, #4490e4);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-primary:hover:not(.disabled):not([disabled]), #level-view .btn.banner.btn-primary:focus:not(.disabled):not([disabled]) {
        background-color: #67a5e9;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5fa0e8), color-stop(16%, #5fa0e8), color-stop(17%, #87b8ee), color-stop(83%, #87b8ee), color-stop(84%, #5fa0e8), to(#5fa0e8));
        background-image: -webkit-linear-gradient(#5fa0e8, #5fa0e8 16%, #87b8ee 16%, #87b8ee 83%, #5fa0e8 84%, #5fa0e8);
        background-image: -moz-linear-gradient(top, #5fa0e8, #5fa0e8 16%, #87b8ee 16%, #87b8ee 83%, #5fa0e8 84%, #5fa0e8);
        background-image: -o-linear-gradient(#5fa0e8, #5fa0e8 16%, #87b8ee 16%, #87b8ee 83%, #5fa0e8 84%, #5fa0e8);
        background-image: linear-gradient(#5fa0e8, #5fa0e8 16%, #87b8ee 16%, #87b8ee 83%, #5fa0e8 84%, #5fa0e8);
        background-repeat: no-repeat; }
      /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-primary.active, #level-view .btn.banner.btn-primary:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-primary.disabled, #level-view .btn.banner.btn-primary[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
    /* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view .btn.banner.btn-info {
      background-color: #569ac2;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4f96c0), color-stop(16%, #4f96c0), color-stop(17%, #71AACC), color-stop(83%, #71AACC), color-stop(84%, #4f96c0), to(#4f96c0));
      background-image: -webkit-linear-gradient(#4f96c0, #4f96c0 16%, #71AACC 16%, #71AACC 83%, #4f96c0 84%, #4f96c0);
      background-image: -moz-linear-gradient(top, #4f96c0, #4f96c0 16%, #71AACC 16%, #71AACC 83%, #4f96c0 84%, #4f96c0);
      background-image: -o-linear-gradient(#4f96c0, #4f96c0 16%, #71AACC 16%, #71AACC 83%, #4f96c0 84%, #4f96c0);
      background-image: linear-gradient(#4f96c0, #4f96c0 16%, #71AACC 16%, #71AACC 83%, #4f96c0 84%, #4f96c0);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-info:hover:not(.disabled):not([disabled]), #level-view .btn.banner.btn-info:focus:not(.disabled):not([disabled]) {
        background-color: #6ca7ca;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#66a3c8), color-stop(16%, #66a3c8), color-stop(17%, #88b7d4), color-stop(83%, #88b7d4), color-stop(84%, #66a3c8), to(#66a3c8));
        background-image: -webkit-linear-gradient(#66a3c8, #66a3c8 16%, #88b7d4 16%, #88b7d4 83%, #66a3c8 84%, #66a3c8);
        background-image: -moz-linear-gradient(top, #66a3c8, #66a3c8 16%, #88b7d4 16%, #88b7d4 83%, #66a3c8 84%, #66a3c8);
        background-image: -o-linear-gradient(#66a3c8, #66a3c8 16%, #88b7d4 16%, #88b7d4 83%, #66a3c8 84%, #66a3c8);
        background-image: linear-gradient(#66a3c8, #66a3c8 16%, #88b7d4 16%, #88b7d4 83%, #66a3c8 84%, #66a3c8);
        background-repeat: no-repeat; }
      /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-info.active, #level-view .btn.banner.btn-info:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-info.disabled, #level-view .btn.banner.btn-info[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
    /* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view .btn.banner.btn-success {
      background-color: #79962d;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#748f2b), color-stop(16%, #748f2b), color-stop(17%, #90B236), color-stop(83%, #90B236), color-stop(84%, #748f2b), to(#748f2b));
      background-image: -webkit-linear-gradient(#748f2b, #748f2b 16%, #90B236 16%, #90B236 83%, #748f2b 84%, #748f2b);
      background-image: -moz-linear-gradient(top, #748f2b, #748f2b 16%, #90B236 16%, #90B236 83%, #748f2b 84%, #748f2b);
      background-image: -o-linear-gradient(#748f2b, #748f2b 16%, #90B236 16%, #90B236 83%, #748f2b 84%, #748f2b);
      background-image: linear-gradient(#748f2b, #748f2b 16%, #90B236 16%, #90B236 83%, #748f2b 84%, #748f2b);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-success:hover:not(.disabled):not([disabled]), #level-view .btn.banner.btn-success:focus:not(.disabled):not([disabled]) {
        background-color: #8cac35;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#87a632), color-stop(16%, #87a632), color-stop(17%, #a1c541), color-stop(83%, #a1c541), color-stop(84%, #87a632), to(#87a632));
        background-image: -webkit-linear-gradient(#87a632, #87a632 16%, #a1c541 16%, #a1c541 83%, #87a632 84%, #87a632);
        background-image: -moz-linear-gradient(top, #87a632, #87a632 16%, #a1c541 16%, #a1c541 83%, #87a632 84%, #87a632);
        background-image: -o-linear-gradient(#87a632, #87a632 16%, #a1c541 16%, #a1c541 83%, #87a632 84%, #87a632);
        background-image: linear-gradient(#87a632, #87a632 16%, #a1c541 16%, #a1c541 83%, #87a632 84%, #87a632);
        background-repeat: no-repeat; }
      /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-success.active, #level-view .btn.banner.btn-success:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-success.disabled, #level-view .btn.banner.btn-success[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
    /* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view .btn.banner.btn-warning {
      background-color: #a85500;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#9f5100), color-stop(16%, #9f5100), color-stop(17%, #CD6800), color-stop(83%, #CD6800), color-stop(84%, #9f5100), to(#9f5100));
      background-image: -webkit-linear-gradient(#9f5100, #9f5100 16%, #CD6800 16%, #CD6800 83%, #9f5100 84%, #9f5100);
      background-image: -moz-linear-gradient(top, #9f5100, #9f5100 16%, #CD6800 16%, #CD6800 83%, #9f5100 84%, #9f5100);
      background-image: -o-linear-gradient(#9f5100, #9f5100 16%, #CD6800 16%, #CD6800 83%, #9f5100 84%, #9f5100);
      background-image: linear-gradient(#9f5100, #9f5100 16%, #CD6800 16%, #CD6800 83%, #9f5100 84%, #9f5100);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-warning:hover:not(.disabled):not([disabled]), #level-view .btn.banner.btn-warning:focus:not(.disabled):not([disabled]) {
        background-color: #c76500;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#be6000), color-stop(16%, #be6000), color-stop(17%, #ec7800), color-stop(83%, #ec7800), color-stop(84%, #be6000), to(#be6000));
        background-image: -webkit-linear-gradient(#be6000, #be6000 16%, #ec7800 16%, #ec7800 83%, #be6000 84%, #be6000);
        background-image: -moz-linear-gradient(top, #be6000, #be6000 16%, #ec7800 16%, #ec7800 83%, #be6000 84%, #be6000);
        background-image: -o-linear-gradient(#be6000, #be6000 16%, #ec7800 16%, #ec7800 83%, #be6000 84%, #be6000);
        background-image: linear-gradient(#be6000, #be6000 16%, #ec7800 16%, #ec7800 83%, #be6000 84%, #be6000);
        background-repeat: no-repeat; }
      /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-warning.active, #level-view .btn.banner.btn-warning:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-warning.disabled, #level-view .btn.banner.btn-warning[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
    /* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view .btn.banner.btn-danger {
      background-color: #95321a;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#8d2f19), color-stop(16%, #8d2f19), color-stop(17%, #B43C20), color-stop(83%, #B43C20), color-stop(84%, #8d2f19), to(#8d2f19));
      background-image: -webkit-linear-gradient(#8d2f19, #8d2f19 16%, #B43C20 16%, #B43C20 83%, #8d2f19 84%, #8d2f19);
      background-image: -moz-linear-gradient(top, #8d2f19, #8d2f19 16%, #B43C20 16%, #B43C20 83%, #8d2f19 84%, #8d2f19);
      background-image: -o-linear-gradient(#8d2f19, #8d2f19 16%, #B43C20 16%, #B43C20 83%, #8d2f19 84%, #8d2f19);
      background-image: linear-gradient(#8d2f19, #8d2f19 16%, #B43C20 16%, #B43C20 83%, #8d2f19 84%, #8d2f19);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-danger:hover:not(.disabled):not([disabled]), #level-view .btn.banner.btn-danger:focus:not(.disabled):not([disabled]) {
        background-color: #af3a1f;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#a7381e), color-stop(16%, #a7381e), color-stop(17%, #ce4525), color-stop(83%, #ce4525), color-stop(84%, #a7381e), to(#a7381e));
        background-image: -webkit-linear-gradient(#a7381e, #a7381e 16%, #ce4525 16%, #ce4525 83%, #a7381e 84%, #a7381e);
        background-image: -moz-linear-gradient(top, #a7381e, #a7381e 16%, #ce4525 16%, #ce4525 83%, #a7381e 84%, #a7381e);
        background-image: -o-linear-gradient(#a7381e, #a7381e 16%, #ce4525 16%, #ce4525 83%, #a7381e 84%, #a7381e);
        background-image: linear-gradient(#a7381e, #a7381e 16%, #ce4525 16%, #ce4525 83%, #a7381e 84%, #a7381e);
        background-repeat: no-repeat; }
      /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-danger.active, #level-view .btn.banner.btn-danger:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-danger.disabled, #level-view .btn.banner.btn-danger[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
    /* line 181, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view .btn.banner.btn-inverse {
      background-color: #2e4366;
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#2c3e5f), color-stop(16%, #2c3e5f), color-stop(17%, #3A537F), color-stop(83%, #3A537F), color-stop(84%, #2c3e5f), to(#2c3e5f));
      background-image: -webkit-linear-gradient(#2c3e5f, #2c3e5f 16%, #3A537F 16%, #3A537F 83%, #2c3e5f 84%, #2c3e5f);
      background-image: -moz-linear-gradient(top, #2c3e5f, #2c3e5f 16%, #3A537F 16%, #3A537F 83%, #2c3e5f 84%, #2c3e5f);
      background-image: -o-linear-gradient(#2c3e5f, #2c3e5f 16%, #3A537F 16%, #3A537F 83%, #2c3e5f 84%, #2c3e5f);
      background-image: linear-gradient(#2c3e5f, #2c3e5f 16%, #3A537F 16%, #3A537F 83%, #2c3e5f 84%, #2c3e5f);
      background-repeat: no-repeat;
      color: #FFF; }
      /* line 28, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-inverse:hover:not(.disabled):not([disabled]), #level-view .btn.banner.btn-inverse:focus:not(.disabled):not([disabled]) {
        background-color: #38507b;
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#354c74), color-stop(16%, #354c74), color-stop(17%, #446194), color-stop(83%, #446194), color-stop(84%, #354c74), to(#354c74));
        background-image: -webkit-linear-gradient(#354c74, #354c74 16%, #446194 16%, #446194 83%, #354c74 84%, #354c74);
        background-image: -moz-linear-gradient(top, #354c74, #354c74 16%, #446194 16%, #446194 83%, #354c74 84%, #354c74);
        background-image: -o-linear-gradient(#354c74, #354c74 16%, #446194 16%, #446194 83%, #354c74 84%, #354c74);
        background-image: linear-gradient(#354c74, #354c74 16%, #446194 16%, #446194 83%, #354c74 84%, #354c74);
        background-repeat: no-repeat; }
      /* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-inverse.active, #level-view .btn.banner.btn-inverse:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }
      /* line 37, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/mixins.sass */
      #level-view .btn.banner.btn-inverse.disabled, #level-view .btn.banner.btn-inverse[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none; }
  /* line 186, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view .gradient {
    position: absolute;
    z-index: 5; }
  /* line 190, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #code-area-gradient {
    top: 0px;
    width: 3px;
    background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    left: -3px;
    bottom: 0; }
  /* line 197, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #canvas-left-gradient {
    left: 0px;
    width: 5px;
    background: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
    bottom: -30px;
    top: 0; }
  /* line 204, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #canvas-top-gradient {
    top: 0;
    height: 5px;
    left: 0;
    right: 0;
    background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.4) 100%); }
  /* line 211, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #hud-left-gradient {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
    left: 0;
    top: 0;
    height: 100%;
    width: 2%; }
  /* line 218, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #hud-right-gradient {
    background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    right: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 2%; }
  /* line 226, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #play-footer {
    position: absolute;
    bottom: 0px;
    width: calc(57% - 200px);
    text-align: center;
    font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-variant: small-caps;
    font-size: 25px;
    padding: 0 0;
    -webkit-transition: opacity 0.1s linear;
    transition: opacity 0.1s linear;
    opacity: 0.6;
    filter: alpha(opacity=60); }
    /* line 238, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view #play-footer:hover {
      opacity: 1;
      filter: alpha(opacity=100); }
    /* line 241, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view #play-footer a {
      opacity: 0.75;
      filter: alpha(opacity=75);
      -webkit-transition: opacity 0.1s linear;
      transition: opacity 0.1s linear;
      color: white; }
      /* line 246, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
      #level-view #play-footer a:hover, #level-view #play-footer a:active {
        opacity: 1;
        filter: alpha(opacity=100); }
    @media screen and (min-aspect-ratio: 17 / 10) {
      /* line 250, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
      #level-view #play-footer:not(.premium) {
        display: none; } }
  /* line 253, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #level-footer-shadow {
    position: absolute;
    width: 100%;
    height: 30px;
    background: linear-gradient(to bottom, black 0%, transparent 100%); }
  /* line 259, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #fullscreen-editor-background-screen {
    background-color: black;
    opacity: 0.5;
    cursor: pointer;
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 19; }
  /* line 271, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #stop-real-time-playback-button {
    display: none;
    position: absolute;
    right: 15px;
    font-size: 30px; }
  /* line 278, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view .ad-container {
    width: 100%;
    height: 90px;
    text-align: center; }
  /* line 283, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view .hints-view {
    position: absolute;
    top: 10px;
    height: 93%;
    right: 45%;
    z-index: 1000000; }
  /* line 290, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view .game-container, #level-view .level-content {
    height: 100%; }
  /* line 295, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.no-api #spell-palette-view {
    display: none; }
  /* line 297, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.no-api #canvas-wrapper {
    width: 60%; }
  /* line 300, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view.web-dev {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    /* line 307, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view.web-dev #playback-view, #level-view.web-dev #thang-hud, #level-view.web-dev #level-dialogue-view, #level-view.web-dev #play-footer, #level-view.web-dev #level-footer-background, #level-view.web-dev #level-footer-shadow {
      display: none; }
    /* line 310, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view.web-dev .game-container, #level-view.web-dev .level-content, #level-view.web-dev #game-area, #level-view.web-dev #canvas-wrapper {
      height: 100%; }
    /* line 313, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view.web-dev #canvas-wrapper {
      height: calc(100% - 50px); }
    /* line 316, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view.web-dev #canvas-wrapper canvas {
      display: none; }
    /* line 319, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    #level-view.web-dev #web-surface-view {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0; }
  /* line 326, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #how-to-play-game-dev-panel {
    position: absolute;
    right: 0;
    top: 52px;
    width: 20%;
    bottom: 38px; }
  /* line 333, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  #level-view #codeplay-product-banner {
    width: calc(58.5% - 250px);
    max-width: 100vh;
    position: relative;
    left: 20px;
    top: -7px;
    z-index: 5; }

/* line 343, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
html.fullscreen-editor #level-view #fullscreen-editor-background-screen {
  display: block; }

/* line 346, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
html.fullscreen-editor #level-view #code-area {
  position: fixed;
  width: 95%;
  height: 100%;
  right: 0; }

/* line 352, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
body.ipad #level-view {
  height: 702.74459px;
  overflow: hidden; }
  /* line 357, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  body.ipad #level-view #code-area, body.ipad #level-view #play-footer, body.ipad #level-view #thang-hud {
    display: none; }
  /* line 360, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  body.ipad #level-view #level-chat-view {
    bottom: 40px; }
  /* line 363, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  body.ipad #level-view #playback-view {
    background-color: transparent;
    z-index: 3;
    bottom: 30px; }
    /* line 368, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    body.ipad #level-view #playback-view button {
      background-color: #333; }
    /* line 371, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    body.ipad #level-view #playback-view .scrubber .progress {
      background-color: rgba(255, 255, 255, 0.4); }
  /* line 374, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  body.ipad #level-view #canvas-wrapper, body.ipad #level-view #control-bar-view, body.ipad #level-view #playback-view, body.ipad #level-view #thang-hud {
    width: 100%; }
  /* line 377, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
  body.ipad #level-view #canvas-wrapper {
    height: 653px; }
    /* line 380, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
    body.ipad #level-view #canvas-wrapper canvas {
      margin: 0 auto;
      overflow: hidden; }

/* line 384, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/play/play-level-view.sass */
#level-footer-background {
  display: none;
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(/images/level/footer_background.jpg) no-repeat;
  bottom: 0;
  width: 100%;
  background-size: 100% 400px;
  height: 400px;
  z-index: -9001; }
