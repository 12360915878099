/* line 3, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view th {
  font-size: 16px; }

/* line 6, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .join-clan-link {
  width: 390px; }

/* line 9, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .join-link-prompt {
  font-weight: bold; }

/* line 12, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .stats-table {
  width: 400px;
  background: transparent; }

/* line 16, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view #editDescriptionModal .modal-dialog {
  background-color: white; }

/* line 19, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view #editNameModal .modal-dialog {
  background-color: white;
  max-width: 400px; }

/* line 23, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .edit-description-input {
  width: 100%; }

/* line 26, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .edit-name-input {
  width: 100%; }

/* line 31, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .remove-hero-cell {
  width: 100px; }

/* line 34, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .hero-icon-cell {
  display: inline-block;
  width: 30px;
  height: 50px;
  margin: 0px 2px;
  vertical-align: middle; }

/* line 41, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view td.hero-icon-cell {
  display: table-cell; }

/* line 44, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .level-cell {
  width: 50px;
  text-align: center;
  vertical-align: middle; }

/* line 49, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .name-cell {
  width: 100px;
  vertical-align: middle; }

/* line 53, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .achievements-cell {
  text-align: center;
  vertical-align: middle; }

/* line 57, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .latest-achievement-cell {
  vertical-align: middle; }

/* line 61, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .member-header {
  cursor: pointer; }

/* line 64, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .progress-header {
  cursor: pointer; }

/* line 67, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .progress-key {
  cursor: default;
  display: inline-block;
  white-space: nowrap;
  font-size: 9pt;
  font-weight: normal;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 0px;
  padding: 2px; }

/* line 78, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .progress-key-started {
  background-color: lightgreen; }

/* line 81, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .progress-key-complete {
  background-color: lightgray;
  margin-left: 14px; }

/* line 85, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .expand-progress-checkbox {
  margin-left: 14px; }

/* line 88, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .expand-progress-label {
  font-weight: normal;
  font-size: 14px; }

/* line 92, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .progress-cell {
  padding: 2px;
  padding-bottom: 10px; }
  /* line 96, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
  #clan-details-view .progress-cell .level-popup-container {
    display: none;
    position: absolute;
    padding: 10px;
    border: 1px solid black;
    z-index: 3;
    background-color: blanchedalmond;
    font-size: 10pt; }

/* line 105, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .level-progression-concepts {
  color: #317EAC;
  font-size: 12pt;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 4px; }

/* line 112, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .level-progression-levels {
  color: #317EAC;
  font-size: 12pt;
  font-weight: bold;
  margin-top: 8px; }

/* line 118, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .level-progression-campaign {
  font-size: 10pt;
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: 4px; }

/* line 124, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .progress-level-cell {
  display: inline-block;
  white-space: nowrap;
  font-size: 9pt;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 0px;
  padding: 2px; }

/* line 133, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .progress-level-cell-started {
  cursor: pointer;
  background-color: lightgreen; }

/* line 137, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .progress-level-cell-complete {
  cursor: pointer;
  background-color: lightgray; }

/* line 141, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .player-hero-icon {
  background: transparent url(/images/pages/play/play-spritesheet.png);
  background-size: cover;
  background-position: -60px 0;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0px 2px;
  vertical-align: middle; }

/* line 151, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .player-hero-icon {
  background-position: -120px 0; }
  /* line 154, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
  #clan-details-view .player-hero-icon.knight {
    background-position: -150px 0; }
  /* line 156, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
  #clan-details-view .player-hero-icon.librarian {
    background-position: -180px 0; }
  /* line 158, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
  #clan-details-view .player-hero-icon.ninja {
    background-position: -210px 0; }
  /* line 160, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
  #clan-details-view .player-hero-icon.potion-master {
    background-position: -240px 0; }
  /* line 162, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
  #clan-details-view .player-hero-icon.samurai {
    background-position: -270px 0; }
  /* line 164, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
  #clan-details-view .player-hero-icon.trapper {
    background-position: -300px 0; }
  /* line 166, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
  #clan-details-view .player-hero-icon.forest-archer {
    background-position: -330px 0; }
  /* line 168, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
  #clan-details-view .player-hero-icon.sorcerer {
    background-position: -360px 0; }

/* line 171, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view td.code-language-cell {
  width: 30px;
  vertical-align: middle; }
  /* line 175, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
  #clan-details-view td.code-language-cell .code-language-cell {
    vertical-align: middle; }

/* line 178, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view span.code-language-cell {
  background: transparent url(/images/common/code_languages/javascript_small.png);
  background-size: cover;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0px 2px; }

/* line 186, /Users/muhammadharisnp/ProductDevelopment/codecombat99lms/coco/codecombat/app/styles/clans/clan-details.sass */
#clan-details-view .remove-member-cell {
  vertical-align: middle; }
